import _ from 'lodash';
import { useMemo } from 'react';
import { IUseTableItemMemorizations } from './interface';

export const useTableItemMemorizations = ({
  changeAmount,
  removeItem,
  saleOrder,
}: IUseTableItemMemorizations) => {
  const changeAmountCallBack = useMemo(
    () =>
      _.debounce(
        async (amount: number, saleOrderItemId: number) =>
          await changeAmount.mutateAsync({
            pedidoVendaItemId: saleOrderItemId,
            quantidade: amount,
          }),
        600
      ),
    [changeAmount]
  );

  const removeItemCallBack = useMemo(
    () =>
      _.debounce(
        async (saleOrderItemId: number) =>
          await removeItem.mutateAsync({
            pedidoVendaItemId: saleOrderItemId,
          }),
        0
      ),
    [removeItem]
  );

  const statusSaleOrder = useMemo(
    () => saleOrder?.statusPedido,
    [saleOrder?.statusPedido]
  );

  return { changeAmountCallBack, removeItemCallBack, statusSaleOrder };
};
