import { useFormikContext } from 'formik';

export const useDiscountInput = () => {
  const form: any = useFormikContext();
  const freightValue = form.values.frete || 0;
  const additionalValue = form.values.valorAdicional || 0;
  const discountTypeId = form.values.tipoDesconto || 2;
  const calculateDiscount = (productsTotal: number, valueString: string) => {
    let stringValueFormatted = valueString.replace('.', '').replace('%', '');
    stringValueFormatted = stringValueFormatted.replace(',', '.');

    const value = Number(stringValueFormatted || 0);

    if (discountTypeId === 1 && value > 100) {
      form.setFieldValue('valorDesconto', 100);
      return;
    }

    if (
      discountTypeId === 2 &&
      value > productsTotal + freightValue + additionalValue
    ) {
      form.setFieldValue(
        'valorDesconto',
        productsTotal + freightValue + additionalValue
      );
      return;
    }
  };

  return { calculateDiscount };
};
