import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CONVERT_UNIT_OF_MEASURE,
  FETCH_LIST_UNITS_BY_CLASS,
} from '@/ControllerApiHook/UniqIds/Inventory/BalanceAdjustment';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { isNil } from 'lodash';
import { useEffect } from 'react';

export const useCreateCallAPI = ({ typeClassProductId, form }: IUseCallAPI) => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: CONVERT_UNIT_OF_MEASURE,
    entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
  });

  const {
    data: listunitsMeasurements,
    isLoading: isUnitOfMeasureLoading,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    enabled: !isNil(typeClassProductId),
  });

  useEffect(() => {
    typeClassProductId &&
      refetch({
        filter: [
          {
            filterName: 'tipoClasseProduto',
            value: typeClassProductId,
          },
        ],
      });
  }, [typeClassProductId, refetch]);

  const { data: unitsMeasurementsDefault } = useControllerQueryApiHook<{
    unidadeEstoqueId: number;
  }>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [form?.values?.produtoExternalId],
  });

  return {
    mutateAsync,
    listunitsMeasurements,
    isUnitOfMeasureLoading,
    unitsMeasurementsDefault,
  };
};
