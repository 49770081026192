import { SaleOrderItem } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import DateUtils from '@/Utils/DateUtils';

interface IUseEditMemorizations {
  item?: SaleOrderItem;
}

export const useEditMemorizations = ({ item }: IUseEditMemorizations) => {
  const initialValues = {
    pedidoVendaItemId: item?.pedidoVendaItemId,
    produtoExternalId: item?.produtoAcabado.produtoExternalId,
    prescritorExternalId: item?.produtoAcabado.prescritorExternalId,
    dataPrescricao: DateUtils.convertDate(item?.produtoAcabado.dataPrescricao),
    valorUnitario: item?.valorUnitario,
    quantidade: item?.quantidade,
    tipoDesconto: item?.tipoDescontoId,
    desconto:
      item?.tipoDescontoId === 1
        ? item.percentualDescontoUnitario
        : item?.valorDescontoUnitario,
    total: item?.valorTotalItem,
  };

  return { initialValues };
};
