import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';

import styles from '../../../../../createPharmaceuticalForm.module.scss';

const DescriptionPharmaceuticalForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TextInput
      className={styles['text-input']}
      name="descricao"
      placeHolder={t(
        'production.pharmaceuticalForm.create.descriptionPlaceholder'
      )}
      required
      label={t('production.pharmaceuticalForm.create.description')}
    />
  );
};

export default DescriptionPharmaceuticalForm;
