import { IFChildren } from '@/Components/Form';
import { FC } from 'react';
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { useTranslation } from 'react-i18next';
import { useDocumentCardMemorizations } from './Hooks/useDocumentCardMemorizations';
import { useDocumentCardFunctions } from './Hooks/useDocumentCardFunctions';
import { useDocumentCardCallAPI } from './Hooks/useDocumentCardCallAPI';
import { DocumentCardSchema } from '@/Pages/Sectors/Order/Supplier/Create/Components/Cards/DocumentCard/documentCard.schema';

export const DocumentCard: FC<{ props: IFChildren; personType: number }> = ({
  props,
  personType,
}) => {
  const { t } = useTranslation();

  const { documentList, fetchNewPageDocument } = useDocumentCardCallAPI({
    personType,
  });

  const { column, modal } = useDocumentCardMemorizations({
    fetchNewPageDocument,
    documentList,
  });

  const { onChange } = useDocumentCardFunctions({ props });

  return (
    <Card title={t('document.documents')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        collum={column}
        modal={modal}
        onChange={onChange}
        buttonLabel={t('document.addDocument')}
        empytDataDescription={t('document.documentEmpyt')}
        actions
        entityExternalIdName="prescritorDocumentoExternalId"
        type="documentType"
        schema={DocumentCardSchema}
      />
    </Card>
  );
};
