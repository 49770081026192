import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { IUseCheckNameDuplicationCallAPI } from "./interfaces";
import { 
  THERE_IS_BIND_LABORATORY_TO_COMPANY 
} from "@/ControllerApiHook/UniqIds/Production/LaboratoryKeys";
import { LaboratoryAPI } from "@/Data/API/Production/Laboratory";
import { debounce } from "lodash";

export const useCheckNameDuplicationCallAPI = ({
  initialLaboratoryName
}: IUseCheckNameDuplicationCallAPI) => {
   const { mutateAsync }: any =
  useControllerMutateApiHook({
    uniqId: THERE_IS_BIND_LABORATORY_TO_COMPANY,
    entityApi: LaboratoryAPI.laboratoryBindCompany,
    withoutToastError: true
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      nome: string | null | undefined,
      empresaExternalId: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = 
          initialLaboratoryName !== nome?.trim() && empresaExternalId
          ? (await mutateAsync({
            descricao: nome,
            empresaExternalId
          })) as boolean
          : true;

        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkNameDuplicationsDebounced
  };
};