export const userGroupsEN = {
  list: {
    name: 'Name',
    status: 'Status',
    users: 'Users',

    statusTypes: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },

  editGroups: {
    modal: {
      user: 'User',
      add: 'Add',
      users: 'users',
      participant: 'participant',
      addBtn: 'Add to group',
    },
    errors: {
      requiredName: 'Name is required',
      requiredDescription: 'Description is required',
      maxDescriptions: 'Description can only have 200 characters',
    },
    success: 'User group edited!',
    modalCannotDelete: {
      messageTitle: 'the users group',
      manyMessageTitle: 'user groups',
      messageSubtitle: 'the users group',
      manyMessageSubtitle: 'the users groups:',
      messageReasonCannotDelete:
        'This user group has registered users and therefore cannot be deleted.',
      manyMessageReasonCannotDelete:
        'These user groups have registered users and therefore it is not possible to delete them.',
      warning: 'If you no longer want to use this group, change its status to "Inactive" or remove the users.',
    },
  },
};

export const userGroupsPT = {
  list: {
    name: 'Nome',
    status: 'Status',
    users: 'Usuários',

    statusTypes: {
      active: 'Ativo',
      inactive: 'Inativo',
    },
  },
  editGroups: {
    modal: {
      user: 'Usuário',
      add: 'Adicionar',
      users: 'participantes',
      participant: 'participante',
      addBtn: 'Adicionar ao grupo',
    },
    errors: {
      requiredName: 'Nome é obrigatório',
      requiredDescription: 'Descricão é obrigatório',
      maxDescriptions: 'Descricão só pode ter no máximo 200 caracteres',
    },
    success: 'Grupo de usuários editado!',
    modalCannotDelete: {
      messageTitle: 'o grupo de usuários',
      manyMessageTitle: 'grupos de usuários',
      messageSubtitle: 'o grupo de usuários',
      manyMessageSubtitle: 'os grupos de usuários:',
      messageReasonCannotDelete:
        'Esse grupo de usuários possui usuários cadastrados e por isso não é possível a sua exclusão.',
      manyMessageReasonCannotDelete:
        'Esses grupos de usuários possuem usuários cadastrados e por isso não é possível a sua exclusão.',
      warning: 'Caso não queira mais utilizar esse grupo, mude o status dele para "Inativo" ou remova os usuários.',
    },
  },
};
