import { isNil } from 'lodash';
import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IUseCallAPI } from './interfaces';

export const useAmountDefaultCallAPI = ({
  classificationProductId,
}: IUseCallAPI) => {
  const { 
    data: listunitsMeasurements,
    refetch: refetchListUnitsMeasurements 
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    pagination: {
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: classificationProductId,
        },
      ],
    },
    enabled: !isNil(classificationProductId),
  });

  return {
    listunitsMeasurements,
    refetchListUnitsMeasurements
  };
};
