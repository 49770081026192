import { FC } from 'react';
import { Form } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/Components/Modal';
import { InfoCard } from './Components/Cards/InfoCard';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import { ContactCard } from './Components/Cards/ContactCard';
import { AddressCard } from './Components/Cards/AddressCard';
import { DocumentCard } from './Components/Cards/DocumentCard';
import { FooterButton } from './Components/FooterButton';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { CreatePhysicalPersonSchema } from './CreatePhysicalClient.schema';
import { CreateLegalClientSchema } from './CreateLegalClient.schema';
import { useCheckCnpjAlreadyUsed } from '@/Pages/Sectors/Order/Supplier/Create/Hooks/useInputCnpjFunctions';
import { useCheckCpfAlreadyUsed } from '@/Pages/Sectors/Order/Supplier/Create/Hooks/useInputCpfFunctions';

export const CreateCustomers: FC = () => {
  const { isBackModalOpen, setIsBackModalOpen, personType, setPersonType } =
    useCreateStates();

  const { createClient } = useCreateCallAPI();

  const { initialValues, body } = useCreateMemorizations();

  const { onSubmit, onOkClick, onCancelClick, onClose } = useCreateFunctions({
    createClient,
    setIsBackModalOpen,
    personType,
  });

  const { t } = useTranslation();

  const { checkCnpjDuplicationsDebounced } = useCheckCnpjAlreadyUsed();

  const { checkCpfDuplicationsDebounced } = useCheckCpfAlreadyUsed();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      schema={
        personType === 0
          ? CreatePhysicalPersonSchema(checkCpfDuplicationsDebounced, t)
          : CreateLegalClientSchema(checkCnpjDuplicationsDebounced, t)
      }
      individualValidateOnChange
    >
      {(props) => (
        <>
          <ConnectedFocusError />
          <InfoCard personType={personType} setPersonType={setPersonType} />
          <ContactCard props={props} />
          <AddressCard props={props} />
          <DocumentCard props={props} personType={personType} />
          <FooterButton
            setIsBackModalOpen={setIsBackModalOpen}
            createClient={createClient}
          />
          <Modal
            visible={isBackModalOpen}
            onOkClick={onOkClick}
            onCancelClick={onCancelClick}
            onClose={onClose}
            okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
            title={t('supply.balanceAdjustment.backModal.title')}
            body={body}
          />
        </>
      )}
    </Form>
  );
};
