import React from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Cards/InfoCard';
import { FinancialCard } from './Components/Cards/FinancialCard';
import { PackagingsCard } from './Components/Cards/PackagingsCard';
import { PharmaceuticalFormCreateSchema } from './CreatePharmaceuticalForm.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { 
  useCheckNameDuplicationCallAPI 
} from './Components/Cards/InfoCard/Rows/DescriptionRow/Hooks/useCheckNameDuplicationCallAPI';
import { useTranslation } from 'react-i18next';

export const CreatePharmaceuticalForm: React.FC = () => {
  const { t } = useTranslation();

  const { createPharmaceuticalForm, isLoadingCreatePharmaceuticalForm } =
    useCreateCallAPI();

  const {
    checkNameDuplicationsDebounced
  } = useCheckNameDuplicationCallAPI({});

  const { onSubmit } = useCreateFunctions({
    createPharmaceuticalForm,
  });

  return (
    <Form 
      onSubmit={onSubmit} 
      schema={PharmaceuticalFormCreateSchema(checkNameDuplicationsDebounced, t)}
      individualValidateOnChange
    >
      <>
        <InfoCard />
        <FinancialCard />
        <PackagingsCard />
        <FooterButtons
          isLoadingCreatePharmaceuticalForm={isLoadingCreatePharmaceuticalForm}
          onSubmit={onSubmit}
        />
        <ConnectedFocusError />
      </>
    </Form>
  );
};
