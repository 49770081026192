import { SaleOrderItem } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useState } from 'react';
import { ISelectedTableItem } from '..';

export const useTableItemStates = () => {
  const [editProductItem, setEditProductItem] = useState<SaleOrderItem>();
  const [contentItemHeight, setContentItemHeight] = useState<number>();
  const [scrollVisible, setScrollVisible] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState<
    ISelectedTableItem | undefined
  >(undefined);

  return {
    editProductItem,
    setEditProductItem,

    contentItemHeight,
    setContentItemHeight,

    scrollVisible,
    setScrollVisible,

    selectedTableItem,
    setSelectedTableItem,
  };
};
