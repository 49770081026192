import { CircularStatus } from '@/Components/CircularStatus';

interface ICircularStatusComponentProps {
  form: any;
  productQuantity: number;

  productDeficit: any;
  itemId: any;
  quantity: number;
  classDescription: string;
}

const parseDate = (date: string) => {
  const parts = date.split('/');
  return new Date(+parts[2], +parts[1] - 1, +parts[0]);
};

export const checkTechnicalInformation = (
  lot: any,
  verifyTechnical = true
): boolean => {
  if (!lot.quantidade) return false;
  if (!lot.numeroLote) return false;
  if (!lot.dataFabricacao) return false;
  if (!lot.dataValidade) return false;
  if (!lot.localEstoqueExternalId) return false;
  if (!lot.informacaoTecnica && verifyTechnical) {
    return false;
  } else if (verifyTechnical) {
    if (!lot.informacaoTecnica.densidade) return false;
    if (!lot.informacaoTecnica.diluicaoFornecedor) return false;
    if (!lot.informacaoTecnica.fatorDiluicaoFornecedor) return false;
    if (!lot.informacaoTecnica.concentracaoAgua) return false;
    if (!lot.informacaoTecnica.fatorConcentracaoAgua) return false;
  }
  return true;
};

export const defineLotStatus = (
  form: any,
  totalQuantity: number,
  productItemId: number,
  productClass: string,
  error?: boolean
): 'check' | 'default' | 'error' => {
  if (error) return 'error';

  const lots: any[] = form.values.lotes;

  const filteredLots = lots.filter(
    (e) => e.notaFiscalEntradaItemId === productItemId
  );
  const lotsNames: any[] = filteredLots
    .map((e) => e.numeroLote)
    .filter((e) => e !== undefined);
  const lotsNamesSet = [...(new Set(lotsNames) as any)];

  let withError = false;
  let isProductLotsOk = true;
  let totalQuantityLots = 0;

  filteredLots.forEach((lot) => {
    totalQuantityLots += lot.quantidade ?? 0;

    if (lot.dataFabricacao && lot.dataValidade) {
      const manufacturingDate = parseDate(lot.dataFabricacao);
      const expirationDate = parseDate(lot.dataValidade);

      withError = manufacturingDate > expirationDate;
    }
  });

  if (totalQuantityLots > totalQuantity) {
    withError = true;
  }

  if (lotsNamesSet.length !== lotsNames.length) {
    withError = true;
  }

  if (withError) {
    return 'error';
  }

  if (productClass === 'Matéria-Prima') {
    filteredLots.forEach((lot) => {
      isProductLotsOk = checkTechnicalInformation(lot, true);
    });
  } else {
    filteredLots.forEach((lot) => {
      isProductLotsOk = checkTechnicalInformation(lot, false);
    });
  }

  if (isProductLotsOk) {
    return 'check';
  }

  return 'default';
};

const defineIconToProductRow = (status: 'default' | 'error' | 'check'): any => {
  const icon = {
    default: 'time',
    error: 'close-x',
    check: 'check',
  };

  return icon[status];
};

const defineColorTypeToProductRow = (
  status: 'default' | 'error' | 'check'
): any => {
  const type = {
    default: 'default',
    error: 'danger',
    check: 'success',
  };

  return type[status];
};

export const CircularStatusComponent: React.FC<
  ICircularStatusComponentProps
> = ({ form, productDeficit, productQuantity, classDescription, itemId }) => {
  return (
    <CircularStatus
      icon={defineIconToProductRow(
        defineLotStatus(
          form,
          productQuantity,
          itemId,
          classDescription,
          !!productDeficit
        )
      )}
      type={defineColorTypeToProductRow(
        defineLotStatus(
          form,
          productQuantity,
          itemId,
          classDescription,
          !!productDeficit
        )
      )}
      color={defineColorTypeToProductRow(
        defineLotStatus(
          form,
          productQuantity,
          itemId,
          classDescription,
          !!productDeficit
        )
      )}
    />
  );
};
