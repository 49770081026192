export const noPermissionEN = {
  administrative: {
    users: {
      route: 'You dont have permission to see the users list',
      create: 'You dont have permission to create new users',
      statusEdit: 'You dont have permission to edit user status',
      delete: 'You dont have permission to delete users',
      detail: 'You dont have permission to see user details',
      edit: 'You dont have permission to edit users personal data',
      editGroup: 'You dont have permission to eit users group',
    },

    userGroups: {
      route: 'You dont have permission to list user groups',
      create: 'You dont have permission to create user groups',
      detail: 'You dont have permission to see user group details',
      delete: 'You dont have permission to delete user groups',
      edit: 'You dont have permission to edit details of user groups',
      statusEdit: 'You dont have permission to edit user group details',
    },

    company: {
      route: 'You dont have permission to see the company list',
      editData: 'You dont have permission to edit the company data',
      editAddress: 'You dont have permission to edit company address',
      editiResponsible:
        'You dont have permission to edit responsable user for this company',
      detail: 'You dont have permission to see company details',
    },
  },
  supply: {
    products: {
      route: 'You dont have permission to view the product list',
      create: 'You dont have permission to register new products',
      statusEdit: 'You dont have permission to change product status',
      delete: 'You dont have permission to delete products',
      detail: 'You dont have permission to access product details',
      edit: 'You dont have permission to edit product information',
      editFinancial:
        'You dont have permission to see financial details of the product',
      youCantCreateProductIncompatible:
        'You dont have permission to create product incompatible',
      youCantRemoveProductIncompatible:
        'You dont have permission to remove product incompatible',
      editIncompatible: 'You dont have permission to edit product incompatible',
      editSynonym: 'You dont have permission to edit product synonym',
      viewIncompatible:
        'You dont have permission to view incompatible products',
      viewSynonym: 'You dont have permission to view synonym products',
      viewDiluted: 'You do not have permission to view diluted products',
      editDiluted: 'You do not have permission to edit diluted products',
      removeDiluted: 'You do not have permission to remove diluted products',
      createDiluted: 'You do not have permission to create diluted products',
    },
    groups: {
      route: 'You dont have permission to view the product group list',
      create: 'You dont have permission to register new product groups',
      createSubgroup:
        'You dont have permission to register new product subgroups',
      delete: 'You dont have permission to delete product groups',
      detail: 'You dont have permission to see details of product groups',
      edit: 'You dont have permission to edit product group details',
    },
    subGroups: {
      route: 'You dont have permission to see the product subgroup list',
      create: 'You dont have permission to register new product subgroups',
      createProduct: 'You dont have permission to register new products',
      delete: 'You dont have permission to delete product subgroups',
      detail: 'You dont have permission to see product subgroup details',
      detailGroups:
        'You dont have permission to view details of product groups',
      edit: 'You dont have permission to edit product subgroup details',
    },
    lot: {
      route: 'You dont have permission to see the batch list',
      create: 'You dont have permission to register new batches',
      statusEdit: 'You dont have permission to change batch status',
      delete: 'You dont have permission to delete batches',
      detail: 'You dont have permission to access batch details',
      detailProducts: 'You dont have permission to see product details',
      edit: 'You dont have permission to edit batch information',
    },
    supplyPlaces: {
      route: 'You dont have permission to view the list of stock locations',
      create: 'You dont have permission to register new stock locations',
      statusEdit:
        'You dont have permission to change status of stock locations',
      delete: 'You dont have permission to delete stock locations',
      detail: 'You dont have permission to view details of stock locations',
      edit: 'You dont have permission to edit stock location details',
    },
    transfers: {
      route: 'You dont have permission to view the transfer list',
      create: 'You dont have permission to register new transfers',
      detail: 'You dont have permission to see transfer details',
    },
    losses: {
      route: 'You dont have permission to see the loss list',
      create: 'You dont have permission to register new losses',
      detail: 'You dont have permission to see loss details',
      edit: 'You dont have permission to edit loss details',
    },
    balanceAdjustments: {
      route: 'You dont have permission to see the list of balance adjustments',
      create: 'You dont have permission to register new balance adjustments',
      detail: 'You dont have permission to see details of balance adjustments',
    },
    messageProduct: {
      route: 'You dont have permission to see the product message list',
      create: 'You dont have permission to register new product messages',
      delete: 'You dont have permission to delete product messages',
      detail: 'You dont have permission to see product messages',
      edit: 'You dont have permission to edit product messages',
      removeLink:
        'You are not allowed to remove a product from a product message',
      linkTabProduct: 'You are not allowed to view product messages',
    },
    inventory: {
      details: 'You dont have permission to see inventory details',
      register: 'You dont have permission to register inventory',
    },
  },
  sale: {
    service: {
      route: 'You dont have permission to see the services list',
      create: 'You are not allowed to register new services',
      detail: 'You are not allowed to view the details of the appointment',
    },
    order: {
      route: 'You are not allowed to view the sales order list',
      create: 'You are not allowed to register new sales orders',
      detail: 'You are not allowed to view sales order details',
      editOrder: 'You are not allowed to edit sales order details',
      toApprove: 'You are not allowed to approve the sales order',
      disapprove: 'You are not allowed to reject the sales order',
      cancel: 'You are not allowed to cancel the sales order',
      reserve: 'You are not allowed to reverse the sales order',
    },
    client: {
      route: 'You are not allowed to view the client list',
      create: 'You are not allowed to register new clients',
      detail: 'You are not allowed to view client details',
      edit: 'You are not allowed to edit client details',
      editAddress: 'You are not allowed to edit customer addresses',
      editContacts: 'You are not allowed to edit customer contacts',
      editDocuments: 'You are not allowed to edit customer documents',
      editStatus: 'You are not allowed to edit client status',
      delete: 'You dont have permission to delete clients',
    },
    prescriber: {
      route: 'You are not allowed to view the prescribers list',
      create: 'You are not allowed to register new prescribers',
      detail: 'You are not allowed to view prescriber details',
      edit: 'You are not allowed to edit prescriber details',
      editAddress: 'You are not allowed to edit prescriber addresses',
      editContacts: 'You are not allowed to edit prescriber contacts',
      editDocuments: 'You are not allowed to edit prescriber documents',
      editStatus: 'You are not allowed to edit prescriber status',
      delete: 'You dont have permission to delete prescribers',
    },
    specialties: {
      route: 'You are not allowed to view the prescribers specialties list',
      editStatus: 'You are not allowed to edit the prescriber skill status',
      edit: 'You dont have permission to edit specialtie details',
      delete: 'You dont have permission to delete specialties',
    },
  },
  purchasing: {
    purchaseOrder: {
      route: 'You dont have permission to see list of order purchase',
      create: 'You dont have permission to register new order purchase',
      edit: 'You dont have permission to edit purchase order details',
      detail: 'You are not allowed to access the purchase order details',
      delete: 'You dont have permission to delete order purchase',
      approve: 'You do not have permission to approve the purchase order',
      disapprove: 'You are not allowed to disapprove the purchase order',
      confirmWithProvider:
        'You are not allowed to confirm the purchase with the supplier',
      reverseReleased:
        'You are not allowed to reverse the released purchase order',
      reverseConfirmWithProvider:
        'You are not allowed to reverse the purchase order confirmed with supplier',
    },
    supplier: {
      route: 'You dont have permission to see list of suppliers',
      create: 'You dont have permission to register new suppliers',
      edit: 'You dont have permission to edit suppliers details',
      editContact: 'You dont have permission to edit suppliers contacts',
      editAddress: 'You dont have permission to edit suppliers addresses',
      editDocument: 'You dont have permission to edit suppliers documents',
      statusEdit: 'You dont have permission to change status of suppliers',
      detail: 'You are not allowed to access the suppliers details',
      delete: 'You dont have permission to delete suppliers',
    },
    invoice: {
      route: 'You dont have permission to see list of invoices',
      create: 'You dont have permission to register new invoices',
      detail: 'You are not allowed to access the invoices details',
      delete: 'You dont have permission to delete the invoice',
      throwLots: 'You dont have permission to throw lots of the invoice',
      edit: 'You dont have permission to edit the invoice details',
    },
  },

  production: {
    prescription: {
      route: 'You dont have permission to see list of prescriptions',
    },

    packingClassification: {
      list: 'You do not have permission to view the list of packaging classifications',
      route: 'You dont have permission to see list of packing classification',
      create: 'You dont have permission to register new packing classification',
      details:
        'You are not allowed to access the packing classification details',
      delete: 'You dont have permission to delete  packing classification',
      edit: 'You dont have permission to edit  packing classification details',
      statusEdit:
        'You are not allowed to change the status of packaging classifications',
      removeLink:
        'You are not allowed to remove a product from a package classification',
    },
    capsuleColors: {
      route: 'You dont have permission to see list of capsule colors',
      create: 'You dont have permission to register new capsule colors',
      details: 'You are not allowed to access the capsule colors details',
      delete: 'You dont have permission to delete capsule colors',
      edit: 'You dont have permission to edit capsule colors details',
    },
    pharmaceuticalForm: {
      route:
        'You do not have permission to view the list of pharmaceutical forms',
      create: 'You do not have permission to register new pharmaceutical forms',
      details:
        'You do not have permission to access the details of pharmaceutical forms',
      delete: 'You do not have permission to delete pharmaceutical forms',
      edit: 'You do not have permission to edit the details of pharmaceutical forms',
      permissionsEdit:
        'You do not have permission to edit Pharmaceutical Form details',
      statusEdit:
        'You do not have permission to change the status of pharmaceutical forms',
      permissionsDelete:
        'You do not have permission to delete pharmaceutical forms',
      permissionsLink:
        'You do not have permission to link packaging classifications',
      permissionsUnlink:
        'You do not have permission to remove packaging classifications',
    },
    laboratory: {
      list: 'You do not have permission to see laboratories list',
      create: 'You do not have permission to register new laboratories',
      details: 'You are not allowed to access the laboratories details',
      delete: 'You do not have permission to delete laboratories',
      edit: 'You do not have permission to edit laboratories details',
      statusEdit: 'You are not allowed to change the  laboratories status',
    },
    posology: {
      list: 'You dont have permission to see list of posologies',
      create: 'You dont have permission to register new posologies',
      details: 'You are not allowed to access the posologies details',
      delete: 'You dont have permission to delete posologies',
      edit: 'You dont have permission to edit posologies details',
      statusEdit: 'You are not allowed to change the status of posologies',
    },
    standardFormula: {
      list: 'You dont have permission to see list of standard formulas',
      create: 'You dont have permission to register new standard formulas',
      details: 'You are not allowed to access the standard formulas details',
      delete: 'You dont have permission to delete standard formulas',
      edit: 'You dont have permission to edit standard formulas details',
    },
  },
  default: 'You dont have permission to do this action',
};

export const noPermissionPT = {
  administrative: {
    users: {
      route: 'Você não tem permissão de ver a lista de usuários',
      create: 'Você não tem permissão de cadastrar novos usuários',
      statusEdit: 'Você não tem permissão de alterar status dos usuários',
      delete: 'Você não tem permissão de excluir usuários',
      detail: 'Você não tem permissão de acessar os detalhes dos usuários',
      edit: 'Você não tem permissão de editar dados pessoais dos usuários',
      editGroup: 'Você não tem permissão de editar grupos dos usuários',
    },

    userGroups: {
      route: 'Você não tem permissão de ver a lista dos grupos de usuários',
      create: 'Você não tem permissão de criar grupos de usuários',
      detail: 'Você não tem permissão de ver detalhes dos grupos de usuário',
      delete: 'Você não tem permissão de excluir grupos de usuários',
      edit: 'Você não tem permissão de editar detalhes dos grupos de usuários',
      statusEdit:
        'Você não tem permissão de alterar status dos grupos de usuários',
    },

    company: {
      route: 'Você não tem permissão de ver a lista de empresas',
      editData: 'Você não tem permissão de editar dados da empresa',
      editAddress: 'Você não tem permissão de editar endereço da empresa',
      editiResponsible:
        'Você não tem permissão de editar usuário responsável da empresa',
      detail: 'Você não tem permissão de ver detalhes das empresas',
    },
  },
  supply: {
    products: {
      route: 'Você não tem permissão de ver a lista de produtos',
      create: 'Você não tem permissão de cadastrar novos produtos',
      statusEdit: 'Você não tem permissão de alterar status dos produtos',
      delete: 'Você não tem permissão de excluir produtos',
      detail: 'Você não tem permissão de acessar os detalhes dos produtos',
      edit: 'Você não tem permissão de editar informações dos produtos',
      editFinancial:
        'Você não tem permissão de ver detalhes financeiros do produto',
      youCantCreateProductIncompatible:
        'Você não tem permissão de cadastrar produtos incompatíveis',
      youCantRemoveProductIncompatible:
        'Você não tem permissão de excluir produtos incompatíveis',
      editIncompatible:
        'Você não tem permissão de editar o produto incompatível',
      editSynonym: 'Você não tem permissão de editar o produto sinônimo',
      viewIncompatible:
        'Você não tem permissão de visualizar os produtos incompativeis',
      viewSynonym: 'Você não tem permissão de visualizar os produtos sinônimos',
      viewDiluted: 'Você não tem permissão de visualizar os produtos diluídos',
      editDiluted: 'Você não tem permissão de editar os produtos diluídos',
      removeDiluted: 'Você não tem permissão de remover os produtos diluídos',
      createDiluted: 'Você não tem permissão de cadastrar produtos diluídos',
    },
    groups: {
      route: 'Você não tem permissão de ver a lista de grupos de produtos',
      create: 'Você não tem permissão de cadastrar novos grupos de produtos',
      createSubgroup:
        'Você não tem permissão de cadastrar novos subgrupos de produtos',
      delete: 'Você não tem permissão de excluir grupos de produtos',
      detail: 'Você não tem permissão de ver detalhes dos grupos de produtos',
      edit: 'Você não tem permissão de editar detalhes dos grupos de produtos',
    },
    subGroups: {
      route: 'Você não tem permissão de ver a lista de subgrupos de produtos',
      create: 'Você não tem permissão de cadastrar novos subgrupos de produtos',
      createProduct: 'Você não tem permissão de cadastrar novos produtos',
      delete: 'Você não tem permissão de excluir subgrupos de produtos',
      detail:
        'Você não tem permissão de ver detalhes dos subgrupos de produtos',
      detailGroups:
        'Você não tem permissão de ver detalhes dos grupos de produtos',
      edit: 'Você não tem permissão de editar detalhes dos subgrupos de produtos',
    },
    lot: {
      route: 'Você não tem permissão de ver a lista de lotes',
      create: 'Você não tem permissão de cadastrar novos lotes',
      statusEdit: 'Você não tem permissão de alterar status dos lotes',
      delete: 'Você não tem permissão de excluir lotes',
      detail: 'Você não tem permissão de acessar os detalhes dos lotes',
      detailProducts: 'Você não tem permissão de ver detalhes dos produtos',
      edit: 'Você não tem permissão de editar informações dos lotes',
    },
    supplyPlaces: {
      route: 'Você não tem permissão de ver a lista de locais de estoque',
      create: 'Você não tem permissão de cadastrar novos locais de estoque',
      statusEdit:
        'Você não tem permissão de alterar status dos locais de estoque',
      delete: 'Você não tem permissão de excluir locais de estoque',
      detail: 'Você não tem permissão de ver detalhes dos locais de estoque',
      edit: 'Você não tem permissão de editar detalhes dos locais de estoque',
    },
    transfers: {
      route: 'Você não tem permissão de ver a lista de transferências',
      create: 'Você não tem permissão de cadastrar novas transferências',
      detail: 'Você não tem permissão de ver detalhes das transferências',
    },
    losses: {
      route: 'Você não tem permissão de ver a lista de perdas',
      create: 'Você não tem permissão de cadastrar novas perdas',
      detail: 'Você não tem permissão de ver detalhes das perdas',
      edit: 'Você não tem permissão de editar detalhes da perda',
    },
    balanceAdjustments: {
      route: 'Você não tem permissão de ver a lista de ajustes de saldo',
      create: 'Você não tem permissão de cadastrar novos ajustes de saldo',
      detail: 'Você não tem permissão de ver detalhes dos ajustes de saldo',
    },
    messageProduct: {
      route: 'Você não tem permissão de ver a lista de mensagens do produto',
      create: 'Você não tem permissão de cadastrar novas mensagens do produto',
      delete: 'Você não tem permissão de excluir mensagens do produto',
      detail: 'Você não tem permissão de ver detalhes das mensagens do produto',
      edit: 'Você não tem permissão de editar mensagens do produto',
      removeLink:
        'Você não tem permissão de remover um produto de uma mensagem do produto',
      linkTabProduct:
        'Você não tem permissão de visualizar as mensagens do produto',
    },
    inventory: {
      details: 'Você não tem permissão de ver detalhes dos inventários',
      register: 'Você não tem permissão de cadastrar novos inventários',
    },
  },
  sale: {
    service: {
      route: 'Você não tem permissão de ver a lista de atendimentos',
      create: 'Você não tem permissão de cadastrar novos atendimentos',
      detail: 'Você não tem permissão de ver os detalhes do atendimento',
    },
    order: {
      route: 'Você não tem permissão de ver a lista de pedidos de venda',
      create: 'Você não tem permissão de cadastrar novos pedidos de venda',
      detail: 'Você não tem permissão de ver os detalhes do pedido de venda',
      editOrder:
        'Você não tem permissão de editar detalhes dos pedidos de venda',
      toApprove: 'Você não tem permissão de aprovar o pedido de venda',
      disapprove: 'Você não tem permissão de reprovar o pedido de venda',
      cancel: 'Você não tem permissão de cancelar o pedido de venda',
      reserve: 'Você não tem permissão de estornar o pedido de venda',
    },

    client: {
      route: 'Você não tem permissão de ver a lista de clientes',
      create: 'Você não tem permissão de cadastrar novos clientes',
      detail: 'Você não tem permissão de ver os detalhes do cliente',
      edit: 'Você não tem permissão de editar detalhes dos clientes',
      editAddress: 'Você não tem permissão de editar endereços dos clientes',
      editContacts: 'Você não tem permissão de editar contatos dos clientes',
      editDocuments: 'Você não tem permissão de editar documentos dos clientes',
      editStatus: 'Você não tem permissão de editar o status do cliente',
      delete: 'Você não tem permissão de excluir clientes',
    },
    prescriber: {
      route: 'Você não tem permissão de ver a lista de prescritores',
      create: 'Você não tem permissão de cadastrar novos prescritores',
      detail: 'Você não tem permissão de ver os detalhes do prescritor',
      edit: 'Você não tem permissão de editar detalhes dos prescritores',
      editAddress:
        'Você não tem permissão de editar endereços dos prescritores',
      editContacts:
        'Você não tem permissão de editar contatos dos prescritores',
      editDocuments:
        'Você não tem permissão de editar documentos dos prescritores',
      editStatus: 'Você não tem permissão de editar o status do prescritor',
      delete: 'Você não tem permissão de excluir prescritores',
    },
    specialties: {
      route:
        'Você não tem permissão de ver a lista de especialidades dos prescritores',
      editStatus:
        'Você não tem permissão de editar o status da especialidade do prescritor',
      edit: 'Você não tem permissão de editar detalhes da especialidade do prescritor',
      statusEdit:
        'Você não tem permissão de alterar status das especialidades do prescritor',
      delete: 'Você não tem permissão de excluir especialidade do prescritor',
    },
  },

  purchasing: {
    purchaseOrder: {
      route: 'Você não tem permissão de ver a lista de pedido de compra',
      create: 'Você não tem permissão de cadastrar novos pedidos de compra',
      detail:
        'Você não tem permissão de acessar os detalhes do pedido de compra',
      edit: 'Você não tem permissão de editar os detalhes dos pedidos de compra',
      delete: 'Você não tem permissão de excluir pedidos de compra',
      approve: 'Você não tem permissão de aprovar o pedido de compra',
      disapprove: 'Você não tem permissão de reprovar o pedido de compra',
      confirmWithProvider:
        'Você não tem permissão de confirmar a compra com o fornecedor',
      reverseReleased:
        'Você não tem permissão de estornar o pedido de compra liberado',
      reverseConfirmWithProvider:
        'Você não tem permissão de estornar o pedido de compra confirmado com fornecedor',
    },
    supplier: {
      route: 'Você não tem permissão de ver a lista de fornecedores',
      create: 'Você não tem permissão de cadastrar novos fornecedores',
      detail: 'Você não tem permissão de acessar os detalhes do fornecedor',
      edit: 'Você não tem permissão de editar detalhes dos fornecedores',
      editContact: 'Você não tem permissão de editar contatos dos fornecedores',
      editAddress:
        'Você não tem permissão de editar endereços dos fornecedores',
      editDocument:
        'Você não tem permissão de editar documentos dos fornecedores',
      statusEdit: 'Você não tem permissão de alterar status dos fornecedores',
      delete: 'Você não tem permissão de excluir fornecedores',
    },
    invoice: {
      route: 'Você não tem permissão de ver a lista de notas fiscais',
      create: 'Você não tem permissão de cadastrar novas notas fiscais',
      detail: 'Você não tem permissão de acessar os detalhes da nota fiscal',
      delete: 'Você não tem permissão de excluir a nota fiscal',
      throwLots: 'Você não tem permissão de lançar lotes da nota fiscal',
      edit: 'Você não tem permissão de editar detalhes da nota fiscal',
    },
  },

  production: {
    prescription: {
      route: 'Você não tem permissão de ver a lista de receitas',
    },

    packingClassification: {
      list: 'Você não tem permissão de ver a lista de classificacao de embalagem',
      route:
        'Você não tem permissão de ver a lista de classificação de embalagem',
      create:
        'Você não tem permissão de cadastrar novas classificações de embalagem',
      details:
        'Você não tem permissão de acessar os detalhes das classificações de embalagem',
      delete: 'Você não tem permissão de excluir classificações de embalagem',
      edit: 'Você não tem permissão de editar os detalhes das classificações de embalagem',
      statusEdit:
        'Você não tem permissão de alterar status das classificações de embalagem',
      removeLink:
        'Você não tem permissão de remover um produto de uma classificação de embalagem',
    },
    capsuleColors: {
      route: 'Você não tem permissão de ver a lista de cores de cápsulas',
      create: 'Você não tem permissão de cadastrar novas cores de cápsulas',
      details:
        'Você não tem permissão de acessar os detalhes de cores de cápsulas',
      delete: 'Você não tem permissão de excluir cores de cápsulas',
      edit: 'Você não tem permissão de editar os detalhes das cores de cápsulas',
    },
    pharmaceuticalForm: {
      route: 'Você não tem permissão de ver a lista de formas farmacêuticas',
      create: 'Você não tem permissão de cadastrar novas formas farmacêuticas',
      details:
        'Você não tem permissão de acessar os detalhes das formas farmacêuticas',
      delete: 'Você não tem permissão de excluir formas farmacêuticas',
      edit: 'Você não tem permissão de editar os detalhes das formas farmacêuticas',
      permissionsEdit:
        'Você não tem permissão de editar detalhes das formas farmacêuticas',
      statusEdit:
        'Você não tem permissão de alterar status das formas farmacêuticas',
      permissionsDelete:
        'Você não tem permissão de excluir formas farmacêuticas',
      permissionsLink:
        'Você não tem permissão de vincular classificações de embalagem',
      permissionsUnlink:
        'Você não tem permissão de remover classificações de embalagem',
    },
    laboratory: {
      list: 'Você não tem permissão de ver a lista de laboratórios',
      create: 'Você não tem permissão de cadastrar novos laboratórios',
      details: 'Você não tem permissão de acessar os detalhes dos laboratórios',
      delete: 'Você não tem permissão de excluir laboratórios',
      edit: 'Você não tem permissão de editar os detalhes dos laboratórios',
      statusEdit: 'Você não tem permissão de alterar status dos laboratórios',
    },
    posology: {
      list: 'Você não tem permissão de ver a lista de posologias',
      create: 'Você não tem permissão de cadastrar novas posologias',
      details: 'Você não tem permissão de acessar os detalhes de posologias',
      delete: 'Você não tem permissão de excluir posologias',
      edit: 'Você não tem permissão de editar os detalhes das posologias',
      statusEdit: 'Você não tem permissão de alterar status das posologias',
    },

    standardFormula: {
      list: 'Você não tem permissão de ver a lista de formulas padrão',
      create: 'Você não tem permissão de cadastrar novas formulas padrão',
      details:
        'Você não tem permissão de acessar os detalhes de formulas padrão',
      delete: 'Você não tem permissão de excluir fórmulas padrão',
      edit: 'Você não tem permissão de editar os detalhes das fórmulas padrão',
    },
  },

  default: 'Você não tem permissão para realizar esta ação',
};
