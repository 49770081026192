import DateUtils from '@/Utils/DateUtils';
import { UseMutationResult } from '@tanstack/react-query';
import { FormikContextType } from 'formik';

interface IUseInsuficientFunds {
  changeVisible: () => void;
  editModal: boolean;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  form: FormikContextType<any>;
}

export const useInsuficientFunds = ({
  changeVisible,
  editModal,
  addProduct,
  editProduct,
  form,
}: IUseInsuficientFunds) => {
  const onOkClick = async () => {
    changeVisible();
    if (editModal)
      await editProduct.mutateAsync({
        ...form.values,
        ...(form.values.dataPrescricao && {
          dataPrescricao: DateUtils.convertDateToApi(
            form.values.dataPrescricao
          ),
        }),
      });
    else
      await addProduct.mutateAsync({
        ...form.values,
        ...(form.values.dataPrescricao && {
          dataPrescricao: DateUtils.convertDateToApi(
            form.values.dataPrescricao
          ),
        }),
      });
  };

  const onCancelClick = () => {
    form.setSubmitting(false);
    changeVisible();
  };

  const onClose = () => {
    form.setSubmitting(false);
    changeVisible();
  };
  
  return { onOkClick, onCancelClick, onClose };
};
