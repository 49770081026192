export const useCompareValues = () => {
  const isNumberString = (value: any) =>
    typeof value === 'string' &&
    !isNaN(parseFloat(value)) &&
    isFinite(Number(value));

  const convertToComparable = (value: any) => {
    if (isNumberString(value)) {
      return parseFloat(value);
    }
    return value;
  };

  const compareValues = (obj1: any, obj2: any) => {
    const clone1 = JSON.parse(JSON.stringify(obj1));
    const clone2 = JSON.parse(JSON.stringify(obj2));

    delete clone1.metadata;
    delete clone2.metadata;

    const differences: string[] = [];

    const findDifferences = (o1: any, o2: any, path = '') => {
      const allKeys = Array.from(
        new Set([...Object.keys(o1), ...Object.keys(o2)])
      );

      for (const key of allKeys) {
        const currentPath = path ? `${path}.${key}` : key;
        const value1 = convertToComparable(o1[key]);
        const value2 = convertToComparable(o2[key]);

        if (!(key in o1)) {
          differences.push(
            `A propriedade "${currentPath}" está presente apenas no segundo objeto`
          );
        } else if (!(key in o2)) {
          differences.push(
            `A propriedade "${currentPath}" está presente apenas no primeiro objeto`
          );
        } else if (
          typeof value1 === 'object' &&
          typeof value2 === 'object' &&
          value1 !== null &&
          value2 !== null
        ) {
          findDifferences(value1, value2, currentPath);
        } else if (value1 !== value2) {
          differences.push(
            `A propriedade "${currentPath}" difere: ${value1} !== ${value2}`
          );
        }
      }
    };

    findDifferences(clone1, clone2);

    if (differences.length === 0) {
      return { isEqual: true, differences: [] };
    }
    return { isEqual: false, differences };
  };

  return { compareValues };
};
