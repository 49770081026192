import { Col, Row } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Dropdown, TextInput } from '../../../../../../../../Components';
import { RadioButton } from '../../../../../../../../Components/Inputs/RadioButton';
import { RadioGroup } from '../../../../../../../../Components/Inputs/RadioButton/RadioGroup';
import { TextArea } from '../../../../../../../../Components/Inputs/TextArea';
import { TextInputWithMask } from '../../../../../../../../Components/Inputs/TextInputWithMask';
import { IProviderTypeResponse } from '@/Data/Interfaces/response/Person/ProviderTypeResponse';
import { useInfoSectionCallAPI } from './Hooks/useInfoSectionCallAPI';
import { IPagination } from '@/ControllerApiHook/Controller';

import styles from '../../../ProviderCreate.module.scss';

interface IInfoCard {
  refetch: (newPagination: IPagination) => void;
  personType: number;
  setPersonType: Dispatch<SetStateAction<number>>;
}

export const InfoCard: FC<IInfoCard> = ({
  refetch,
  personType,
  setPersonType,
}) => {
  const { t } = useTranslation();

  const { providerTypes } = useInfoSectionCallAPI();

  return (
    <Card title={t('purchasing.provider.create.info')} withoutCollapse>
      <Row gutter={[16, 0]} style={{ marginBottom: 25 }}>
        <Col span={12}>
          <RadioGroup
            className={styles['row']}
            name="tipoPessoa"
            onChange={(x) => {
              setPersonType(+x);
              refetch({
                filter: [
                  { filterName: 'tipoPessoa', value: x },
                  { filterName: 'ordem', value: [0, 1, 2] },
                ],
              });
            }}
          >
            <RadioButton
              name="tipoPessoaCNPJ"
              value={1}
              label={t('purchasing.provider.create.pj')}
            />
            <RadioButton
              name="tipoPessoaCPF"
              value={0}
              label={t('purchasing.provider.create.pf')}
            />
          </RadioGroup>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Dropdown
            name="tipoFornecedorId"
            required
            label={t('purchasing.provider.create.type')}
            placeHolder={t('purchasing.provider.create.typePlaceholder')}
            items={
              providerTypes?.data?.map((x: IProviderTypeResponse) => ({
                id: x.id,
                label: x.name,
              })) || []
            }
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={10}>
          {personType === 1 ? (
            <TextInput
              name="fantasyName"
              required
              label={t('purchasing.provider.create.fantasyName')}
              placeHolder={t(
                'purchasing.provider.create.fantasyNamePlaceholder'
              )}
            />
          ) : (
            <TextInput
              name="nome"
              required
              label={t('purchasing.provider.create.fullName')}
              placeHolder={t('purchasing.provider.create.fullNamePlaceholder')}
            />
          )}
        </Col>

        {personType === 1 && (
          <Col span={10}>
            <TextInput
              name="razaoSocial"
              label={t('purchasing.provider.create.social')}
              placeHolder={t('purchasing.provider.create.socialPlaceholder')}
            />
          </Col>
        )}
        <Col span={4}>
          {personType === 1 ? (
            <TextInputWithMask
              name="cnpj"
              required
              label={t('purchasing.provider.create.cnpj')}
              placeHolder={t('purchasing.provider.create.cnpjPlaceholder')}
              mask="cnpjMask"
            />
          ) : (
            <TextInputWithMask
              name="cpf"
              required
              label={t('purchasing.provider.create.cpf')}
              placeHolder={t('purchasing.provider.create.cpfPlaceholder')}
              mask="cpfMask"
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('purchasing.provider.create.obs')}
            placeholder={t('purchasing.provider.create.obsPlaceholder')}
            maxLength={1000}
            rows={6}
          />
        </Col>
      </Row>
    </Card>
  );
};
