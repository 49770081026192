export const useFormatQuantity = () => {
  const formatQuantity = (numero: number, language?: string) => {
    if (!language) language = 'pt-BR';
    if (!numero) numero = 0;

    return numero.toLocaleString(language, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  };

  return { formatQuantity };
};
