import { PopOver } from '@/Components';
import { FC, ReactNode } from 'react';

import styles from './Popover.module.scss';

interface IInputWitchSeatchPopover {
  visible: boolean;
  onOpenChange?: (visible: boolean) => void;
  popover?: {
    content: ReactNode;
    title: ReactNode;
    width?: number;
  };
  children: ReactNode;
}

export const InputWitchSeatchPopover: FC<IInputWitchSeatchPopover> = ({
  visible,
  onOpenChange,
  children,
  popover,
}) => {
  return (
    <PopOver
      content={
        <div style={{ width: popover?.width }}>
          <div className={styles['container']}>{popover?.content}</div>
        </div>
      }
      placement="bottomLeft"
      title={
        <div className={styles['content-title']}>
          <div
            style={popover?.width ? { width: popover?.width - 32 } : undefined}
          >
            {popover?.title}
          </div>
        </div>
      }
      trigger="hover"
      visible={visible}
      mouseEnterDelay={1}
      onOpenChange={onOpenChange}
      children={children}
      align={[0, 10]}
    />
  );
};
