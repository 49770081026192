import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetInventory } from '../hooks/useGetInventory';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';

interface IDeleteModalProps {
  visibleDeleteModal: boolean;
  setVisibleDeleteModal: any;
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({
  setVisibleDeleteModal,
  visibleDeleteModal,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { inventory } = useGetInventory(externalId);

  const { t } = useTranslation();

  if (!inventory) return null;

  return (
    <Modal
      title={`${t('supply.inventory.details.deleteInventory')}  "#${
        inventory ? String(inventory.codigo).padStart(5, '0') : ''
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <ReactMarkdown>
              {t('supply.inventory.details.areYouSureDeleteInventory', {
                inventario: `**#${String(inventory.codigo).padStart(5, '0')}**`,
              })}
            </ReactMarkdown>
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleDeleteModal}
      // onOkClick={onDelete}
      okButtonName={t('common.delete')}
      onCancelClick={() => setVisibleDeleteModal(false)}
      onClose={() => setVisibleDeleteModal(false)}
      widthModal={399}
      okButtonType="danger"
    />
  );
};
