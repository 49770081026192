import {
  EDIT_PRODUCT,
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { IUseCallAPI } from './interfaces';

export const useDetailsCallAPI = ({
  externalId,
}: IUseCallAPI) => {
  const editProduct: any = useControllerMutateApiHook({
    uniqId: EDIT_PRODUCT,
    entityApi: ProductAPI.editProduct,
  });

  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [externalId]
  });

  return {
    editProduct,
    product
  };
};