import { useMemo } from 'react';
import _ from 'lodash';
import { IUseMemorizations } from './interfaces';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useModalBodyMemorizations = ({
  mutateAsync,
  dataGroupDropDown,
}: IUseMemorizations) => {
  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(
        async (
          value?: string,
          groupExternalId?: string,
          subGroupExternalId?: string
        ) => {
          try {
            if (value && groupExternalId) {
              const payload: any = {
                descricao: value,
                grupoExternalId: groupExternalId,
              };

              if (subGroupExternalId) {
                payload.subGrupoExternalId = subGroupExternalId;
              }

              return await mutateAsync(payload);
            }
          } catch {}
        },
        500
      ),
    [mutateAsync]
  );

  const items = useMemo(() => {
    return dataGroupDropDown?.data?.map((x: IProductGroupListData) => ({
      id: x.externalId,
      label: x.descricao,
    }));
  }, [dataGroupDropDown]);

  return {
    fetchProductSubGroupValidationName,
    items,
  };
};
