import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { debounce } from 'lodash';
import { THERE_IS_STOCK_LOCATION_TYPE } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';

export const useCheckNameDuplicationAPI = () => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_STOCK_LOCATION_TYPE,
    entityApi: StorageLocationApi.isStorageLocationNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      descricao: string | null | undefined,
      empresaId: string | null | undefined,
      localEstoqueExternalId: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = (await mutateAsync({
          descricao,
          empresaId,
          localEstoqueExternalId,
        })) as boolean;
        callback(result);
      } catch {
        callback(false);
      }
    },
    500
  );

  return { checkNameDuplications: checkNameDuplicationsDebounced };
};
