import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FormatDataToDropdownUtils } from '@/Utils/FormatDataToDropdownUtils';
import { IQueryListParams } from '@/Utils/Http';
import { useTranslation } from 'react-i18next';
import { ListSupplyPlace } from './list-supply-place.service';

export const LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY =
  '[LABORATORY] LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY';

interface IUseListSupplyPlace {
  fieldName: string;
  enabled?: boolean;
  pagination?: IQueryListParams;
  returnFullData?: boolean;
}

export const useSupplyPlaceList = ({
  fieldName,
  enabled,
  pagination,
  returnFullData,
}: IUseListSupplyPlace) => {
  const { t } = useTranslation();
  const { data, refetch, isLoading, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
      entityApi: ListSupplyPlace,
      autoIncrement: true,
      enabled,
      pagination,
    });

  const items = FormatDataToDropdownUtils({
    data: data && data.data ? data.data : [],
    idKey: 'externalId',
    labelKey: 'descricao',
  });

  const onSearch = (search?: string) => {
    refetch({
      search,
    });
  };

  return {
    fullData: returnFullData ? (data && data.data ? data.data : []) : undefined,
    name: fieldName,
    items,
    onSearch,
    isLoading,
    onScrollEnd: fetchNewPage,
  };
};
