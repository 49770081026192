import { IProviderRequest } from '@/Data/Interfaces/request/Purchasing/Providers/IProviderRequest';
import { IUseFunctions } from './interfaces';
import { TypePersonEnum } from '@/Data/Interfaces/model/typePersonEnum';

export const useCreateFunctions = ({
  createProvider,
  personType,
}: IUseFunctions) => {
  const onSubmit = async (values: any) => {
    const body: IProviderRequest = {
      ...values,
      ...(personType === TypePersonEnum.physical_person
        ? { nome: values.nome, cpf: values.cpf }
        : { nome: values.fantasyName, cnpj: values.cnpj }),
    };

    return await createProvider(body);
  };

  return {
    onSubmit,
  };
};
