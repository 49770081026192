import { Http, IQueryListParams } from '../../../Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
  IGetPharmaceuticalForm,
  IGetPharmaceuticalFormDetails,
  IListPackagingClassification,
  IListPharmaceuticalFormDropdownResponse,
  IPackingClassificationLinkedPharmaceuticalFormListResponse,
  IPharmaceuticalFormList,
  IPharmaceuticalPrescriptionResponse,
} from '../../Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import {
  ICreatePharmaceuticalForm,
  IDeleteFarmaceuticalFormRequest,
  IEditPharmaceuticalForm,
  IPatchPharmaceuticalFormStatusRequest,
} from '../../Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class PharmaceuticalFormAPI {
  public static async listPharmaceuticalFormForDropDown(
    context: any
  ): Promise<IListPharmaceuticalFormDropdownResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/producao/v1/FormaFarmaceutica/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getPharmaceuticalFormList(
    context: QueryFunctionContext
  ): Promise<IPharmaceuticalFormList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IPharmaceuticalFormList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/FormaFarmaceutica/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async patchPharmaceuticalFormStatus(
    body: IPatchPharmaceuticalFormStatusRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/FormaFarmaceutica/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async deletePharmaceuticalFormModalCannnotDelete(
    externalIds: string[]
  ) {
    return await Http.fetcher('/pharma/producao/v1/FormaFarmaceutica', {
      method: 'DELETE',
      body: JSON.stringify({ pharmaceuticalFormExternalIds: externalIds }),
    });
  }

  public static async deletePharmaceuticalForm(
    body: IDeleteFarmaceuticalFormRequest
  ) {
    return await Http.fetcher(`/pharma/producao/v1/FormaFarmaceutica`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }

  public static async deletePharmaceuticalFormDetails(
    body: IDeleteFarmaceuticalFormRequest
  ) {
    const res = await Http.fetcher(`/pharma/producao/v1/FormaFarmaceutica`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async pharmaceuticalFormDescription(
    descricao?: string
  ): Promise<boolean> {
    if (!descricao) return false;
    return await Http.fetcher(
      '/pharma/producao/v1/FormaFarmaceutica/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ descricao }),
      }
    );
  }

  public static async createPharmaceuticalForm(
    body: ICreatePharmaceuticalForm
  ) {
    const res = await Http.fetcher(`/pharma/producao/v1/FormaFarmaceutica`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getCurrentPharmaceuticalForm(
    context: QueryFunctionContext
  ): Promise<IGetPharmaceuticalForm | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetPharmaceuticalForm>(
      `/pharma/producao/v1/FormaFarmaceutica/${context.queryKey[1]}`
    );
  }

  public static async editPharmaceuticalForm(body: IEditPharmaceuticalForm) {
    const res = await Http.fetcher('/pharma/producao/v1/FormaFarmaceutica', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getCurrentPharmaceuticalFormDetails(
    context: QueryFunctionContext
  ): Promise<IGetPharmaceuticalFormDetails> {
    return await Http.fetcher<IGetPharmaceuticalFormDetails>(
      `/pharma/producao/v1/FormaFarmaceutica/${context.queryKey[1]}`
    );
  }

  public static async getPackingClassificationLinkedPharmaceuticalFormList(
    context: QueryFunctionContext
  ): Promise<IPackingClassificationLinkedPharmaceuticalFormListResponse | null> {
    if (!context.queryKey[1]) {
      return null;
    }
    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/producao/v1/FormaFarmaceutica/ListarEmbalagensVinculadas`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getListPackagingClassification(
    context: QueryFunctionContext
  ): Promise<IListPackagingClassification | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/producao/v1/FormaFarmaceutica/ListarEmbalagensClassificacao`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async getPharmaceuticalPrescription(
    context: QueryFunctionContext
  ): Promise<IPharmaceuticalPrescriptionResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher(
      `/pharma/producao/v1/FormaFarmaceutica/${context.queryKey[1]}/Prescricao`
    );
  }

  public static async isApresentationPharmaceuticalFormNameAlreadyRegistered(
    apresentacao?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!apresentacao) return null;
    return await Http.fetcher(
      '/pharma/producao/v1/FormaFarmaceutica/ValidarApresentacao',
      {
        method: 'POST',
        body: JSON.stringify({ apresentacao }),
      }
    );
  }
}
