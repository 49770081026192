import { useState } from 'react';

export const useEditStates = () => {
  const [disabledForm, setDisabledForm] = useState(true);

  const [visibleModal, setVisibleModal] = useState(false);

  const [currentTechnicalInformationPage, setCurrentTechnicalInformationPage] =
    useState('');

  const [currentProductMessagePage, setCurrentProductMessagePage] =
    useState('product-messages');

  return {
    disabledForm,
    setDisabledForm,
    visibleModal,
    setVisibleModal,
    currentTechnicalInformationPage,
    setCurrentTechnicalInformationPage,
    currentProductMessagePage,
    setCurrentProductMessagePage,
  };
};
