import { InputWithSearch, TextInput } from '@/Components';
import { TextInputPhoneMask } from '@/Components/Inputs/TextInputPhoneMask';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { IListRecordTypeData } from '@/Data/Interfaces/response/Record/IRecordResponse';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';

export const ModalBody: FC = () => {
  const { t } = useTranslation();

  const {
    listState,
    refetchStateList,
    fetchNewStatePage,

    specialtiesData,
    refetchSearchSpecialties,
    fetchNewSpecialtiesPage,

    recordTypeList,
    fetchNewRecordPage,
    refetchRecordTypeList,
  } = useBodyCallAPI();

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <TextInput
            name="nomeCompleto"
            label={t('callCenter.createService.createClientModal.fullName')}
            required
          />
        </Col>
        <Col span={12}>
          <InputWithSearch
            name="tipoRegistroId"
            placeHolder={t('prescribers.createPage.info.registerPlaceholder')}
            label={t('prescribers.createPage.info.register')}
            required
            items={recordTypeList?.data?.map((x: IListRecordTypeData) => ({
              id: x.id,
              label: `${x.sigla} (${x.descricao})`,
            }))}
            onScrollEnd={fetchNewRecordPage}
            onSearch={(search) =>
              refetchRecordTypeList({
                search: search,
              })
            }
            withoutSearchIcon
          />
        </Col>
        <Col span={4}>
          <InputWithSearch
            name="ufRegistroExternalId"
            placeHolder="UF"
            label={t('prescribers.createPage.info.UF')}
            required
            items={listState?.data?.map((x: IGetStateResponse) => ({
              id: x.externalId,
              label: `${x.abreviacao} - ${x.descricao}`,
              content: x.descricao,
            }))}
            onScrollEnd={fetchNewStatePage}
            onSearch={(search) =>
              refetchStateList({
                search: search,
              })
            }
            withoutSearchIcon
          />
        </Col>
        <Col span={8}>
          <TextInput
            name="codigoRegistro"
            placeHolder={`${t('common.exampleAbbreviation')}: 14.975`}
            label={t('prescribers.createPage.info.registerNumber')}
            required
          />
        </Col>
        <Col span={24}>
          <InputWithSearch
            name="especialidadesIds"
            label={t('prescribers.createPage.info.specialties')}
            placeHolder={t(
              'prescribers.createPage.info.specialtiesPlaceholder'
            )}
            items={specialtiesData?.data?.map((x: any) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            multipleSelection
            typeTag="tertiary"
            onScrollEnd={fetchNewSpecialtiesPage}
            onSearch={(search) =>
              refetchSearchSpecialties({
                search: search,
              })
            }
            withoutSearchIcon
          />
        </Col>
        <Col span={10}>
          <TextInputPhoneMask
            name="identificacaoContato"
            label={t('callCenter.createService.createClientModal.cellphone')}
            placeHolder={`(DDD) + ${t(
              'callCenter.createService.createClientModal.cellphone'
            )}`}
          />
        </Col>
      </Row>
    </div>
  );
};
