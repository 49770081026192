import React from 'react';
import { Button, Form } from '@/Components';
import { useTranslation } from 'react-i18next';
import { InvoiceCreateCreateSchema } from './InvoiceCreate.schema';

import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { InfoSection } from './Sections/InfoSection';
import { ValueSection } from './Sections/ValueSection';
import { ProductSection } from './Sections/ProductSection';

import styles from './styles.module.scss';
import { history } from '@/Store';
import { ButtonDraft } from '@/Components/Button/ButtonDraft';
import { useCreateInvoice } from '@/Hooks/HooksServices/Purchase/Invoice/useCreateinvoice';
import { useFormatData } from './Hooks/useFormatData';
import { initialValue } from './util/constants';
import { useCreateInvoiceDraft } from '@/Hooks/HooksServices/Purchase/Invoice/useCreateInvoiceDraft';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_INVOICE } from '@/ControllerApiHook/UniqIds/Invoice/InvoiceHooks';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useEnableDraft } from './Hooks/useEnableDraft';

const InvoiceCreateRefactor: React.FC = () => {
  const { t } = useTranslation();
  const { createInvoice } = useCreateInvoice();
  const { createInvoiceDraft } = useCreateInvoiceDraft();
  const { formatData } = useFormatData();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enableDraft } = useEnableDraft();

  const handleSubmit = async (values: any) => {
    const valuesToPost = formatData(values);
    await createInvoice.mutateAsync(valuesToPost);
  };

  const handleSaveDraft = async (values: any) => {
    const valuesToPost = formatData(values);
    const res = await createInvoiceDraft.mutateAsync(valuesToPost);

    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
      dispatch(NotificationActions.setCurrentFormIsDirty(false));
      setTimeout(() => {
        history.push(`/order/invoice/create/${res.externalId}/draft`);
      }, 1);
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        schema={InvoiceCreateCreateSchema}
        initialValues={initialValue}
      >
        {(props) => (
          <>
            <ConnectedFocusError />
            <InfoSection />
            <ValueSection />
            <ProductSection />
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => history.push('/order/invoice')}
                />
                <Button
                  type="primary"
                  children={
                    createInvoice.isLoading
                      ? t('common.creating')
                      : t('common.create')
                  }
                  htmlType="submit"
                  loading={createInvoice.isLoading}
                />
              </div>

              <ButtonDraft
                type="secondary"
                htmlType="button"
                onClick={() => {
                  handleSaveDraft(props.values);
                }}
                disabled={!enableDraft(props.values)}
                children={
                  createInvoiceDraft.isLoading
                    ? t('common.saving')
                    : t('common.saveDraft')
                }
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default InvoiceCreateRefactor;
