import i18n from 'i18next';

export const useConfigTabs = (edit: any, setEdit: any): any => {
  const rightButtonConfig = {
    isVisible: !edit,
    tooltipPosition: 'bottomRight',
    targetOffsetTooltip: [35, -15],
    onClick: () => setEdit(true),
  };

  const dropdownItems = (openDeleteModal: any) => {
    return [
      {
        label: i18n.t('purchasing.invoice.details.exclude'),
        icon: 'trash',
        targetOffsetTooltip: [35, -15],
        onClick: openDeleteModal,
      },
    ];
  }

  const dropdownTitle = i18n.t('purchasing.invoice.details.moreActions');

  return { rightButtonConfig, dropdownItems, dropdownTitle };
};
