import { Button, Icon, Text } from '@/Components';
import { TFunction } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import {
  IFinishedProduct,
  SaleOrderItem,
} from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { NumberInput } from '@/Components/StateInputs/NumberInput';
import { Dispatch, SetStateAction } from 'react';
import { currencyFormater } from '@/Utils/Currency';
import { StatusIcon, StatusSaleOrderItem } from './CollumStatusIconUtils';
import { Checkbox } from '@/Components/StateInputs/Checkbox';
import { UseMutationResult } from '@tanstack/react-query';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { ISelectedTableItem } from '..';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { StatusSalesOrderEnum } from '../../../SideRightMenu/Components/StatusHeader/Components/ActionButtons';

import styles from './Collum.module.scss';

export const TableItemCollum = (
  t: TFunction<'translation', undefined>,
  changeAmountCallBack: _.DebouncedFunc<
    (amount: number, saleOrderItemId: number) => Promise<any>
  >,
  setEditProductItem: Dispatch<SetStateAction<SaleOrderItem | undefined>>,
  changeSelectedItem: UseMutationResult,
  setDeleteTableItemModal: Dispatch<
    SetStateAction<ISelectedTableItem | undefined>
  >,
  statusSaleOrder: number
) => [
  {
    title: <div>#</div>,
    dataIndex: 'code',
    key: 'code',
    width: '3%',
  },
  {
    width: '4%',
    render: (_x: any, row: SaleOrderItem) => {
      const statusProp = StatusIcon(row.statusItem);
      return row.statusItem > StatusSaleOrderItem.budgeted ? (
        <div
          className={styles['container-status']}
          style={{ background: statusProp.background }}
        >
          <Icon icon={statusProp.icon} size="SM" color={statusProp.iconColor} />
        </div>
      ) : (
        <Checkbox
          onChange={(x) =>
            changeSelectedItem.mutateAsync({
              pedidoVendaItemId: row.pedidoVendaItemId,
              selecionar: x.target.checked,
            })
          }
          checked={row.selecionado}
        />
      );
    },
  },
  {
    title: (
      <Text
        type="ui-tiny-semibold"
        children={t('saleOrder.editOrder.SaleOrder.table.columns.description')}
      />
    ),
    dataIndex: 'produtoAcabado',
    key: 'produtoAcabado',
    width: '38%',
    render: (x: IFinishedProduct, row: SaleOrderItem) => (
      <Divflex>
        <Icon
          icon="Box"
          color={
            row.statusItem === StatusSaleOrderItem.disapproved ||
            row.statusItem === StatusSaleOrderItem.canceled
              ? 'text-400'
              : 'text-300'
          }
          style={{ margin: 'auto 6px auto 0' }}
          size="M"
        />
        <div>
          <Text
            type="small-text"
            children={`Cód ${x.codigoProduto}`}
            color="text-300"
          />
          <Text
            type="ui-tiny-bold"
            children={x.descricao}
            color={
              row.statusItem === StatusSaleOrderItem.disapproved ||
              row.statusItem === StatusSaleOrderItem.canceled
                ? 'text-300'
                : !row.selecionado
                ? 'text-200'
                : 'text-50'
            }
            className={
              row.statusItem === StatusSaleOrderItem.disapproved ||
              row.statusItem === StatusSaleOrderItem.canceled
                ? styles['line-through']
                : ''
            }
          />
        </div>
      </Divflex>
    ),
  },
  {
    title: (
      <Text
        type="ui-tiny-semibold"
        children={t('saleOrder.editOrder.SaleOrder.table.columns.unitValue')}
      />
    ),
    dataIndex: 'valorUnitario',
    key: 'valorUnitario',
    width: '14%',
    render: (x: number, row: SaleOrderItem) => (
      <Text
        type="ui-tiny-content"
        children={currencyFormater(x, 'un')}
        className={
          row.statusItem === StatusSaleOrderItem.disapproved ||
          row.statusItem === StatusSaleOrderItem.canceled
            ? styles['line-through']
            : ''
        }
        color={
          row.statusItem === StatusSaleOrderItem.disapproved ||
          row.statusItem === StatusSaleOrderItem.canceled
            ? 'text-300'
            : 'text-50'
        }
      />
    ),
  },
  {
    title: (
      <Text
        type="ui-tiny-semibold"
        children={t('saleOrder.editOrder.SaleOrder.table.columns.unitDiscount')}
      />
    ),
    dataIndex: 'tipoDescontoId',
    key: 'tipoDescontoId',
    width: '12%',
    render: (x: number, row: SaleOrderItem) => (
      <div>
        <Text
          className={
            row.statusItem === StatusSaleOrderItem.disapproved ||
            row.statusItem === StatusSaleOrderItem.canceled
              ? styles['line-through']
              : ''
          }
          type="ui-tiny-content"
          children={
            x === DiscountTypes.currency
              ? `${currencyFormater(row.valorDescontoUnitario)} `
              : `${row.percentualDescontoUnitario}% (${currencyFormater(
                  row.valorDescontoUnitario
                )})`
          }
          color={
            row.statusItem === StatusSaleOrderItem.disapproved ||
            row.statusItem === StatusSaleOrderItem.canceled
              ? 'text-300'
              : 'text-50'
          }
          style={{ wordBreak: 'break-word' }}
        />
        {row.valorDescontoRateio > 0 && (
          <Text
            type="small-text"
            children={`+ ${currencyFormater(row.valorDescontoRateio)}`}
            color="text-300"
          />
        )}
      </div>
    ),
  },
  {
    title: (
      <Text
        type="ui-tiny-semibold"
        children={t('saleOrder.editOrder.SaleOrder.table.columns.amount')}
      />
    ),
    dataIndex: 'quantidade',
    width: '10%',
    key: 'quantidade',
    render: (x: number, row: SaleOrderItem) => (
      <PermissionWrapper
        permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
        tooltip={t('noPermission.sale.order.editOrder')}
      >
        <NumberInput
          onChange={(amount) =>
            changeAmountCallBack(amount, row.pedidoVendaItemId)
          }
          value={x}
          disabled={row.statusItem > StatusSaleOrderItem.budgeted}
        />
      </PermissionWrapper>
    ),
  },
  {
    title: (
      <Text
        type="ui-tiny-semibold"
        children={t('saleOrder.editOrder.SaleOrder.table.columns.total')}
      />
    ),
    dataIndex: 'valorTotalItem',
    width: '13%',
    key: 'valorTotalItem',
    render: (x: number, row: SaleOrderItem) => (
      <Text
        type="ui-tiny-content"
        children={currencyFormater(x)}
        color={
          row.statusItem === StatusSaleOrderItem.disapproved ||
          row.statusItem === StatusSaleOrderItem.canceled ||
          !row.selecionado
            ? 'text-300'
            : 'text-50'
        }
        className={
          row.statusItem === StatusSaleOrderItem.disapproved ||
          row.statusItem === StatusSaleOrderItem.canceled ||
          !row.selecionado
            ? styles['line-through']
            : ''
        }
      />
    ),
  },
  {
    dataIndex: 'buttons',
    key: 'buttons',
    width: '10%',
    render: (_x: any, row: SaleOrderItem) => (
      <Divflex>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Button
            type="secondary"
            leftIcon="edit"
            style={{ marginRight: '4px' }}
            size="xs"
            onClick={() => setEditProductItem(row)}
            disabled={statusSaleOrder !== StatusSalesOrderEnum.Orcado}
          />
        </PermissionWrapper>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Button
            type="secondary"
            leftIcon="trash"
            size="xs"
            onClick={() =>
              setDeleteTableItemModal({
                id: row.pedidoVendaItemId,
                description: row.produtoAcabado.descricao,
              })
            }
            disabled={statusSaleOrder !== StatusSalesOrderEnum.Orcado}
          />
        </PermissionWrapper>
      </Divflex>
    ),
  },
];
