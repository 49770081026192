import { Route, Switch } from 'react-router-dom';
import { ListInventory } from '@/Pages/Sectors/Inventory/InventoryPages/List';
import { InventoryCreate } from '@/Pages/Sectors/Inventory/InventoryPages/Create';
import { DetailsInventory } from '@/Pages/Sectors/Inventory/InventoryPages/Details';

export const InventoryPageRoutes = () => (
  <Switch>
    <Route path="/inventory/stock-taking/create" component={InventoryCreate} />
    <Route path="/inventory/stock-taking/:externalId/:tab" component={DetailsInventory} />
    <Route path="/inventory/stock-taking" component={ListInventory} />
  </Switch>
);
