import { Tooltip } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import DateUtils from '@/Utils/DateUtils';
import { IPreferences } from '@/Utils/ThemeUtils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrescriptionStates } from './Hooks/usePrescriptionDateStates';

interface IPrescriptionDate {
  datePattern?: IPreferences;
}

export const PrescriptionDate: FC<IPrescriptionDate> = ({ datePattern }) => {
  const { t } = useTranslation();

  const { dateGreaterThanCurrentDay, setDateGreaterThanCurrentDay } =
    usePrescriptionStates();

  return (
    <Tooltip
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.dateGreaterThanCurrentDay'
      )}
      showMe={dateGreaterThanCurrentDay}
    >
      <TextInputWithMask
        name="dataPrescricao"
        placeHolder={DateUtils.getDatePlaceholder(
          datePattern?.idioma,
          datePattern?.padraoData
        )}
        label={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.prescriptionDate'
        )}
        mask="dataMask"
        leftIcon="calendar"
        withoutMarginBottom
        error={dateGreaterThanCurrentDay}
        onChange={(value) => {
          const parsedDate = DateUtils.convertStringToDate(value);
          setDateGreaterThanCurrentDay(parsedDate > new Date());
        }}
      />
    </Tooltip>
  );
};
