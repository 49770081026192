import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';
import { useProductCallFunctions } from './Hooks/useProductCallFunctions';
import { useProductCallMemorizations } from './Hooks/useProductCallMemorizations';
import { useFormikContext } from 'formik';

export const Product: FC<IProduct> = ({
  index,
  setProductRowAmount,
  uuidV4,
  totalRows,
  refetchUnitsMesurements,
  setClasseProdutoId,
  initialProduct,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { productList, fetchNewProductPage, refetchSearchProductPage } =
    useProductCallAPI({ index });

  const { onChange, onSearch } = useProductCallFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    refetchSearchProductPage,
    refetchUnitsMesurements,
    setClasseProdutoId,
  });

  const { items, label, selectedItemFromForm } = useProductCallMemorizations({
    productList,
    index,
    initialProduct,
  });

  useEffect(() => {
    if (
      form.values.pedidoCompraItens[index] &&
      form.values.pedidoCompraItens[index].produtoExternalId === null
    ) {
      form.setFieldValue(
        `pedidoCompraItens.${index}.produtoExternalId`,
        undefined
      );
      form.setFieldValue(`pedidoCompraItens.${index}.precoUnitario`, undefined);
      form.setFieldValue(
        `pedidoCompraItens.${index}.unidadeMedidaId`,
        undefined
      );
      form.setFieldValue(
        `pedidoCompraItens.${index}.valorDescontoUnitario`,
        undefined
      );
      form.setFieldValue(`pedidoCompraItens.${index}.quantidade`, undefined);
    }
  }, [form.values.pedidoCompraItens]);

  return (
    <InputWithSearch
      name={`pedidoCompraItens.${index}.produtoExternalId`}
      items={items}
      onChange={(_, item) => onChange(item)}
      selectedItemFromForm={selectedItemFromForm}
      label={label}
      required
      placeHolder={t(
        'purchasing.purchaseOrder.create.productsCardPlaceHolders.product'
      )}
      onScrollEnd={fetchNewProductPage}
      withoutMarginBottom
      onSearch={onSearch}
    />
  );
};
