import { Table } from 'antd';
import { FC, useRef } from 'react';
import { TableItemCollum } from './Collum';
import {
  IGetSaleOrderResponse,
  SaleOrderItem,
} from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useTranslation } from 'react-i18next';
import { EditItemModalSaleOrder } from './Edit';
import { DeleteTableItemModal } from './Delete';
import { WithoutProducts } from '../WithoutProducts';
import { useTableItemStates } from './Hooks/useTableItemStates';
import { useTableItemCallAPI } from './Hooks/useTableItemCallAPI';
import { useTableItemMemorizations } from './Hooks/useTableItemMemorizations';
import { useTableItemFunctions } from './Hooks/useTableItemFunctions';

import './TableItem.override.scss';

interface ITableItem {
  items: SaleOrderItem[];
  saleOrder: IGetSaleOrderResponse;
}

export interface ISelectedTableItem {
  id: number;
  description: string;
}

export const TableItem: FC<ITableItem> = ({ items, saleOrder }) => {
  const tableRef = useRef(null);

  const { t } = useTranslation();

  const {
    contentItemHeight,
    setContentItemHeight,
    editProductItem,
    setEditProductItem,
    scrollVisible,
    setScrollVisible,
    selectedTableItem,
    setSelectedTableItem,
  } = useTableItemStates();

  const { changeVisibleScroll } = useTableItemFunctions({
    setScrollVisible,
    contentItemHeight,
    items,
    setContentItemHeight,
    tableRef,
  });

  const { changeAmount, changeSelectedItem, removeItem } = useTableItemCallAPI({
    contentItemHeight,
    changeVisibleScroll,
    setSelectedTableItem,
  });

  const { changeAmountCallBack, removeItemCallBack, statusSaleOrder } =
    useTableItemMemorizations({ changeAmount, removeItem, saleOrder });

  return (
    <>
      <Table
        ref={tableRef}
        id="table-item"
        style={{
          ...(contentItemHeight && {
            height:
              saleOrder?.pedidoVendaItens.length <= 0
                ? 16
                : contentItemHeight - 52,
          }),
        }}
        columns={TableItemCollum(
          t,
          changeAmountCallBack,
          setEditProductItem,
          changeSelectedItem,
          setSelectedTableItem,
          statusSaleOrder
        )}
        dataSource={items.map((x, index) => ({
          ...x,
          code: index + 1,
          key: `table_clients_${x.pedidoVendaItemId}`,
        }))}
        pagination={false}
        scroll={{
          ...(scrollVisible && {
            y: contentItemHeight && contentItemHeight - 46,
          }),
        }}
        loading={!items}
        className="table-sale-order"
      />
      {saleOrder?.pedidoVendaItens.length <= 0 && <WithoutProducts />}
      <EditItemModalSaleOrder
        item={editProductItem}
        onClose={() => setEditProductItem(undefined)}
      />
      {selectedTableItem && (
        <DeleteTableItemModal
          changeVisibleModal={setSelectedTableItem}
          visibleModal={selectedTableItem}
          onOkClick={() =>
            selectedTableItem !== undefined &&
            removeItemCallBack(selectedTableItem?.id)
          }
          description={selectedTableItem?.description}
          okButtonName={
            removeItem.isLoading
              ? t('common.removeLoading')
              : t('common.remove')
          }
          okButtonLoading={removeItem.isLoading}
        />
      )}
    </>
  );
};
