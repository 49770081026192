import { useState } from 'react';

export const useLinkedProductMessageModalStates = () => {
  const [
    visibleLinkedProductMessageModalSearch,
    setVisibleLinkedProductMessageModalSearch,
  ] = useState<boolean>(true);

  const [typeMessageSelected, setTypeMessageSelected] = useState<number>(1);

  const [productMessageId, setProductMessageId] = useState<string>();

  return {
    visibleLinkedProductMessageModalSearch,
    setVisibleLinkedProductMessageModalSearch,
    typeMessageSelected,
    setTypeMessageSelected,
    setProductMessageId,
    productMessageId,
  };
};
