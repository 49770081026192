import Select from 'antd/lib/select';
import { Field } from 'formik';
import React from 'react';
import { Icon } from '../..';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { Tag } from '../../Tag';
import { IField } from '../interfaces/IField';
import { Text } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { v4 as uuidV4 } from 'uuid';
import { OptionsInputWithSearch } from './Components/Options';
import { FilterSearch } from './Components/FilterSearch';
import { InputWitchSearchHook } from './Hooks';
import { IInputWithSearch } from './Interface';
import { InputWithSearchLabel } from './Components/Label';
import _ from 'lodash';
import { InputWitchSeatchPopover } from './Components/Popover';

import styles from './TextInputWithSearch.module.scss';
import '@static/scss/SelectStatic/Select.override.scss';

export const InputWithSearch: React.FC<IInputWithSearch> = ({
  multipleSelection,
  typeTag,
  hideSelectedItem,
  items,
  name,
  selectedOptionRender,
  emptyState,

  currentItemsSelected,

  onChange,
  onClear,
  onChangeInputText,
  onInput,
  onSearch,
  label,
  className,
  required,
  disabled,
  error,
  placeHolder,
  rightIcon,

  selectedItemFromForm,

  success,
  withoutMarginBottom,
  onScrollEnd,
  isLoading,
  withTooltip,
  withStatusIcon,

  withoutSearchIcon,

  getPopupContainerId,
  staticOptions,
  actionOnFocus,
  onClick,

  placeholderWithAvatar,
  filterSearch,
  withoutLeftIconSelected,
  listHeight,
  dataTestId,
  inputDataTestId,

  dropdownMinWidth,

  focus,

  displayFormattedValue,
  popover,
}) => {
  const hooks = InputWitchSearchHook({
    name,
    actionOnFocus,
    filterSearchItems: filterSearch?.items,
    isLoading,
    itemsArray: items,
    onChange,
    onScrollEnd,
    onSearch,
    currentItemsSelected,
    selectedItemFromForm,
  });

  const { t } = useTranslation();

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          return (
            <>
              {label && (
                <InputWithSearchLabel
                  label={label}
                  props={props}
                  required={required}
                  withStatusIcon={withStatusIcon}
                  withTooltip={withTooltip}
                />
              )}
              <div
                id={hooks.randomId}
                onClick={() => onClick?.()}
                data-testid={dataTestId}
                className={`${styles['input-container']} ${classNames} ${
                  rightIcon || success || hasError
                    ? styles['with-right-icon']
                    : null
                } 
                ${hasError ? styles['error'] : null} ${
                  success ? styles['success'] : null
                }`}
              >
                <InputWitchSeatchPopover
                  visible={
                    hooks.popoverIsVisible &&
                    !hooks.isOpen &&
                    !!props.field.value &&
                    !!popover?.content
                  }
                  onOpenChange={hooks.setPopoverIsVisible}
                  popover={popover}
                >
                  <Select
                    autoFocus={focus}
                    id={name}
                    data-testid={inputDataTestId}
                    listHeight={listHeight}
                    onPopupScroll={hooks.onScroll}
                    mode={multipleSelection ? 'multiple' : undefined}
                    onChange={(x) => hooks.onChangeFunc(x, props)}
                    value={props.field.value}
                    dropdownStyle={{
                      minWidth: dropdownMinWidth,
                    }}
                    className={`${styles['TextInput']} ${
                      withoutSearchIcon
                        ? styles['TextInput-without-searchIcon']
                        : ''
                    } ${
                      withoutLeftIconSelected
                        ? 'selected-input-with-search'
                        : ''
                    }`}
                    notFoundContent={
                      emptyState ? (
                        <div
                          data-testid={`${inputDataTestId}-empty-state`}
                          onClick={() => {
                            emptyState.onClick(hooks.currentSearch);
                            hooks.setCurrentSearch('');
                          }}
                          className={styles['empty-state']}
                        >
                          {emptyState.prefix}
                          {hooks.currentSearch}
                          {emptyState.suffix}
                        </div>
                      ) : undefined
                    }
                    dropdownRender={
                      staticOptions
                        ? (menu) => (
                            <>
                              {menu}
                              <div className={styles['divider']} />
                              {staticOptions.map((option) => (
                                <div
                                  key={uuidV4()}
                                  data-testid={`${inputDataTestId}-static-${option.name}`}
                                  onClick={option.onClick}
                                  className={styles['static-option']}
                                >
                                  <Icon
                                    icon={option.icon}
                                    size="XXL"
                                    color="background-08"
                                  />
                                  <span className={styles['text']}>
                                    {option.name}
                                  </span>
                                </div>
                              ))}
                            </>
                          )
                        : undefined
                    }
                    allowClear
                    tagRender={(props: any) => {
                      return hideSelectedItem ||
                        _.isNil(props.label) ||
                        _.isNil(props.value) ? (
                        <></>
                      ) : (
                        <Tag
                          dataTestId={`${inputDataTestId}-tag-${props.value}`}
                          className={styles['tag']}
                          type={typeTag ? typeTag : 'secondary'}
                          closable
                          onClose={() => {
                            if (!disabled) {
                              hooks.onCloseTags(props.value);
                              props.onClose();
                            }
                          }}
                          children={props?.label?.props?.item?.label}
                        />
                      );
                    }}
                    disabled={disabled || props.form.isSubmitting}
                    placeholder={
                      placeholderWithAvatar ? (
                        <Divflex className={styles['container-placeholder']}>
                          <div />
                          <Text
                            type="ui-tiny-content"
                            color="text-400"
                            children={placeHolder}
                          />
                        </Divflex>
                      ) : (
                        placeHolder
                      )
                    }
                    showSearch
                    onInputKeyDown={(inputProps) =>
                      onInput && onInput(inputProps)
                    }
                    optionFilterProp="id"
                    suffixIcon={
                      !filterSearch && (
                        <Icon
                          className={styles['chevron-up']}
                          icon="chevron-down"
                        />
                      )
                    }
                    getPopupContainer={() =>
                      document.getElementById(
                        getPopupContainerId ?? hooks.randomId
                      ) as any
                    }
                    onFocus={() => hooks.setFocusState()}
                    onBlur={() => {
                      hooks.setFocusState();
                      hooks.setCurrentSearch('');
                      hooks.onSearchFunc('');
                    }}
                    onSelect={() => {
                      hooks.setCurrentSearch('');
                      onSearch && onSearch('');
                    }}
                    onClear={() => {
                      hooks.setCurrentSearch('');
                      onSearch && onSearch('');
                      onClear && onClear();
                    }}
                    onDeselect={() => hooks.setCurrentSearch('')}
                    onDropdownVisibleChange={(x) => {
                      hooks.setCurrentSearch('');
                      hooks.setIsOpen(x);
                      hooks.setPopoverIsVisible(x);
                    }}
                    searchValue={
                      displayFormattedValue
                        ? displayFormattedValue
                        : hooks.currentSearch
                    }
                    filterOption={false}
                    onSearch={(x) => {
                      hooks.onSearchFunc(x);
                      hooks.setCurrentSearch(x);
                      onChangeInputText && onChangeInputText(x);
                    }}
                    optionLabelProp={
                      items?.some((x) => x.content)
                        ? 'content'
                        : selectedOptionRender
                    }
                    options={OptionsInputWithSearch({
                      items: hooks.items,
                      currentSearch: hooks.currentSearch,
                      isLoading,
                      inputDataTestId,
                      name,
                      isOpen: hooks.isOpen,
                    })}
                  />
                </InputWitchSeatchPopover>
                {hooks.isOpen &&
                  !isLoading &&
                  hooks.items &&
                  hooks.items.length < 1 &&
                  !hooks.items[0]?.loading &&
                  !emptyState && (
                    <div className={styles['empyt']}>
                      <Text
                        type="ui-tiny-content"
                        color="text-300"
                        children={t('dropDown.empty')}
                      />
                    </div>
                  )}
                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}
                {rightIcon?.icon && !success ? (
                  <Icon
                    className={styles['Icon']}
                    icon={rightIcon.icon}
                    size="M"
                    color="text-50"
                  />
                ) : null}
                {hooks.filterSearchItem && (
                  <FilterSearch
                    filterSearchItem={hooks.filterSearchItem}
                    changeFilterSearchItemSelected={(search) => {
                      const auxFilterSearchItem = search?.filter(
                        (x) => x.checked
                      );
                      hooks.setFilterSearchItem(search);
                      hooks.onChangeFilterFunc(
                        auxFilterSearchItem.map((x) => x.id)
                      );
                    }}
                    title={filterSearch?.title}
                  />
                )}
                {!withoutSearchIcon && (
                  <Icon
                    className={styles['search']}
                    icon="search"
                    size="M"
                    color={hasError ? 'status-danger-base' : 'text-400'}
                  />
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
