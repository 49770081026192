export const breadCrumbEN = {
  home: 'Home',

  bairros: 'Neighborhoods',
  createBairros: 'Create',

  preference: 'Preference',
  accessibility: 'Accessibility',
  placeAndTime: 'Place and Time',
  theme: 'Theme',

  accountManager: 'Account manager',
  accountDetails: 'Account details',
  accountSecurity: 'Account security',
  notification: 'Notifications',

  systemConfig: 'System Settings',
  personalization: 'Personalization',
  currency: 'Currency',

  adminBootis: 'Admin Bootis',
  adminBootisRoute: {
    company: 'Company',
    companyPage: {
      create: 'Company Registration',
      details: 'Details',
    },

    conglomerate: 'Grupos de Empresas',
    conglomeratePage: {
      details: 'Details',
      company: 'Companys',
    },

    status: {
      ative: 'Active',
      inative: 'Inative',
      changeStatus: 'Change status',
    },
  },

  admin: 'Administrative',
  adminRoute: {
    user: 'Users',
    userPage: {
      create: 'User registration',
      details: 'Details',
      permissions: 'Permissions',
    },

    company: 'Company',
    companyPage: {
      details: 'Details',
    },

    groupPage: {
      list: 'User Groups',
      create: 'User Group Registration',
      edit: {
        tabs: {
          detail: 'Details',
          users: 'Users',
          permission: 'Permissions',
        },
      },
    },
  },

  products: {
    list: 'Products',
    create: 'Product registration',
    group: {
      list: 'Product Groups',
    },
    subGroup: {
      list: 'Product Subgroups',
    },
    details: 'Details',
    subGroups: 'Subgroups',
    products: 'Products',

    financial: 'Financial',

    inventory: 'Inventory',

    incompatible: 'Incompatible Products',
    registerIncompatibleProduct: 'Incompatible Product Registration',

    synonym: 'Products',
    registerSynonym: 'Register Synonyms',

    productMessage: 'Product Message',

    diluted: 'Diluted Products',
    dilutedRegister: 'Diluted Product Registration',
    associated: 'Associated Products',
    associatedRegister: 'Associated Product Registration',
  },

  supply: 'Supply',
  supplyPlaces: {
    title: 'Stock Locations',
    create: 'Stock Location Registration',
    details: 'Stock Location',
    inventory: 'Stock',
    inventoryMovements: 'Stock Movements',

    detail: {
      title: 'Detail',
    },
  },

  balanceAdjustments: {
    list: 'Balance Adjustments',
    create: 'Balance Adjustment Registration',
  },

  supplyRoutes: {
    list: 'Supply Places',
    lossesList: 'Supply Losses',
    lots: 'Lots',
    transfers: 'Transfers',
    inventoryList: 'Inventory',
    stockTransfers: 'Stock transfers',
  },

  inventory: {
    create: 'Inventory Register',
    details: 'Details',
    inventory: 'Inventory',
  },

  losses: {
    create: 'Inventory Loss Record',
    losses: 'Loss',
    detail: {
      title: 'Lot',
      details: 'Details',
      stockLocations: 'Stock Locations',
    },
  },

  lots: {
    create: 'Batch Registration',
    detail: {
      title: 'Lot',
      details: 'Details',
      stockLocations: 'Stock Locations',
    },
  },

  messageProduct: {
    productMensage: 'Product Mensage',
    productMensageCreate: 'Create Product Mensage',
    productMessageDetails: 'Details',
  },

  sales: 'Sales',
  salesRoute: {
    service: 'Services',
    serviceOrder: {
      service: 'Service',

      order: 'Order',
    },

    saleOrder: 'Sale Order',

    prescriber: 'Prescribers',
    createPrescriber: 'Create Prescriber',

    specialties: 'Specialties of Prescribers',

    client: 'Clients',
    createclient: 'Create Client',
  },

  transfers: {
    detail: {
      title: 'Stock Transfer',
    },
    create: {
      breadCrumb: 'Inventory Transfer Registration',
    },
  },

  purchase: 'purchase',
  purchasing: {
    purchaseOrder: {
      purchaseList: 'Purchase Orders',
      create: 'Purchase Order Registration',
      detail: 'Purchase order',
      title: 'Purchasing',
    },

    provider: {
      title: 'Provider',

      create: {
        title: 'Provider Creation',
      },
    },

    invoice: {
      title: 'Invoices',
      invoice: 'Invoice',
      edit: 'Edit',

      create: {
        title: 'Invoice Creation',
      },

      registerLots: {
        title: 'Register Lots | Invoice:',
        breadCrumb: 'Register Lots',
      },
    },
  },

  production: {
    production: 'Production',
    packingClassification: {
      details: 'Details',
      productsLinked: 'Products Linked',
      packingClassificationList: 'Packing Classification',
      packingClassificationCreate: 'Packing Classification Registration',
      packingClassificationDetail: 'Packing Classification',
    },

    capsuleColors: {
      details: 'Details',
      capsuleColorsListTitle: 'Capsule Colors',
      capsuleColorsCreateTitle: 'Capsule Color Registration',
      capsuleColorsDetailTitle: 'Capsule Colors',
    },

    posology: {
      details: 'Details',
      posologyListTitle: 'Posology',
      posologyCreateTitle: 'Posology Registration',
      posologyDetailTitle: 'Posology',
    },

    pharmaceuticalForm: {
      details: 'Details',
      pharmaceuticalFormListTitle: 'Pharmaceutical Form',
      pharmaceuticalFormCreateTitle: 'Pharmaceutical Form Registration',
    },

    laboratory: {
      laboratoryListTitle: 'Laboratories',
      laboratoryCreateTitle: 'Laboratóries Registration',
    },

    standardFormula: {
      standardFormulaListTitle: 'Standard Formula',
      standardFormulaCreateTitle: 'Standard Formula Registration',
      standardFormulaDetailTitle: 'Standard Formula',
    },
  },
};

export const breadCrumbPT = {
  home: 'Início',

  bairros: 'Bairros',
  createBairros: 'Criar',

  preference: 'Preferências',
  accessibility: 'Acessibilidade',
  placeAndTime: 'Local e Hora',
  theme: 'Tema',

  accountManager: 'Gerenciar conta',
  accountDetails: 'Detalhes da conta',
  accountSecurity: 'Segurança da conta',
  notification: 'Notificações',

  systemConfig: 'Configurações do Sistema',
  personalization: 'Personalização',
  currency: 'Moeda',

  adminBootis: 'Admin Bootis',
  adminBootisRoute: {
    company: 'Empresas',
    companyPage: {
      create: 'Cadastro de Empresa',
      details: 'Detalhes',
    },

    conglomerate: 'Grupos de Empresas',
    conglomeratePage: {
      details: 'Detalhes',
      company: 'Empresas',
    },

    status: {
      ative: 'Ativo',
      inative: 'Inativo',
      changeStatus: 'Alterar status',
    },
  },
  admin: 'Administrativo',
  adminRoute: {
    user: 'Usuários',
    userPage: {
      create: 'Cadastro de Usuário',
      details: 'Detalhes',
      permissions: 'Permissões',
    },

    company: 'Empresas',
    companyPage: {
      details: 'Detalhes',
    },

    groupPage: {
      list: 'Grupos de Usuários',
      create: 'Cadastro de Grupo de Usuários',
      edit: {
        tabs: {
          detail: 'Detalhes',
          users: 'Usuários',
          permission: 'Permissões',
        },
      },
    },
  },

  products: {
    list: 'Produtos',
    create: 'Cadastro de Produto',
    group: {
      list: 'Grupos de Produtos',
    },
    subGroup: {
      list: 'Subgrupos de Produtos',
    },
    details: 'Detalhes',
    subGroups: 'Subgrupos',
    products: 'Produtos',

    financial: 'Financeiro',

    inventory: 'Estoque',
    incompatible: 'Produtos Incompatíveis',
    registerIncompatibleProduct: 'Cadastro de Produto Incompatível',
    diluted: 'Produtos Diluídos',
    dilutedRegister: 'Cadastro de Produto Diluído',
    associated: 'Produtos Associados',
    associatedRegister: 'Cadastro de Produto Associado',

    synonym: 'Sinônimos',
    registerSynonym: 'Cadastro de Sinônimos',

    productMessage: 'Mensagem do Produto',
  },

  supply: 'Estoque',
  supplyPlaces: {
    title: 'Locais de Estoque',
    create: 'Cadastro de Local de Estoque',
    details: 'Local de Estoque',
    inventory: 'Estoque',
    inventoryMovements: 'Movimentações de Estoque',

    detail: {
      title: 'Detalhes',
    },
  },

  balanceAdjustments: {
    list: 'Ajustes de Saldo',
    create: 'Cadastro de Ajuste de Saldo',
  },

  supplyRoutes: {
    list: 'Locais de Estoque',
    lossesList: 'Perdas de Estoque',
    lots: 'Lotes',
    transfers: 'Transferências',
    inventoryList: 'Inventário',
    stockTransfers: 'Transferências de Estoque',
  },

  inventory: {
    create: 'Cadastro de Inventário',
    details: 'Detalhes',
    inventory: 'Inventário',
  },

  losses: {
    create: 'Cadastro de Perda de Estoque',
    losses: 'Perdas',
    detail: {
      title: 'Lote',
      details: 'Detalhes',
      stockLocations: 'Locais de estoque',
    },
  },

  lots: {
    create: 'Cadastro de lote',
    detail: {
      title: 'Lote',
      details: 'Detalhes',
      stockLocations: 'Locais de estoque',
    },
  },

  messageProduct: {
    productMensage: 'Mensagem do Produto',
    productMensageCreate: 'Cadastro de Mensagem do Produto',
    productMessageDetails: 'Detalhes',
  },

  sales: 'Vendas',
  salesRoute: {
    service: 'Atendimentos',
    serviceOrder: {
      service: 'Atendimento',

      order: 'Pedido',
    },

    saleOrder: 'Pedidos de Venda',

    prescriber: 'Prescritores',
    createPrescriber: 'Cadastro de Prescritor',

    specialties: 'Especialidades dos Prescritores',

    client: 'Clientes',
    createclient: 'Cadastro de Cliente',
  },

  transfers: {
    detail: {
      title: 'Transferência de estoque',
    },
    create: {
      breadCrumb: 'Cadastro de Transferência de Estoque',
    },
  },

  purchase: 'Compras',
  purchasing: {
    purchaseOrder: {
      purchaseList: 'Pedidos de Compra',
      create: 'Cadastro de Pedido de Compra',
      detail: 'Pedido de Compra',
      title: 'Pedido de Compra',
    },

    provider: {
      title: 'Fornecedores',

      create: {
        title: 'Cadastro de Fornecedor',
      },
    },

    invoice: {
      title: 'Notas Fiscais',
      invoice: 'Nota Fiscal',
      edit: 'Editar',

      create: {
        title: 'Cadastro de Nota Fiscal',
      },

      registerLots: {
        title: 'Lançamento de Lotes | NF:',
        breadCrumb: 'Lançamento de Lotes',
      },
    },
  },

  production: {
    production: 'Produção',
    packingClassification: {
      details: 'Detalhes',
      productsLinked: 'Produtos Vinculados',
      packingClassificationList: 'Classificação de Embalagem',
      packingClassificationCreate: 'Cadastro de Classificação de Embalagem',
      packingClassificationDetail: 'Classificação de Embalagem',
    },

    capsuleColors: {
      details: 'Detalhes',
      capsuleColorsListTitle: 'Cores de Cápsulas',
      capsuleColorsCreateTitle: 'Cadastro de Cores de Cápsulas',
      capsuleColorsDetailTitle: 'Cores de Cápsulas',
    },

    posology: {
      details: 'Detalhes',
      posologyListTitle: 'Posologia',
      posologyCreateTitle: 'Cadastro de Posologia',
      posologyDetailTitle: 'Posologia',
    },

    pharmaceuticalForm: {
      details: 'Detalhes',
      pharmaceuticalFormListTitle: 'Forma Farmacêutica',
      pharmaceuticalFormCreateTitle: 'Cadastro de Forma Famacêutica',
    },

    laboratory: {
      laboratoryListTitle: 'Laboratórios',
      laboratoryCreateTitle: 'Cadastro de Laboratório',
    },

    standardFormula: {
      standardFormulaListTitle: 'Fórmula Padrão',
      standardFormulaCreateTitle: 'Cadastro de Fórmula Padrão',
      standardFormulaDetailTitle: 'Fórmula Padrão',
    },
  },
};
