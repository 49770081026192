import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';

export const useInventoryListMemorizations = () => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () => history.push('/inventory/stock-taking/create'),
      label: 'supply.inventory.list.newButton',
      permission: {
        permission: PermissionEnum.Estoque_Inventario_Cadastrar,
        tooltip: 'noPermission.supply.inventory.register',
      },
    },
    searchPlaceholder: t('purchasing.invoice.list.searchPlaceholder'),
  };

  const rowOptions = {
    permission: {
      permission: PermissionEnum.Estoque_Inventario_VerDetalhes,
      tooltip: 'noPermission.supply.inventory.details',
    },
    onRowDoubleClick: (x: any) => {
      history.push(`/inventory/stock-taking/${x.externalId}/details`);
    },
  };

  return { headerOptions, rowOptions };
};
