import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { FETCH_LIST_RECORD_TYPE } from '@/ControllerApiHook/UniqIds/People/RecordKeys';
import { FETCH_LIST_SPECIALTIES_FOR_DROPDOWN } from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { RecordAPI } from '@/Data/API/People/RecordApi';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';

export const useBodyCallAPI = () => {
  const {
    data: listState,
    refetch: refetchStateList,
    fetchNewPage: fetchNewStatePage,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: recordTypeList,
    refetch: refetchRecordTypeList,
    fetchNewPage: fetchNewRecordPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_RECORD_TYPE,
    entityApi: RecordAPI.listRecordType,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const {
    data: specialtiesData,
    refetch: refetchSearchSpecialties,
    fetchNewPage: fetchNewSpecialtiesPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES_FOR_DROPDOWN,
    entityApi: SpecialtiesAPI.listSpecialtiesForDropdown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  return {
    listState,
    refetchStateList,
    fetchNewStatePage,

    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,

    specialtiesData,
    refetchSearchSpecialties,
    fetchNewSpecialtiesPage,
  };
};
