export const useConfigColumnsSpacing = () => {
  const configColumnsSpacing = (
    canShowDatesFields: boolean,
    showTechnicalInformation: boolean
  ) => {
    if (canShowDatesFields && showTechnicalInformation) {
      return {
        firstColumn: 7,
        restColumns: 4,
      };
    }

    if (!canShowDatesFields && showTechnicalInformation) {
      return {
        firstColumn: 15,
        restColumns: 4,
      };
    }

    return {
      firstColumn: 10,
      restColumns: 3,
    };
  };

  return { configColumnsSpacing };
};
