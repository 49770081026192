import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { debounce } from 'lodash';
import { 
  USER_GROUP_NAME_ALREADY_REGISTRED_TYPE 
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import { IUseCheckNameDuplications } from './interfaces';

export const useCheckNameDuplicationAPI = ({
  initialUserGroup
}: IUseCheckNameDuplications) => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: USER_GROUP_NAME_ALREADY_REGISTRED_TYPE,
    entityApi: UserGroupAPI.isUserGroupNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = 
          initialUserGroup !== value?.trim() 
          ? (await mutateAsync(value)) as boolean
          : true;
          
        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return { checkNameDuplications: checkNameDuplicationsDebounced };
};
