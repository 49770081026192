import { TextInput } from '@/Components';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ITransfersRows {
  index: number;
}

export const TransfersRows: FC<ITransfersRows> = ({ index }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <TextInput
          name={`produtoResponse.${index}`}
          label={
            index === 0 ? t('supply.transfers.detailsForm.product') : t('')
          }
          disabled
        />
      </Col>
      <Col span={7}>
        <TextInputWithMask
          name={`loteResponse.${index}`}
          label={index === 0 ? t('supply.transfers.detailsForm.lot') : t('')}
          disabled
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name={`movimentoEstoqueOrigem.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.originBalance')
              : t('')
          }
          dropdownRight={{
            name: `unidadeMedidaId.${index}`,
            options: [],
          }}
          withTooltip={
            index === 0
              ? {
                  icon: 'exclamation-upside-down',
                  title: t('supply.transfers.toolTips.originBalance'),
                }
              : undefined
          }
          disabled
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name={`quantidadeTransferida.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.transferAmount')
              : t('')
          }
          dropdownRight={{
            name: `unidadeMedidaId.${index}`,
            options: [],
          }}
          disabled
        />
      </Col>
      <Col span={3}>
        <WeightInput
          name={`movimentoEstoqueDestino.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.destinationBalance')
              : t('')
          }
          dropdownRight={{
            name: `unidadeMedidaId.${index}`,
            options: [],
          }}
          withTooltip={
            index === 0
              ? {
                  icon: 'exclamation-upside-down',
                  title: t('supply.transfers.toolTips.destinationBalance'),
                }
              : undefined
          }
          disabled
        />
      </Col>
    </Row>
  );
};
