import { FC } from 'react';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { TopButtons } from './TopButtons';
import { TableItem } from './TableItem';

import styles from './BodyOrder.module.scss';

interface IBodyOrderContent {
  saleOrder: IGetSaleOrderResponse;
}

export const BodyOrderContent: FC<IBodyOrderContent> = ({ saleOrder }) => {
  return (
    <div className={styles['container']}>
      <div>
        <TopButtons
          saleOrderExternalId={saleOrder?.externalId}
          customerDiscount={saleOrder.cliente.descontoProdutosAcabados}
          client={{
            externalId: saleOrder.clienteExternalId,
            name: saleOrder.cliente.nome,
          }}
          saleOrder={saleOrder}
        />
        <TableItem items={saleOrder.pedidoVendaItens} saleOrder={saleOrder} />
      </div>
    </div>
  );
};
