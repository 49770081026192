import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useInternalDilutionsFunctions } from './Hooks/useInternalDilutionFunctions';

export const InternalDilution: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useInternalDilutionsFunctions();

  return (
    <Col span={6}>
      <PercentageInput
        name="loteInformacaoTecnica.diluicaoInterna"
        label={[
          {
            children: t('supply.lot.create.internalDilution'),
          },
        ]}
        placeHolder={t('supply.lot.create.internalDilution')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.internalDilution'),
        }}
        onChange={(x) => onChange(x)}
        withoutMarginBottom
      />
    </Col>
  );
};
