import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import styles from '../laboratoryName.module.scss';

export const LaboratoryName = () => {
  const { t } = useTranslation();

  return (
    <TextInput
      className={styles['text-input']}
      name="nomeLaboratorio"
      placeHolder={t('production.laboratory.create.namePlaceholder')}
      required
      label={t('production.laboratory.create.name')}
      dataTestId="laboratory-description"
    />
  );
};
