import { BalanceAdjustmentsRoutes } from './BalanceAdjustments';
import { InventoryPageRoutes } from './Inventory';
import { LossesRoutes } from './Losses';
import { LotRoutes } from './Lot';
import { ProductMessageRoutes } from './ProductMessage';
import { GroupRoutes } from './Products/Group';
import { ProductRoutes } from './Products/Product';
import { SubGroupRoutes } from './Products/SubGroup';
import { SupplyPlacesRoutes } from './SupplyPlaces';
import { TransfersRoutes } from './Transfers';

export const InventoryRoutes = () => (
  <>
    <ProductRoutes />
    <GroupRoutes />
    <SubGroupRoutes />
    <LotRoutes />
    <SupplyPlacesRoutes />
    <TransfersRoutes />
    <LossesRoutes />
    <BalanceAdjustmentsRoutes />
    <ProductMessageRoutes />
    <InventoryPageRoutes />
  </>
);
