import { FC } from 'react';
import { AddProductModal } from '../../TopButtons/Modals/AddProductModal';
import { SaleOrderItem } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useEditStates } from './Hooks/useEditStates';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';

interface IEditItemModalSaleOrder {
  item?: SaleOrderItem;
  onClose: () => void;
}

export const EditItemModalSaleOrder: FC<IEditItemModalSaleOrder> = ({
  item,
  onClose,
}) => {
  const { productModalOpen, setProductModalOpen } = useEditStates();

  const { initialValues } = useEditMemorizations({ item });

  const { changeVisible } = useEditFunctions({
    onClose,
    setProductModalOpen,
    item,
  });

  return (
    <AddProductModal
      changeVisibleModal={changeVisible}
      visibleModal={productModalOpen}
      initialValues={initialValues}
      editModal
    />
  );
};
