import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { useTranslation } from 'react-i18next';
import { HistoricModalBody } from './Body';

interface IHistoricModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrderExternalId?: string;
}

export const HistoricModal: FC<IHistoricModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrderExternalId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('saleOrder.editOrder.topButtons.historic')}
      body={<HistoricModalBody saleOrderExternalId={saleOrderExternalId} />}
      visible={visibleModal}
      onCancelClick={() => changeVisibleModal(false)}
      cancelButtonName={t('common.close')}
      onClose={() => changeVisibleModal(false)}
      withoutOkButton
      maxWidth={400}
      htmlType="submit"
    />
  );
};
