import { AxiosResponse } from 'axios';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IQueryParams } from '../../../Components/Table';
import {
  IProductListResponse,
  IProductGroupListResponse,
  IProductSubGroupListResponse,
  IProductGroupResponse,
  IProductSubGroupResponse,
  IUnitMeasureResponse,
  IProductResponse,
  ISubGroupsOfGroupListResponse,
  IProductsOfGroupListResponse,
  ISubGroupsByGroupListResponse,
  IFinishedProductListResponse,
  IProductPrescriptionListResponse,
  IProductLinkedMessageListResponse,
  IDilutedProductResponse,
} from '../../Interfaces/response/Product/IProductRespose';
import {
  IChangeProductStatusRequest,
  ICreateProductGroupRequest,
  ICreateSubGroupProductRequest,
  IDeleteProductGroupRequest,
  IEditProductGroupRequest,
  IDeleteProductSubGroupRequest,
  IEditProductSubGroupRequest,
  IEditProductRequest,
  IDeleteProductRequest,
  IEditFinancialProductRequest,
  IUnitMeasureClassProductRequest,
  IDeleteProductIncompatibleRequest,
  IDeleteProductLinkedMessageRequest,
  IDeleteSynonymProductRequest,
  IDeleteDilutedProductRequest,
  IAssociatedProductRequest,
  IDeleteAssociatedProductRequest,
} from '../../Interfaces/request/Product/IProductRequest';
import { ICreateProductRequest } from '../../Interfaces/request/Product/ICreateProductRequest';
import { QueryFunctionContext } from '@tanstack/query-core';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';
import { ICreateIncompatibleProductRequest } from '@/Data/Interfaces/request/Product/ICreateIncompatibleProductRequest';
import { IIncompatibleProductRequest } from '@/Data/Interfaces/request/Product/IIncompatibleProductRequest';
import { ICreateSynonymProductRequest } from '@/Data/Interfaces/request/Product/ICreateSynonymProductRequest';
import {
  ICreateProductLinkedMessage,
  ICreateProductMessageLinked,
} from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import { ISynonymRequest } from '@/Data/Interfaces/request/Product/ISynonymRequest';
import { ICreateDilutedProductRequest } from '@/Data/Interfaces/request/Product/ICreateDilutedProductRequest';
import { IAssociatedProductResponse } from '@/Data/Interfaces/response/Product/IAssociatedProductResponse';
import { IListPharmaceuticalFormDropdownSimpleResponse } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

export class ProductAPI {
  public static async getProduct(
    context: QueryFunctionContext
  ): Promise<IProductResponse | null> {
    if (!context.queryKey[1]) {
      return null;
    }
    return await Http.fetcher<IProductResponse>(
      `/pharma/estoque/v1/Produto/${context.queryKey[1]}`
    );
  }

  public static async productList(
    context: QueryFunctionContext
  ): Promise<IProductListResponse> {
    return await Http.fetcher<IProductListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
  public static async finishedProductList(
    context: QueryFunctionContext
  ): Promise<IFinishedProductListResponse> {
    return await Http.fetcher<IFinishedProductListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/ListarProdutoAcabado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteProduct(body: IDeleteProductRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/RemoverProdutos`,
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async productStatus(body: IChangeProductStatusRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async isProductNameAlreadyRegistred(
    body: IQueryParams
  ): Promise<AxiosResponse<boolean>> {
    const axios = await Http.axios();
    return axios.get<boolean>(
      Http.AddODataQueryParams('/estoque/odata/v1/Produto', body)
    );
  }

  public static async productNameValidation(body: {
    descricao: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/estoque/v1/Produto/ValidarDescricao', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async inventoryListIncrementing(
    body: IQueryParams
  ): Promise<AxiosResponse<IProductListResponse>> {
    const axios = await Http.axios();
    return axios.get<IProductListResponse>(
      Http.AddODataQueryParams('/estoque/odata/v1/LocalEstoque', body)
    );
  }

  public static async groupList(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse> {
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Grupo/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async subGroupsOfGroupsList(
    context: QueryFunctionContext
  ): Promise<ISubGroupsOfGroupListResponse> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Grupo/ListarDetalhadoGrupoSubgrupos`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&grupoExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<ISubGroupsOfGroupListResponse>(url);
  }

  public static async productsOfGroupsList(
    context: QueryFunctionContext<any>
  ): Promise<IProductsOfGroupListResponse | null> {
    return await Http.fetcher<IProductsOfGroupListResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/Produto/ListarDetalhadoPorGrupo`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getGroupListDropdown(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse> {
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Grupo/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createProduct(body: ICreateProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/Produto', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProduct(body: IEditProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Produto`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async editProductFinancial(body: IEditFinancialProductRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/AtualizarFinanceiro`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    if (!res) return [];

    return res;
  }

  public static async editFinancialProduct(
    body: IEditFinancialProductRequest
  ): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.put('/pharma/estoque/v1/Produto/AtualizarFinanceiro', body);
  }

  public static async createSubGroup(body: ICreateSubGroupProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/SubGrupo', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async createGroup(body: ICreateProductGroupRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/Grupo', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async subGroupList(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupListResponse> {
    return await Http.fetcher<IProductSubGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Subgrupo/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async productsOfSubGroupsList(
    context: QueryFunctionContext<any>
  ): Promise<ISubGroupsOfGroupListResponse | null> {
    return await Http.fetcher<ISubGroupsOfGroupListResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/Produto/ListarDetalhadoPorSubgrupo`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getSubGroupByGroupDropdown(
    context: QueryFunctionContext<any>
  ): Promise<ISubGroupsByGroupListResponse | null> {
    return await Http.fetcher<ISubGroupsByGroupListResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/SubGrupo/Listar/`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getSubGroupListDropdown(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupListResponse> {
    return await Http.fetcher<IProductSubGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/SubGrupo/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getproductSubGroup(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupResponse> {
    return await Http.fetcher<IProductSubGroupResponse>(
      `/pharma/estoque/v1/SubGrupo/${context.queryKey[1]}`
    );
  }

  public static async getProductGroup(
    context: QueryFunctionContext
  ): Promise<IProductGroupResponse> {
    return await Http.fetcher<IProductGroupResponse>(
      `/pharma/estoque/v1/Grupo/${context.queryKey[1]}`
    );
  }

  public static async productGroupNameValidation(body: {
    descricao: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher(
      '/pharma/estoque/v1/Grupo/ValidarDescricaoGrupo',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async deleteProductGroup(body: IDeleteProductGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Grupo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async deleteProductSubGroup(
    body: IDeleteProductSubGroupRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/SubGrupo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProductGroup(body: IEditProductGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Grupo`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProductSubGroup(body: IEditProductSubGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/SubGrupo`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async isProductSubgroupNameAlreadyRegistred(
    body: IQueryParams
  ): Promise<AxiosResponse<boolean>> {
    const axios = await Http.axios();
    return axios.get<boolean>(
      Http.AddODataQueryParams('/estoque/odata/v1/SubGrupo', body)
    );
  }

  public static async getListProductDropDown(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getProductPrescriptionList(
    context: QueryFunctionContext
  ): Promise<IProductPrescriptionListResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IProductPrescriptionListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/ListarPrescricao',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getLisUnitMeasureDropDown(
    body: IUnitMeasureClassProductRequest
  ): Promise<AxiosResponse<IUnitMeasureResponse>> {
    const axios = await Http.axios();
    return axios.get<IUnitMeasureResponse>(
      `/pharma/estoque/v1/UnidadeMedida/ListarUnidadesPorClasseProduto/${body.classType}`
    );
  }

  public static async productSubGroupNameValidation(body: {
    descricao: string;
    grupoExternalId: string;
    subGrupoExternalId?: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/estoque/v1/SubGrupo/ValidarDescricao', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async getProductLinkedMessageList(
    context: QueryFunctionContext
  ): Promise<IProductLinkedMessageListResponse | null> {
    if (!context.queryKey[1]) {
      return null;
    }
    return await Http.fetcher(
      Http.AddQueryParams(
        `/pharma/estoque/v1/ProdutoMensagem/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getIncompatibleProductList(
    context: QueryFunctionContext
  ): Promise<any | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/ProdutoIncompativel/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteIncompatibleProduct(
    body: IDeleteProductIncompatibleRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoIncompativel`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getListProductIncompatibleDropDown(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/ProdutoIncompativel/ListarProdutos',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createIncompatibleProduct(
    body: ICreateIncompatibleProductRequest
  ) {
    const res = await Http.fetcher('/pharma/estoque/v1/ProdutoIncompativel', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (!res) return null;

    return res;
  }

  public static async getIncompatibleProduct(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IIncompatibleProductRequest>(
      `/pharma/estoque/v1/ProdutoIncompativel/${context.queryKey[1]}`
    );
  }

  public static async deleteProductLinkedMessage(
    body: IDeleteProductLinkedMessageRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoMensagem`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async editIncompatibleProduct(
    body: ICreateIncompatibleProductRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoIncompativel`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getSynonymProductList(
    context: QueryFunctionContext
  ): Promise<any | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/ProdutoSinonimo/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createSynonym(body: ICreateSynonymProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/ProdutoSinonimo', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (!res) return null;

    return res;
  }

  public static async createProductlinkedMessage(
    body: ICreateProductLinkedMessage
  ) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Mensagem/VinculadoAoProduto`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async createProductMessageLinked(
    body: ICreateProductMessageLinked
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoMensagem`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async deleteSynonymProduct(body: IDeleteSynonymProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoSinonimo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async editSynonymProduct(body: ICreateSynonymProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoSinonimo`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getSynonymProduct(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<ISynonymRequest>(
      `/pharma/estoque/v1/ProdutoSinonimo/${context.queryKey[1]}`
    );
  }

  public static async productSynonymValidation(body: {
    descricao: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher(
      '/pharma/estoque/v1/ProdutoSinonimo/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async getDilutedProductList(
    context: QueryFunctionContext
  ): Promise<any | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/ProdutoDiluido/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createDilutedProduct(body: ICreateDilutedProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/ProdutoDiluido', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (!res) return null;

    return res;
  }

  public static async deleteDilutedProduct(body: IDeleteDilutedProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoDiluido`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getDilutedProduct(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IDilutedProductResponse>(
      `/pharma/estoque/v1/ProdutoDiluido/${context.queryKey[1]}`
    );
  }

  public static async editDilutedProduct(body: ICreateDilutedProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoDiluido`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getAssociatedProductList(
    context: QueryFunctionContext
  ): Promise<any | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/ProdutoAssociado/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createAssociatedProduct(body: IAssociatedProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/ProdutoAssociado', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (!res) return null;

    return res;
  }

  public static async getAssociatedProduct(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return;
    return await Http.fetcher<IAssociatedProductResponse>(
      `/pharma/estoque/v1/ProdutoAssociado/${context.queryKey[1]}`
    );
  }

  public static async editAssociatedProduct(body: IAssociatedProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoAssociado`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async deleteAssociatedProduct(
    body: IDeleteAssociatedProductRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/ProdutoAssociado`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getListAssociatedProductPharmaceuticalFormDropDown(
    context: any
  ): Promise<IListPharmaceuticalFormDropdownSimpleResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/estoque/v1/ProdutoAssociado/ListarFormasFarmaceuticas',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
