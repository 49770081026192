import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LIST_DOCUMENT } from '@/ControllerApiHook/UniqIds/People/DocumentKeys';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { isNil } from 'lodash';
import { useEffect } from 'react';

interface IDocumentCallAPI {
  personType?: number;
}

export const useDocumentCardCallAPI = ({ personType }: IDocumentCallAPI) => {
  const {
    data: documentList,
    fetchNewPage: fetchNewPageDocument,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: LIST_DOCUMENT,
    entityApi: DocumentAPI.listDocument,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
    enabled: !isNil(personType),
  });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        {
          filterName: 'tipoPessoa',
          value: personType === 1 ? 1 : '0',
        },
        { filterName: 'ordem', value: [0, 1, 9] },
      ],
    });
  }, [personType]);

  return { documentList, fetchNewPageDocument };
};
