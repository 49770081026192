import { useFormikContext } from 'formik';

export const useCheckLotNumber = (itemId: string) => {
  const form: any = useFormikContext();

  const checkLotNumber = (lotIndex: number) => {
    const lots = form.values.lotes;
    const filteredLots = lots.filter((e: any, indexLotForm: number) => {
      return e.notaFiscalEntradaItemId === itemId && indexLotForm !== lotIndex;
    });

    const lotNumberExists = filteredLots.some((e: any) => {
      return (
        e.numeroLote === lots[lotIndex].numeroLote &&
        !!lots[lotIndex].numeroLote
      );
    });
    return lotNumberExists;
  };

  return { checkLotNumber };
};
