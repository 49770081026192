import { useCallback } from 'react';

import { IUseFunctions } from './interfaces';

export const useProviderFunctions = ({
  refetchSearchProviderPage,
}: IUseFunctions) => {
  const onSearch = useCallback(
    (search: string | undefined) => {
      refetchSearchProviderPage({
        search: search,
      });
    },
    [refetchSearchProviderPage]
  );

  return {
    onSearch,
  };
};
