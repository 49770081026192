import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCard } from '../ButtonCard';
import { UseMutationResult } from '@tanstack/react-query';
import { AdderList, Card, Form } from '../..';
import { DocumentModal } from '../Modal';
import { IPermissionWrapper } from '../../PermissionWrapper';
import { ObjectSchema } from 'yup';

interface IDocumentCard {
  addEntity: UseMutationResult<any, any, any, any>;
  editEntity: UseMutationResult<any, any, any, any>;
  deleteEntity: UseMutationResult<any, any, any, any>;
  entityDocuments?: any[];
  entityExternalId?: string;
  entityDocumentExternalIdName: string;
  entityExternalIdName: string;
  entityDocumentPermission?: IPermissionWrapper;
  documentList: any;
  fetchNewPageDocument: () => void;
  schema?: ObjectSchema<any, object> | undefined;
}
export const DocumentCard: FC<IDocumentCard> = ({
  addEntity,
  editEntity,
  deleteEntity,
  entityDocuments,
  entityExternalId,
  entityDocumentExternalIdName,
  entityExternalIdName,
  entityDocumentPermission,
  documentList,
  fetchNewPageDocument,
  schema,
}) => {
  const [editCardDisabled, setEditCardDisabled] = useState(true);

  const { t } = useTranslation();
  return (
    <Card title={t('document.documents')} withoutCollapse>
      <Form>
        {(props) => (
          <>
            <AdderList
              type="documentType"
              schema={schema}
              items={entityDocuments?.map((x) => ({
                description: [
                  x.tipoDocumentoId,
                  x.documentoIdentificacao,
                  x.documentoObservacao,
                ],
                favorite: x.documentoPrincipal,
                values: {
                  [entityDocumentExternalIdName]: x.documentoExternalId,
                  tipoDocumentoId: x.tipoDocumentoId,
                  identificacao: x.documentoIdentificacao,
                  principal: x.documentoPrincipal,
                  observacao: x.documentoObservacao,
                },
              }))}
              onChange={(values) => props.setFieldValue('documentos', values)}
              collum={[
                {
                  label: t('prescribers.createPage.document.documentType'),
                  width: 8,
                  key: 'tipoDocumentoId',
                },
                {
                  label: t('prescribers.createPage.document.document'),
                  width: 8,
                  key: 'identificacao',
                },
                {
                  label: t('prescribers.createPage.document.observation'),
                  width: 8,
                  key: 'observacao',
                },
              ]}
              modal={{
                editTitle: t('document.editDocument'),
                addTitle: t('document.addDocument'),
                modalFormName: [
                  'tipoDocumentoId',
                  'identificacao',
                  'observacao',
                ],
                modalFormRequiredName: ['tipoDocumentoId', 'identificacao'],
                body: (
                  <DocumentModal
                    documentList={documentList}
                    fetchNewPageDocument={fetchNewPageDocument}
                    props={props}
                  />
                ),
                okAddButtonLabel: t('common.add'),
                okEditButtonLabel: t('common.save'),
                widthModal: 600,
              }}
              buttonLabel={t('document.addDocument')}
              empytDataDescription={t('document.documentEmpyt')}
              actions={!editCardDisabled}
              withoutFavorite
              withoutAddButton={editCardDisabled}
              onAdd={async (x) =>
                entityExternalId &&
                (await addEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onEdit={async (x) =>
                entityExternalId &&
                (await editEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onDelete={async (x) =>
                x.values[entityDocumentExternalIdName] &&
                (await deleteEntity.mutateAsync(
                  x.values[entityDocumentExternalIdName]
                ))
              }
              entityExternalIdName={entityDocumentExternalIdName}
            />
            {entityDocuments && (
              <ButtonCard
                type="documentType"
                disabled={editCardDisabled}
                onDisabledChange={setEditCardDisabled}
                permission={entityDocumentPermission}
              />
            )}
          </>
        )}
      </Form>
    </Card>
  );
};
