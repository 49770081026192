import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  setpharmaceuticaFormExternalId,
  pharmaceuticaFormDetail,
  setIdUntabbreviated,
  setUntabbreviated,
}: IUseFunctions) => {
  const handlerInputPharmaceuticalFormChange = (value: string) => {
    setpharmaceuticaFormExternalId(value);
  };

  const { abreviacaoUnidadeMedida, unidadeMedidaId } =
    pharmaceuticaFormDetail ?? {};

  if (abreviacaoUnidadeMedida && unidadeMedidaId) {
    setUntabbreviated(abreviacaoUnidadeMedida);
    setIdUntabbreviated(unidadeMedidaId);
  }

  return {
    handlerInputPharmaceuticalFormChange,
  };
};
