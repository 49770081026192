import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { THERE_IS_STANDARD_FORMULA_TYPE } from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { FETCH_GET_PRODUCT, FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useInputProductCallAPI = ({
  onStandardFormulaError,
  produtoExternalId
}: IUseCallAPI) => {
  const {
    data: productList,
    isLoading: isProductLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [produtoExternalId]
  });

  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_STANDARD_FORMULA_TYPE,
    entityApi: StandardFormulaAPI.isStandardFormulaNameAlreadyRegistred,
    options: {
      onError: onStandardFormulaError,
    },
  });

  return {
    productList,
    product,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
    mutateAsync,
  };
};
