import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { IInputProduct } from './interfaces';
import { useInputProductCallAPI } from './Hooks/useInputProductCallAPI';
import { useInputProductFunctions } from './Hooks/useInputProductFunctions';
import { useInputProductMemorizations } from './Hooks/useInputProductMemorizations';

export const InputProduct: FC<IInputProduct> = ({
  setClassificationProductId,
  setUnidadeMedidaId,
  produtoExternalId,
  disabled,
  standardFormula,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { onStandardFormulaError } = useInputProductFunctions({});

  const {
    productList,
    product,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
    mutateAsync,
  } = useInputProductCallAPI({
    onStandardFormulaError,
    produtoExternalId,
  });

  const { onSearch, onChange } = useInputProductFunctions({
    mutateAsync,
    refetchSearchProductPage,
    productList,
    product,
    setClassificationProductId,
    setUnidadeMedidaId,
    produtoExternalId,
  });

  const { items } = useInputProductMemorizations({
    productList,
  });

  return (
    <Tooltip
      title={`${t(
        'production.standardFormula.details.tooltips.previousProduct'
      )} ${standardFormula?.descricaoProdutoFormula}`}
      targetOffset={[0, -18]}
      showMe={!disabled && produtoExternalId !== form.values.produtoExternalId}
    >
      <InputWithSearch
        items={items}
        name="produtoExternalId"
        placeHolder={t('production.standardFormula.details.productPlaceholder')}
        label={t('production.standardFormula.details.product')}
        disabled={disabled}
        required={!disabled}
        withoutMarginBottom
        isLoading={isProductLoading}
        onScrollEnd={fetchNewProductPage}
         selectedItemFromForm="productLabelInitialValue"
        onSearch={onSearch}
        onChange={onChange}
        withStatusIcon
      />
    </Tooltip>
  );
};
