import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_PHARMACEUTICAL_FORM,
  LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IUseCallAPI } from './interfaces';

export const useCreateCallAPI = ({
  pharmaceuticaFormExternalId,
}: IUseCallAPI) => {
  const {
    data: listPharmaceuticalForm,
    isLoading: isLoadingPharmaceuticalFormList,
    fetchNewPage: fetchNewPharmaceuticalFormListPage,
    refetch: refetchSearchPharmaceuticalFormListPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const { data: pharmaceuticaFormDetail } = useControllerQueryApiHook({
    uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
    externalIds: [pharmaceuticaFormExternalId],
  });

  return {
    listPharmaceuticalForm,
    isLoadingPharmaceuticalFormList,
    fetchNewPharmaceuticalFormListPage,
    refetchSearchPharmaceuticalFormListPage,
    pharmaceuticaFormDetail,
  };
};
