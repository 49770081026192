import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components/Inputs/InputWithSearch';
import { TextInput } from '@/Components/Inputs/TextInput';
import { useModalBodyCallAPI } from './Hooks/useModalBodyCallAPI';
import { useModalBodyFunctions } from './Hooks/useModalBodyFunctions';
import { useModalBodyMemorizations } from './Hooks/useModalBodyMemorizations';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { IProductSubGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

interface IModalbody {
  selectedRowsOnTable: IProductSubGroupListData[];
}

export const ModalBody: React.FC<IModalbody> = ({ selectedRowsOnTable }) => {
  const abbreviatedI18n = 'products.subGroupList.modal';
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { onSubGroupValidationError, onSubGroupValidationSuccess } =
    useModalBodyFunctions();

  const { dataGroupDropDown, fetchNewGroupPage, mutateAsync, onSearch } =
    useModalBodyCallAPI({
      onSubGroupValidationError,
      onSubGroupValidationSuccess,
    });

  const { fetchProductSubGroupValidationName, items } =
    useModalBodyMemorizations({
      mutateAsync,
      dataGroupDropDown,
    });

  return (
    <div style={{ padding: '20px' }}>
      <ConnectedFocusError />
      <TextInput
        name="descricao"
        label={t(`${abbreviatedI18n}.subGroupLabel`)}
        placeHolder={t(`${abbreviatedI18n}.subGroupPlaceholder`)}
        type="text"
        onChange={(x) => {
          !selectedRowsOnTable
            ? fetchProductSubGroupValidationName(
                x,
                form.values.grupoPaiExternalId
              )
            : fetchProductSubGroupValidationName(
                x,
                form.values.grupoPaiExternalId,
                selectedRowsOnTable[0].externalId
              );
        }}
        dataTestId="subgroup-description"
        required
      />
      <InputWithSearch
        label={t(`${abbreviatedI18n}.groupLabel`)}
        items={items}
        withoutSearchIcon
        placeHolder={t(`${abbreviatedI18n}.groupPlaceholder`)}
        name="grupoPaiExternalId"
        withoutMarginBottom
        onChange={(x) => {
          !selectedRowsOnTable
            ? fetchProductSubGroupValidationName(form.values.descricao, x)
            : fetchProductSubGroupValidationName(
                form.values.descricao,
                x,
                selectedRowsOnTable[0].externalId
              );
        }}
        onSearch={onSearch}
        onScrollEnd={fetchNewGroupPage}
        dataTestId="primary-group"
        required
        selectedItemFromForm="groupLabel"
      />
    </div>
  );
};
