import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../../DetailsTab';

export const useTabs = (edit: boolean, setEdit: any) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('common.details'),
      key: 'details',
      children: <DetailsTab edit={edit} setEdit={setEdit} />,
    },
    {
      label: t('common.release'),
      key: 'release',
      children: '',
      disabled: true,
    },
  ];

  return {
    tabs,
  };
};
