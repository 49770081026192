import { Icon, Label, Skeleton, Tag, Text } from '@/Components';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_SIMPLIFIED_PRESCRIBER } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { ICreateManipulatedRecipe } from '@/Data/Interfaces/request/Production/ManipulatedRecipe';
import { IGetSimplifiedPrescriberResponse } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { TagsRender } from '@/Utils/TagsUtils/TagsUtils';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { FC } from 'react';

export const Content: FC = () => {
  const form: FormikContextType<ICreateManipulatedRecipe> = useFormikContext();

  const { data: prescriberGet, isLoading: prescriberLoading } =
    useControllerQueryApiHook<IGetSimplifiedPrescriberResponse>({
      uniqId: GET_SIMPLIFIED_PRESCRIBER,
      entityApi: PrescribersAPI.getSimplifiedPrescriber,
      externalIds: [form.values.prescritorExternalId],
    });

  return prescriberLoading || !prescriberGet ? (
    <>
      <Skeleton size="md" />
      <br />
      <Skeleton size="md" />
      <br />
      <Skeleton size="md" />
    </>
  ) : (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={'Registro'}
          withBottomMargin
        />
        <Text type="ui-tiny-semibold" color="text-50">
          {`${prescriberGet?.siglaRegistro}/${prescriberGet?.abreviacaoUfRegistro} ${prescriberGet?.codigoRegistro}`}
        </Text>
      </Col>
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={'Especialidades'}
          withBottomMargin
        />
        <TagsRender
          data={prescriberGet.especialidades.map((value) => value.descricao)}
          widthCollum={268}
        />
      </Col>
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={'Contato Principal'}
          withBottomMargin
        />
        {prescriberGet?.contatoPrincipal.length > 0 && (
          <Divflex>
            <Icon
              icon={prescriberGet?.contatoPrincipal[0]?.tipoContatoIcon}
              style={{ marginRight: 6 }}
            />
            <Text type="ui-tiny-content" color="text-50">
              {prescriberGet?.contatoPrincipal[0]?.contatoIdentificacao}
            </Text>
          </Divflex>
        )}
      </Col>
    </Row>
  );
};
