import { Tooltip } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';

interface ICalendarInputProps {
  name: string;
  placeHolder: string;
  label: string;
  error: boolean;
  errorMessage: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

export const CalendarInput = ({
  name,
  placeHolder,
  label,
  error,
  errorMessage,
  onClick,
  onChange,
}: ICalendarInputProps) => {
  return (
    <div onClick={() => onClick?.()}>
      <Tooltip title={errorMessage} showMe={error}>
        <TextInputWithMask
          name={name}
          leftIcon="calendar"
          placeHolder={placeHolder}
          required
          label={label}
          mask="dataMask"
          error={error}
          onChange={(value) => onChange?.(value)}
        />
      </Tooltip>
    </div>
  );
};
