import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const ContactCardSchema = yup.object().shape({
  tipoContatoId: yup
    .number()
    .nullable()
    .required(i18n.t('contact.requiredTypeContact')),
  identificacao: yup
    .string()
    .nullable()
    .required(i18n.t('contact.requiredContact')),
});
