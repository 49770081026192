import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const PurchaseOrderCreateSchema = yup.object().shape({
  fornecedorExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('purchasing.purchaseOrder.create.errors.requiredProvider')
    ),
  pedidoCompraItens: yup.array().of(
    yup.object().shape({
      produtoExternalId: yup
        .string()
        .required(
          i18n.t(
            i18n.t('purchasing.purchaseOrder.create.errors.requiredProduct')
          )
        ),
      precoUnitario: yup
        .string()
        .required(
          i18n.t('purchasing.purchaseOrder.create.errors.requiredUnitaryValue')
        ),
      quantidade: yup
        .string()
        .required(
          i18n.t('purchasing.purchaseOrder.create.errors.requiredAmount')
        ),
    })
  ),
});
