import * as yup from 'yup';
import i18n from '@/Components/i18n';
import DateUtils from '@/Utils/DateUtils';

export const PurchaseOrderEditSchema = yup.object().shape({
  fornecedorExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('purchasing.purchaseOrder.create.errors.requiredProvider')
    ),
  pedidoCompraItens: yup
    .array()
    .of(
      yup.object().shape({
        produtoExternalId: yup
          .string()
          .required(
            i18n.t('purchasing.purchaseOrder.create.errors.requiredProduct')
          ),
        precoUnitario: yup
          .number()
          .required(
            i18n.t(
              'purchasing.purchaseOrder.create.errors.requiredUnitaryValue'
            )
          ),
        quantidade: yup
          .number()
          .required(
            i18n.t('purchasing.purchaseOrder.create.errors.requiredAmount')
          ),
      })
    )
    .test('', '', function (value: any) {
      const lastItem = value[value.length - 1];
      if (lastItem && this.parent.pedidoCompraItens.length === value.length) {
        return this.parent.pedidoCompraItens.pop() === lastItem;
      }
      return true;
    })
    .required(),
  previsaoEntrega: yup
    .date()
    .transform((value: Date, originalValue: string) => {
      if (typeof originalValue !== 'string') {
        return value;
      }
      var parsedDate = DateUtils.convertStringToDate(originalValue);
      if (isNaN(parsedDate.getTime())) {
        var now = new Date();
        now.setDate(now.getDate() - 1);
        return now;
      }
      return parsedDate;
    })
    .required(i18n.t('purchasing.purchaseOrder.create.errors.requiredDate'))
    .min(
      new Date(),
      i18n.t('purchasing.purchaseOrder.create.errors.dateIsEarlier')
    ),
});
