import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const DocumentCardSchema = yup.object().shape({
  tipoDocumentoId: yup
    .number()
    .nullable()
    .required(i18n.t('document.requiredTypeDocument')),
  identificacao: yup
    .string()
    .nullable()
    .required(i18n.t('document.requiredDocument')),
});
