import * as yup from 'yup';
import { i18n } from '../../../../../Components';

export const CreateSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    corCapsula: yup
      .string()
      .required(i18n.t('production.capsuleColors.create.erros.requiredName'))
      .test(
        'checkDuplications',
        t('production.capsuleColors.errors.errorCapsuleColorNameExist'),
        (value) => {
          return new Promise((resolve) => {
            validateName(value, (success) => {
              resolve(success);
            });
          });
        }
      ),
  });
