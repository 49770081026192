import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { history } from '@/Store';
import {
  messageErrorToast,
} from '@/Utils/MessageErrorToast';

export const useCreateInventory = () => {
  const createInventory: any = useControllerMutateApiHook({
    uniqId: CREATE_INVENTORY,
    entityApi: InventoryAPI.createInventory,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => { 
        history.push('/inventory/stock-taking');
      }
    },
  });

  return { createInventory };
};
