import {
  CREATE_PRODUCT_LINKED_MESSAGE,
  CREATE_PRODUCT_MESSAGE_LINKED,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IGetProductMessage } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { FETCH_GET_PRODUCT_MESSAGE } from '@/ControllerApiHook/UniqIds/Supply/ProductMessageKeys';
import { ProductMessageAPI } from '@/Data/API/Supply/ProductMensage';

interface IProductMessageCallAPI {
  productMessageId?: string;
}

export const useLinkedProductMessageModalCallAPI = ({
  productMessageId,
}: IProductMessageCallAPI) => {
  const createProductLinkedMessage: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT_LINKED_MESSAGE,
    entityApi: ProductAPI.createProductlinkedMessage,
  });

  const createProductMessageLinked: any = useControllerMutateApiHook({
    uniqId: CREATE_PRODUCT_MESSAGE_LINKED,
    entityApi: ProductAPI.createProductMessageLinked,
  });

  const { data: productMessage } =
    useControllerQueryApiHook<IGetProductMessage>({
      uniqId: FETCH_GET_PRODUCT_MESSAGE,
      entityApi: ProductMessageAPI.getCurrentProductMessage,
      externalIds: [productMessageId],
    });

  return {
    createProductLinkedMessage,
    createProductMessageLinked,
    productMessage,
  };
};
