import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Text, Icon } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { DeleteModalOnTableErrorCode } from '@/Utils/DeleteModalUtils';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          content?.length === 1 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('supply.supplyPlaces.modal.cannotDelete.subTitle', {
                    storagelocation: `**${content[0].localEstoqueDescricao}**`,
                    company: `**${content[0].empresaDescricao}**`,
                  })}
                </ReactMarkdown>
              </Text>

              <Text type="ui-tiny-content" style={{ marginTop: 25 }}>
                {t('supply.supplyPlaces.modal.cannotDelete.description')}
              </Text>

              <Divflex style={{ marginTop: '20px' }}>
                <Icon
                  icon={'exclamation-upside-down'}
                  size={'SM'}
                  color={'text-400'}
                  style={{ padding: '18px 18px 0 8px' }}
                />
                <Text
                  type="paragraph2"
                  color={'text-400'}
                  children={t(
                    'supply.supplyPlaces.modal.cannotDelete.warningFooter'
                  )}
                />
              </Divflex>
            </div>
          ) : (
            DeleteModalOnTableErrorCode(
              content,
              t('supply.supplyPlaces.modal.cannotDelete.batchSubTitle'),
              'localEstoqueDescricao',
              '',
              t('supply.supplyPlaces.modal.cannotDelete.batchDescription'),
              t('supply.supplyPlaces.modal.cannotDelete.batchWarningFooter'),
              'empresaDescricao'
            )
          )
        }
        style={{ padding: 20 }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content?.length === 1
        ? t('supply.supplyPlaces.modal.cannotDelete.title', {
            storagelocation: content[0].localEstoqueDescricao,
          })
        : t('supply.supplyPlaces.modal.cannotDelete.batchTitle', {
            storagelocation: content.length,
          })}
    </>
  );
};

const Footer: FC<{
  params: { localEstoqueExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      cancelButtonName={t('supply.supplyPlaces.modal.cannotDelete.ok')}
      onOkClick={() => {
        history.push(`/inventory/supply-places`);
        instance.destroy();
      }}
      onCancelClick={() => instance.destroy()}
      withoutOkButton
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { localEstoqueExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});
