import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';
import { IDescriptionRow } from './interfaces';

export const DescriptionRow: FC<IDescriptionRow> = ({
  disabledForm,
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="space-between">
      <Col span={24}>
        <TextInput
          name="nome"
          label={t('admin.editUserGroups.detailForm.name')}
          placeHolder={t('admin.editUserGroups.detailForm.namePlaceholder')}
          disabled={disabledForm}
          required
        />
      </Col>
    </Row>
  );
};
