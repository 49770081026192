import { CircularStatus } from '@/Components/CircularStatus';
import styles from './productRow.module.scss';
import { Col, Row } from 'antd';
import useMediaQuery from '@/Utils/CustomHooks/useMediaQuery';
import { useConfigColumnSpan } from '../hooks/useConfigColumnSpan';
import StorageUtils from '@/Utils/StorageUtils';
import { useFormatQuantity } from '../hooks/useFormatQuantity';
import { Button, Icon, TextInput, Tooltip } from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useTranslation } from 'react-i18next';
import { CalendarInput } from './CalendarInput';
import { useConfigDatePlaceholder } from '../hooks/useConfigDatePlaceholder';
import { useValidateDates } from '../hooks/useValidateDates';
import { useListUnitMeasure } from '../hooks/useListUnitMeasurement';
import { useControlRows } from '../hooks/useControlRows';
import { useConfigQuantityExcessOrDeficit } from '../hooks/useConfigQuantityExcessOrDeficit';
import { useState } from 'react';
import { useCheckLotNumber } from '../hooks/useCheckLotNumber';
import { useFormikContext } from 'formik';
import { CircularStatusComponent } from './CircularStatusComponent';

interface IProductRowProps {
  productDescription: string;
  quantity: number;
  unityId: number;
  unityAbbreviation: string;
  unitType: string;
  lotIndex: number;
  showTechnicalInformation: boolean;
  itemId: string;
  selectRowIndex: (index: number) => void;
  selectedRow: number | null;
  productClass: string;
  toggleShowTechnicalInformation: () => void;
}

export const ProductRowLot: React.FC<IProductRowProps> = ({
  productDescription,
  quantity,
  unitType,
  unityAbbreviation,
  unityId,
  showTechnicalInformation,
  itemId,
  selectRowIndex,
  selectedRow,
  productClass,
  toggleShowTechnicalInformation,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const [currentUnitAbbreviation, setCurrentUnitAbbreviation] =
    useState(unityAbbreviation);
  const preferences = StorageUtils.getPreference();
  const showDatesWidth = '2133px';
  const keepDatesOnScreen = useMediaQuery(`(min-width: ${showDatesWidth})`);
  const { configColumnsSpacing } = useConfigColumnSpan();
  const { formatQuantity } = useFormatQuantity();
  const { configDatePlaceHolder } = useConfigDatePlaceholder();
  const { validateDates } = useValidateDates();
  const { checkLotNumber } = useCheckLotNumber(itemId);
  const { unitOfMeasure } = useListUnitMeasure(unitType);
  const { configRows } = useControlRows();
  const rows = configRows(itemId);
  const quantityControl = useConfigQuantityExcessOrDeficit(
    itemId,
    quantity,
    currentUnitAbbreviation
  );

  const { firstColumn, restColumns } = configColumnsSpacing(
    keepDatesOnScreen,
    showTechnicalInformation
  );

  return (
    <>
      {rows.map((e: any, indexLot: number) => {
        return (
          <>
            <Row
              wrap={false}
              gutter={[16, 0]}
              className={`${styles['productRow']} ${
                selectedRow === e.lotIndex ? styles['selectedRow'] : ''
              }`}
              onClick={() => {
                selectRowIndex(e.lotIndex);
                toggleShowTechnicalInformation();
              }}
            >
              <Col span={firstColumn}>
                <div
                  className={styles['productMainInfo']}
                  style={{ display: indexLot !== 0 ? 'none' : '' }}
                >
                  <CircularStatusComponent
                    form={form}
                    itemId={itemId}
                    productDeficit={quantityControl.productDeficit}
                    productQuantity={quantity}
                    quantity={quantity}
                    classDescription={productClass}
                  />
                  <div className={styles['textContainer']}>
                    <p className={styles['productDescription']}>
                      {productDescription}
                    </p>
                    <p className={styles['productQuantity']}>
                      {formatQuantity(
                        quantity,
                        preferences ? preferences.idioma : 'pt-BR'
                      )}{' '}
                      {unityAbbreviation}
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                span={restColumns}
                className={styles['alignVerticalContent']}
                onClick={(e) => e.stopPropagation()}
              >
                <Tooltip
                  title={quantityControl.quantityErrorMessage}
                  showMe={
                    !!quantityControl.productExcess ||
                    !!quantityControl.productDeficit
                  }
                >
                  <WeightInput
                    error={
                      !!quantityControl.productExcess ||
                      !!quantityControl.productDeficit
                    }
                    name={`lotes.${e.lotIndex}.quantidade`}
                    placeHolder="0,0000"
                    required
                    onChange={(value) => {
                      quantityControl.calculateExcessOrDeficit(
                        value,
                        e.lotIndex
                      );
                    }}
                    dropdownRight={{
                      name: `lotes.${e.lotIndex}.unidadeMedidaId`,
                      options: unitOfMeasure.map((e: any) => {
                        return {
                          id: e.id,
                          content: e.unidadeAbreviacao,
                        };
                      }),
                    }}
                  />
                </Tooltip>
              </Col>
              <Col
                span={restColumns}
                className={styles['alignVerticalContent']}
                onClick={(e) => e.stopPropagation()}
              >
                <Tooltip
                  title={t('purchasing.invoice.registerLots.lotNameError')}
                  showMe={checkLotNumber(e.lotIndex)}
                >
                  <TextInput
                    error={checkLotNumber(e.lotIndex)}
                    name={`lotes.${e.lotIndex}.numeroLote`}
                    placeHolder={t(
                      'purchasing.invoice.registerLots.lotPlaceHolder'
                    )}
                  />
                </Tooltip>
              </Col>
              <Col
                span={restColumns}
                className={`${styles['alignVerticalContent']} ${
                  showTechnicalInformation && !keepDatesOnScreen
                    ? styles['hideInformation']
                    : ''
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <CalendarInput
                  name={`lotes.${e.lotIndex}.dataFabricacao`}
                  placeHolder={configDatePlaceHolder(preferences) as string}
                  error={!!validateDates(e.lotIndex).messageManufacturingDate}
                  errorMessage={
                    validateDates(e.lotIndex).messageManufacturingDate ?? ''
                  }
                  label=""
                />
              </Col>
              <Col
                span={restColumns}
                className={`${styles['alignVerticalContent']} ${
                  showTechnicalInformation && !keepDatesOnScreen
                    ? styles['hideInformation']
                    : ''
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <CalendarInput
                  name={`lotes.${e.lotIndex}.dataValidade`}
                  placeHolder={configDatePlaceHolder(preferences) as string}
                  error={!!validateDates(e.lotIndex).messageExpirationDate}
                  errorMessage={
                    validateDates(e.lotIndex).messageExpirationDate ?? ''
                  }
                  label=""
                />
              </Col>
              <Col
                className={`${styles['alignVerticalContent']} ${
                  showTechnicalInformation ? styles['hideInformation'] : ''
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div style={{ display: rows.length !== 1 ? 'block' : 'none' }}>
                  <Button
                    type="secondary"
                    style={{
                      display: 'none',
                    }}
                    onClick={() => {
                      const rowValue = form.values.lotes[e.lotIndex].quantidade;
                      const newFormLots = form.values.lotes.filter(
                        (_: any, index: number) => {
                          return index !== e.lotIndex;
                        }
                      );

                      form.setFieldValue('lotes', newFormLots);
                      quantityControl.deleteRow(e.lotIndex, rowValue);
                    }}
                  >
                    <Icon icon="trash" size="M" />
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        );
      })}
    </>
  );
};
