import { IFChildren } from "@/Components/Form";
import { FC } from 'react';
import { Card } from '@/Components';
import { AdderList } from '@/Components/AdderList';
import { useTranslation } from "react-i18next";
import { useAddressCardFunctions } from "./Hooks/useAddressCardFunctions";
import { useAddressCardMemorizations } from "./Hooks/useAddressCardMemorizations";
import { AdderListSchema } from '@/Components/AdderList/AdderList.schema';

export const AddressCard: FC<{ props: IFChildren }> = ({ props }) => {
  const { t } = useTranslation();

  const { 
    column,
    modal 
  } = useAddressCardMemorizations();

  const { onChange } = useAddressCardFunctions({ props });

  return (
    <Card title={t('address.adresses')} withoutCollapse>
      <AdderList
        favoriteFormName="principal"
        onChange={onChange}
        collum={column}
        modal={modal}
        buttonLabel={t('address.addAddress')}
        empytDataDescription={t('address.addressEmpyt')}
        entityExternalIdName="prescritorEnderecoExternalId"
        type="adrressType"
        actions
        schema={AdderListSchema}
      />
    </Card>
  );
};