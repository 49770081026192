import * as yup from 'yup';
import i18n from '@/Components/i18n';
import DateUtils from '@/Utils/DateUtils';
import _ from 'lodash';

export const ManipulatedRecipeSchema = () =>
  yup.object().shape({
    emissao: yup
      .string()
      .test(
        'date validation',
        i18n.t('common.dateInvalid'),
        (val) => _.isNil(val) || DateUtils.isDateValid(val)
      )
      .test(
        'date not in future',
        i18n.t(
          'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.dateGreaterThanCurrentDayInPrescriptionEmission'
        ),
        (val) => {
          if (_.isNil(val) || !DateUtils.isDateValid(val)) return true;
  
          return DateUtils.dateIsInFuture(val);
        }
      )
      .nullable(),
    validade: yup
      .string()
      .test(
        'date validation',
        i18n.t('common.dateInvalid'),
        (val) => _.isNil(val) || DateUtils.isDateValid(val)
      )
      .test(
        'date not in past',
        i18n.t(
          'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.dateLesserThanCurrentDayInPrescriptionValidate'
        ),
        (val) => {
          if (_.isNil(val) || !DateUtils.isDateValid(val)) return true;
  
          return DateUtils.dateIsInPast(val);
        }
      )
      .nullable(),
    validadeReceita: yup
      .string()
      .test(
        'date validation',
        i18n.t('common.dateInvalid'),
        (val) => _.isNil(val) || DateUtils.isDateValid(val)
      )
      .nullable(),
  });
