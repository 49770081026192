import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { FETCH_LIST_SUBGROUP_BY_GROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useEffect } from 'react';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { isNil } from 'lodash';

interface IProductCallAPI {
  product?: IProductResponse;
}

export const useProductCardCallAPI = ({ product }: IProductCallAPI) => {
  const {
    data: listSubGroups,
    fetchNewPage: fetchNewSubGroupPage,
    refetch: refetchNewPageSubGroupPege,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SUBGROUP_BY_GROUP,
    entityApi: ProductAPI.getSubGroupByGroupDropdown,
    enabled: !isNil(product?.grupoExternalId),
  });

  useEffect(() => {
    product?.grupoExternalId &&
      refetchNewPageSubGroupPege({
        filter: [
          {
            filterName: 'grupoExternalId',
            value: product?.grupoExternalId,
          },
        ],
      });
  }, [product?.grupoExternalId, refetchNewPageSubGroupPege]);

  return {
    listSubGroups,
    fetchNewSubGroupPage,
    refetchNewPageSubGroupPege,
  };
};
