import { Card, Form, Button, Checkbox } from '@/Components';
import { useTranslation } from 'react-i18next';
import styles from './inventory.module.scss';
import { SupplyPlaceRow } from './components/SupplyPlaceRow';
import { Col, Row } from 'antd';
import { useCreateInventory } from './hooks/useCreateInventory';
import { history } from '@/Store';

export const InventoryCreate: React.FC = () => {
  const { createInventory } = useCreateInventory();
  const { t } = useTranslation();

  const handleSubmit = async (values: any) => {
    await createInventory.mutateAsync(values);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ inventarioEspecificacao: [] }}
    >
      <Card withoutCollapse title={t('supply.inventory.create.info')}>
        <SupplyPlaceRow />

        <Row gutter={16}>
          <Col span={12} className={styles['checkbox']}>
            <Checkbox
              name="ocultarQuantidadeLancamento"
              label={t('supply.inventory.create.hideAnotherValue')}
              withTooltip={{
                icon: 'question-mark',
                title: t('supply.inventory.create.hiderAnotherValueTooltip'),
              }}
            />
          </Col>
          <Col span={12} className={styles['checkbox']}>
            <Checkbox
              name="ocultarQuantidadeConferencia"
              label={t('supply.inventory.create.hideAnotherValueImpression')}
              withTooltip={{
                icon: 'question-mark',
                title: t(
                  'supply.inventory.create.hiderAnotherValueImpressionTooltip'
                ),
              }}
            />
          </Col>
        </Row>
      </Card>
      <div className={styles['footer-button']}>
        <Button
          type="secondary"
          children={t('products.create.cancel')}
          disabled={createInventory.isLoading}
          onClick={() => history.push('/inventory/stock-taking')}
        />
        <Button
          type="primary"
          htmlType="submit"
          children={t('supply.inventory.create.register')}
          loading={createInventory.isLoading}
        />
      </div>
    </Form>
  );
};
