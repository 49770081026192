import { CardSmaller } from '@/Components/CardSmaller';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col, Row } from 'antd';
import { FC } from 'react';

export const AditionalInformation: FC = () => (
  <CardSmaller
    children={
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <TextInputWithMask
            name="emissao"
            mask="dataMask"
            leftIcon="calendar"
            leftIconColor="text-400"
            placeHolder="dd/mm/aaaa"
            label={'Emissão da Presc.'}
            withoutMarginBottom
          />
        </Col>
        <Col span={8}>
          <TextInputWithMask
            name="validade"
            mask="dataMask"
            leftIcon="calendar"
            leftIconColor="text-400"
            placeHolder="dd/mm/aaaa"
            label={'Validade da Presc.'}
            withoutMarginBottom
          />
        </Col>
        <Col span={8}>
          <TextInputWithMask
            name="validadeReceita"
            mask="dataMask"
            leftIcon="calendar"
            leftIconColor="text-400"
            placeHolder="dd/mm/aaaa"
            label={'Validade da Receita'}
            disabled
            withoutMarginBottom
          />
        </Col>
      </Row>
    }
    title={'Informações adicionais'}
  />
);
