import { useCallback, useEffect } from 'react';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  uuidV4,
  form,
  balanceOriginDestiny,
  destinySupplyPlaceId,
  lotsRefetch,
  originSupplyPlaceId,
  convertUnitOfMeasureAsync,
  unitsMeasurements,
}: IUseFunctions) => {
  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }
  };

  const removeRow = (index: number) => {
    setProductRowAmount((oldState: string[]) => {
      return oldState.filter((_: string, i: number) => i !== index);
    });
  };

  const deleteRow = () => {
    if (!form.values.transfers) return;
    form.setFieldValue(
      `transfers`,
      form.values.transfers.filter((_: any, i: number) => i !== index)
    );

    if (totalRows === 1) {
      setProductRowAmount((oldState) => [...oldState, uuidV4()]);
    }

    removeRow(index);
  };

  useEffect(() => {
    const currentProduct = form.values.transfers[index]?.product;

    if (currentProduct) {
      let qntTransfers = Number(
        form.values.transfers[index]?.qntTransfers || 0
      );

      if (qntTransfers > (balanceOriginDestiny?.saldoLoteOrigem || 0)) {
        qntTransfers = balanceOriginDestiny?.saldoLoteOrigem || 0;
        form.setFieldValue(`transfers.${index}.qntTransfers`, qntTransfers);
      }

      const originBalanceValue = balanceOriginDestiny?.saldoLoteOrigem || 0;
      const destinyBalanceValue =
        (balanceOriginDestiny?.saldoLoteDestino || 0) + qntTransfers;

      if (
        form.values.transfers[index]?.originFunds !== originBalanceValue ||
        !form.values.transfers[index]?.originFunds
      ) {
        form.setFieldValue(
          `transfers.${index}.originFunds`,
          originBalanceValue
        );
      }

      const finalOrigin = originBalanceValue - qntTransfers;
      form.setFieldValue(
        `transfers.${index}.originFunds`,
        finalOrigin >= 0 ? finalOrigin : originBalanceValue
      );

      if (
        form.values.transfers[index]?.destinyFunds !== destinyBalanceValue ||
        !form.values.transfers[index]?.destinyFunds
      ) {
        form.setFieldValue(
          `transfers.${index}.destinyFunds`,
          destinyBalanceValue
        );
      }
    }
  }, [
    form.values.transfers[index]?.product,
    form.values.transfers[index]?.qntTransfers,
    balanceOriginDestiny,
    destinySupplyPlaceId,
    originSupplyPlaceId,
  ]);

  const onUnitOfMeasureChange = useCallback(
    async (selectedId: any) => {
      const currentUnitOfMeasureId =
        form.values.transfers[index].unidadeMedidaId;
      const quantity = form.values.transfers[index].qntTransfers;
      const currentQuantity = form.values.transfers[index].originFunds;

      const result = await convertUnitOfMeasureAsync([
        {
          quantidade: currentQuantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
        {
          quantidade: quantity || 0,
          unidadeMedidaConversaoId: selectedId,
          unidadeMedidaOrigemId: currentUnitOfMeasureId,
        },
      ]);

      if (result && result.length > 0) {
        const saldoAtual = result[0].quantidadeConvertida;
        const quantity = result[1].quantidadeConvertida;

        form.setFieldValue(`transfers.${index}.originFunds`, saldoAtual);
        form.setFieldValue(`transfers.${index}.qntTransfers`, quantity);
        form.setFieldValue(
          `transfers.${index}.destinyFunds`,
          saldoAtual - quantity
        );
      }
    },
    [convertUnitOfMeasureAsync, form.values, index]
  );

  const selectedUnitOfMeasure = unitsMeasurements?.data?.find(
    (x: any) => x.id === form.values.transfers[index]?.unidadeMedidaId
  );

  return {
    addRow,
    deleteRow,
    onUnitOfMeasureChange,
    selectedUnitOfMeasure,
  };
};
