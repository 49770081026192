import { SaleOrderItem } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface IUseEditFunctions {
  item?: SaleOrderItem;
  onClose: () => void;
  setProductModalOpen: Dispatch<SetStateAction<boolean>>;
}
export const useEditFunctions = ({
  onClose,
  setProductModalOpen,
  item,
}: IUseEditFunctions) => {
  const changeVisible = (value: boolean) => {
    setProductModalOpen(value);
    onClose();
  };

  useEffect(() => {
    item !== undefined && setProductModalOpen(true);
  }, [item]);

  return { changeVisible };
};
