export const productsEN = {
  common: {
    product: 'Product',
    products: 'Products',
    subGroup: 'Subgroup',
    subGroups: 'Subgroups',
  },

  toolTips: {
    products: {
      nameOfProduct: 'Original product name:',
      labelDescription: 'Original label description:',
      class: 'Original Class:',
      nameOfGroup: 'Original Group:',
      nameOfSubGroup: 'Original Subgroup name:',
      unitStock: 'Original stock unit:',
      provider: 'Original provider:',
      enabled: 'Enabled',
      disabled: 'Disabled',
      originalValue: 'Original value:',
      costValue: 'Original cost value:',
      profitMargin: 'Original profit margin:',
      saleValue: 'Original sale value:',

      originalDcb: 'Original DCB: ',
      originalCas: 'Original CAS: ',
      originalUnitPrescription: 'Original Prescription Unit: ',
      originalComponentType: 'Original Component Type: ',
      originalValidateDays: 'Original Validation Days: ',
      originalBaseMolecularWeight: 'Original Base Molecular Weight: ',
      originalSaltMolecularWeight: 'Original Salt Molecular Weight: ',
      originalEquivalenceFactor: 'Original Equivalence Factor: ',
      originalToleranceWeighingBelow: 'Original Tolerance Weighing Below: ',
      originalToleranceWeighingAbove: 'Original Tolerance Weighing Above: ',
      originalObservations: 'Original Storage Label Observations: ',
      empty: 'Empty',

      originalPackingClassification: 'Original Packaging Classification: ',
      originalVolume: 'Original Volume:',

      originalCapsuleSize: 'Original capsule size: ',

      copyName: 'Copy product name to label description',
    },
    subGroups: {
      nameOfSubGroup: 'Original Subgroup name:',
      nameOfGroup: 'Original Group:',
    },
    groups: {
      nameOfGroup: 'Original group name:',
    },
  },

  modal: {
    products: {
      title: 'It is not possible to disable batch control.',
      theProduct: 'The product ',
      amount: 'have quantity in stock and thats why ',
      desative: 'it is not possible to disable batch control.',
      warningFooter:
        'To deactivate batch control, empty active batches from the system by creating a balance adjustment.',
    },
    cannotChangeClassType: {
      title: 'Cannot change product class',
      body: 'Cannot edit the class of product {{product}}!',
      footer: 'This product has already had movement within the system, so it cannot be edited.',
    },   
  },

  productList: {
    searchPlaceholder: 'Search for a product',
    tableTitle: {
      code: 'Code',
      productName: 'Product name',
      class: 'Class',
      group: 'Group',
      subGroup: 'Subgroup',
      saleValue: 'Sale Price',
      costValue: 'Cost price',
      status: 'Status',
    },
    newButton: 'New Product',
    stockBalance: {
      searchPlaceHolder: 'Search for a stock location',
      code: 'Code',
      placeName: 'Place name',
      company: 'Company',
      availableQuantity: 'Available quantity',
    },
    productMessage: {
      searchPlaceHolder: 'Search for a product message',
      productMessage: 'Product Message',
      exhibition: 'Exhibition',

      exhibitionType: {
        displaysSale: 'Sale',
        displayLabel: 'Label',
        displaysWeighingSheet: 'Weighing Sheet',
        displayLabeling: 'Labeling',
      },

      modalRemoveProductLinkedMessage: {
        messageTitle: ' the product message',
        messageStartBody:
          'Are you sure you want to remove the product message:',
        messageWarning:
          'The message will only be removed from that product, it will still remain in the Product Messaging system.',
      },

      modalProductLinkedMessage: {
        messageTitle: 'New Product Message',
        placeholderMessageExisting: 'Search for a message',
        placeholderNewMessage: 'Register a new product message',

        messageSystemWarning: 'Display warning in the system',
        messagePrintWarning: 'Show in print',
        checkboxDisplay: {
          checkBox1: 'Sale',
          checkBox2: 'Labeling',
          checkBox3: 'Weighing form',
          checkBox4: 'Label',
        },
        newMessageDropdown: 'New Message',
      },

      raddioButtonMessageTypes: {
        messageExisting: 'Select an existing message',
        createNewMessage: 'Register new message',
      },

      buttonLinkedProductMessage: 'Link New Message',
    },
  },
  groupList: {
    tableTitle: {
      description: 'Name',
      subGroups: 'Subgroups',
      products: 'Products',
    },
    newButton: 'New group',
    placeholder: 'Search for a group',
    modal: {
      title: 'Group registration',

      label: 'Group name',
      placeholder: 'E.g.: Raw materials',

      okButton: 'Register',

      errorGroupNameExist: 'A group with that name already exists',
    },
  },
  subGroupList: {
    tableTitle: {
      description: 'Name',
      groups: 'Group',
      products: 'Products',
    },

    placeholder: 'Search for a subgroup',
    newButton: 'New subgroup',
    goToGroup: 'Go to Group',
    edit: {
      name: 'Name of Subgrupo',
      group: 'Grupo',

      success: 'Subgroup edited successfully',
    },
    deleted: 'Subgroup deleted',

    modal: {
      title: 'Subgroup registration',

      subGroupLabel: 'Subgroup name',
      subGroupPlaceholder: 'E.g.: Controlled',

      groupLabel: 'Group',
      groupPlaceholder: 'Search for a group',

      register: 'Register',
      signingUp: 'Signin up',

      edit: 'Edit',
      editing: 'Editing',

      errorSubGroupNameExist: 'A Subgroup with that name already exists',
      errorNameRequired: 'Name is required',
      errorGroupRequired: 'Group is required',
    },
  },
  create: {
    titleInformation: 'Information',
    financeTitle: 'Finance',
    productName: 'Product Name',
    descriptionLabel: 'Label Description',
    descriptionScientific: 'Scientific Description',

    productCode: 'Product code',

    class: 'Class',
    classPlaceholder: 'Select a class',

    group: 'Group',
    groupPlaceholder: 'Select a group',

    subGroup: 'Subgroup',
    subGroupPlaceholder: 'Select a sub-group',

    provider: 'Provider',
    providerPlaceholder: 'Search a provider',
    providerTooltip:
      'Inform the supplier only if the product is exclusive to that supplier.',

    batchControlTooltip: 'Activate if the products stock balance is per batch.',
    qualityControlTooltip:
      'Activate if the product needs to carry out quality control after entry through the invoice.',
    continueUseTooltip:
      'Activate if the product is for continuous use or prolonged treatment.',
    tagTooltip:
      'Activate if you want to issue the products laboratory label after receipt of this product through the invoice.',

    measure: 'Unit of Measure',
    measurePlaceholder: 'Select a unit of measure',

    partControl: 'Part control',
    qualityControl: 'Quality control',

    continueUse: 'Continue use',
    tag: 'Tag',

    costPrice: 'Cost Price',
    costPricePlaceholder: 'Eg: BRL 10.00',

    profit: 'Profit (%)',
    profitPlaceholder: 'Eg: 60%',

    sellPrice: 'Sell Price',
    sellPricePlaceholder: 'Eg: BRL 10.00',

    save: 'Save',
    saveLoading: 'Saving',
    register: 'Register',
    registerLoading: 'Signing up',

    cancel: 'Cancel',
    errors: {
      requiredName: 'Name is required',
      requiredProductClass: 'Class is required',
      requiredGroupdId: 'Group is required',
      requiredSubGroupdId: 'Subgroup is required',
      requiredMeasure: 'Measure is required',

      requiredCostPrice: 'Cost Price is required',
      requiredProfit: 'Profit is required',
      requiredSellPrice: 'Sell price is required',

      errorNameExist: 'A product with that name already exists',
      errorProductGroupExist: 'A product group with that name already exists',
      errorProductSubGroupExist:
        'A product subgroup with that name already exists',
      errorSubGroupExist: 'A subgroup with that name already exists',
      errorMolecularBase:
        'Value cannot be greater than the Molecular Weight of the Salt',
    },

    success: {
      productCreated: 'Product created!',
      productDeleted: 'Product deleted!',
      productGroupEdited: 'Edited product group',
    },

    additionalInformation: 'Additional Information',
    dcb: 'DCB',
    dcbPlaceholder: 'Select a DCB',
    cas: 'CAS',
    casPlaceholder: 'Select a CAS',
    unitPrescription: 'Unit Prescription',
    componentType: 'Component Type',
    unitPrescriptionPlaceholder: 'Select a unit of prescription',
    daysValidity: 'Days of Validity',
    baseMolecularWeight: 'Base Molecular Weight',
    saltMolecularWeight: 'Salt Molecular Weight',
    equivalenceFactor: 'Equivalence Factor',
    valence: 'Valence',
    weighingToleranceBelow: 'Weighing Tolerance Below',
    weighingToleranceAbove: 'Weighing Tolerance Above',
    onlyLab: 'Only Laboratory',
    onlyDiluted: 'Only Diluted',
    gastroresistantCapsule: 'Gastroresistant Capsule',
    monodrug: 'Monodrug',
    pellets: 'Pellets',
    excipient: 'Excipient',
    qsp: 'QSP',
    storageLabelNotes: 'Storage Label Notes',
    storageLabelNotesPlaceholder: 'Notes on storage label',

    infoForFormCalc: 'Information for Formula Calculation',
    infoForFormCalcTooltip:
      'Enter information for calculation if there are no batches in use',
    density: 'Density',
    supplierDilution: 'Supplier Dilution(%)',
    supplierDilutionFactor: 'Supplier Dilution Factor',
    concentrationWater: 'Water Concentration (%)',
    concentrationWaterFactor: 'Water Concentration Factor',
    supplierDilutionTooltip:
      "Dilution percentage reported in the Supplier's Certificate of Analysis. This value is typically between 1% and 100%.",
    supplierDilutionFactorTooltip:
      "Factor resulting from the Supplier's Dilution. Example: If the Supplier Dilution is 40%, the Dilution Factor will be 2.5, which is 100 / 40.",
    concentrationWaterTooltip:
      "Water Concentration/Content reported in the Supplier's Certificate of Analysis. This value is typically between 1% and 17%.",
    concentrationWaterFactorTooltip:
      'Factor resulting from the Water Concentration. Example: If the Water Concentration is 8.5%, the Water Concentration Factor will be 1.093, which is 100 / (100 - 8.5).',

    originalDensityTooltip: 'Original Density: ',
    originalSupplierDilutionTooltip: 'Original Supplier Dilution: ',
    originalSupplierDilutionFactorTooltip:
      'Original Supplier Dilution Factor: ',
    originalConcentrationWaterTooltip: 'Original Water Concentration: ',
    originalConcentrationWaterFactorTooltip:
      'Original Water Concentration Factor: ',
    originalAlternativeUnit1: 'Original Alternative Unit 1: ',
    originalAlternativeUnit2: 'Original Alternative Unit 2: ',

    tooltips: {
      molecularBase:
        'Molecular Weight of the Base must be less than or equal to the Molecular Weight of the Salt.',
      equivalenceFactor:
        'Equivalence Factor is equal to dividing the Molecular Weight of the Salt by the Molecular Weight of the Base.',
      valence: 'Enter the valence for products prescribed in mEq.',
      onlyLab:
        'Activate if the product is used only in the laboratory and will not be available for prescription.',
      gastroResistantCapsule:
        'Activate if the product is to be used exclusively in gastroresistant capsules.',
      excipient: 'Activate if the product is an excipient.',
      onlyDiluted:
        'Activate if the product is to be used exclusively diluted, i.e., products with a low therapeutic index.',
      pellets: 'Activate if the product is pellets.',
      qsp: 'Activate if the product is preferably used as QSP.',
      monodrug: 'Activate if the product cannot be used together with others.',
    },

    capsuleType: 'Capsule Type',
    capsuleNumber: 'Capsule Number',
    capsuleColor: 'Capsule Color',
    capsuleTypePlaceholder: 'Select the capsule type',
    capsuleNumberPlaceholder: 'Select the capsule number',
    capsuleColorPlaceholder: 'Search for capsule colors',

    packingClassification: 'Packaging Classification',
    packingClassificationPlaceholder: 'Select packaging classification',
    volume: 'Volume',
    associateCapsules: 'Associate Capsule Type',
    associateCapsulesPlaceholder: 'Capsule number',
    quantity: 'Quantity',
    associatePackings: 'Associate Packagings',
    associatePackingsPlaceHolder: 'Search for a packaging',

    capsuleSize: 'Capsule Size',
    capsuleSizePlaceholder: 'Select a capsule size',
    capsuleSizeTooltip:
      'The capsule size will be used as a reference for choosing the jar.',
    product: 'Product',
    productPlaceHolder: 'Search for a product',
  },

  edit: {
    status: {
      inactivate: 'Inactivate',
      confirmation:
        'Are you sure you want to deactivate the product {{description}}',
    },
  },

  incompatible: {
    incompatibleProduct: 'Incompatible Product',
    incompatibleProducts: 'Incompatible Products',
    levelIncompatibility: 'Level of Incompatibility',
    newIncompatibleProduct: 'New Incompatible Product',
    searchForIncompatibleProductPlaceholder:
      'Search for an incompatible product',

    product: 'Product',
    registerIncompatibleProductTitle:
      'Register of Incompatible Products | Information',
    description: 'Description',

    productIncompatiblePlaceholder: 'Search for a product',
    levelIncompatibilityPlaceholder: 'Select',
    descriptionPlaceholder: 'Enter the description of the incompatibility',
    justWarn: 'Just Warning',
    passwordLiberation: 'Password Liberation',
    block: 'Block',

    editSuccess: 'Incompatible product edited successfully',
    originalIncompatibleLevel: 'Original Incompatibility Level: ',
    originalIncompatibleProduct: 'Original Incompatible Product: ',
    originalDescription: 'Original Description: ',
  },

  synonym: {
    synonym: 'Synonym',
    equivalenceFactor: 'Equivalence Factor',
    correctionPercentage: 'Correction Percentage',
    newSynonym: 'New Synonym',
    labelDescription: 'Label Description',
    synonymPlaceholder: 'Enter the synonym',
    descriptionPlaceholder: 'Enter the label description',
    searchSynonymPlaceholder:
      'Search by synonym, equivalence factor or correction percentage',

    registerSynonymTitle: 'Synonym Registration | Information',
    editSuccess: 'Synonym edited successfully',
    originalSynonym: 'Original synonym: ',
    originalEquivalenceFactor: 'Original equivalence factor: ',
    originalCorrectionPercentage: 'Original Correction Percentage: ',
    originalLabelDescription: 'Original Label Description: ',

    thereIsOneSynonymRegisteredWithThisName:
      'There is already a synonym registered with that name',
    theSynonymCannotHaveTheSameNameAsTheRegisteredProduct:
      'The Synonym cannot have the same name as the registered product',
  },

  diluted: {
    dilutedProducts: 'Diluted Products',
    pharmaceuticalForm: 'Pharmaceutical Form',
    minimalDosage: 'Minimal Dosage',
    maximalDosage: 'Maximal Dosage',

    newDilutedProduct: 'New Dilution',
    newDilutedProductTitle: 'Diluted Products Registration | Information',
    searchForDilutedPlaceholder:
      'Search by dosage form, minimum dosage, maximum dosage or dilution',
    searchForPharmaceuticalForm: 'Search for a pharmaceutical form',
    originalPharmaceuticalForm: 'Original Pharmaceutical Form: ',
    originalMinimalDosage: 'Original Minimum Dosage: ',
    originalMaximalDosage: 'Original Maximum Dosage: ',
    originalDilution: 'Original Dilution: ',
    originalUnit: 'Original Unit: ',

    editSuccess: 'Diluted product edited successfully',
    ratio: 'Ratio',

    errorsCannotCreateModal: {
      cannotCreateModal7: {
        title: 'Unable to register diluted product',
        description:
          'The product {{product}} already has pharmaceutical forms registered with minimum and maximum dosage.',
        warning:
          'To register all pharmaceutical forms with any dosage, remove the existing diluted products.',
      },
    },
  },

  associated: {
    associatedProduct: 'Associated Product',
    pharmaceuticalForm: 'Pharmaceutical Form',
    associatedQuantity: 'Associated Quantity',

    searchForAssociatedProductPlaceholder: 'Search for an associated product',

    newAssociatedProduct: 'New Associated Product',

    pharmaceuticalFormTooltip:
      'When the product is associated regardless of the Pharmaceutical Form, this field should not be filled',
    minimalDosageTooltip: 'Minimum dosage to associate the product',
    maximalDosageTooltip: 'Maximum dosage to associate the product',
    associatedQuantityTooltip: 'Quantity associated with the product',
    quantityRelationType: 'Relation Type',
    accumulate: 'Accumulate',

    selectReletionShip: 'Select the relationship type',
    select: 'Select',

    accumulateTooltip:
      "This option is used when the associated product is also associated with another product in the formula. 'Yes' option sums the associated quantities in both products, and 'No' option will keep only the greater associated quantity",
    relationShipTooltip:
      'Whether the associated quantity refers to the quantity of the product or the quantity of the formula',

    quantityProduct: 'Quantity over Product',
    quantityForm: 'Quantity over Formula',

    yes: 'Yes',
    no: 'No',

    editSuccess: 'Associated product edited successfully',

    originalAssociatedProduct: 'Original Associated Product: ',
    originalPharmaceuticalForm: 'Original Pharmaceutical Form: ',
    originalMinimalDosage: 'Original Minimal Dosage: ',
    originalMaximalDosage: 'Original Maximal Dosage: ',
    originalAssociatedQuantity: 'Original Associated Quantity: ',
    originalRelationship: 'Original Relationship Type: ',
    originalAcumulate: 'Original Value: ',
  },

  technicalInformation: {
    associated: 'Associated',
    diluted: 'Diluted',
    incompatible: 'Incompatible',
    synonym: 'Synonyms',
  },
};

export const productsPT = {
  common: {
    product: 'Produto',
    products: 'Produtos',
    subGroup: 'Subgrupo',
    subGroups: 'Subgrupos',
  },

  toolTips: {
    products: {
      nameOfProduct: 'Nome do produto original:',
      labelDescription: 'Descrição de rótulo original:',
      class: 'Classe original:',
      nameOfGroup: 'Grupo original:',
      nameOfSubGroup: 'Subgrupo original:',
      unitStock: 'Unidade de estoque original:',
      provider: 'Fornecedor original:',
      enabled: 'Ativado',
      disabled: 'Desativado',
      originalValue: 'Valor original:',
      costValue: 'Valor de custo original:',
      profitMargin: 'Margem de lucro original:',
      saleValue: 'Valor de venda original:',

      originalDcb: 'DCB original: ',
      originalCas: 'CAS original: ',
      originalUnitPrescription: 'Unidade de Prescrição original: ',
      originalComponentType: 'Tipo de Componente original: ',
      originalValidateDays: 'Dias de Validade original: ',
      originalBaseMolecularWeight: 'Peso Molecular da Base original: ',
      originalSaltMolecularWeight: 'Peso Molecular do Sal original: ',
      originalEquivalenceFactor: 'Valência original: ',
      originalToleranceWeighingBelow: 'Tolerância Pesagem Abaixo original: ',
      originalToleranceWeighingAbove: 'Tolerância Pesagem Acima original: ',
      originalObservations: 'Observações do Rótulo de Armazenagem original: ',
      empty: 'Vazio',

      originalPackingClassification: 'Classificação da Embalagem original: ',
      originalVolume: 'Volume original: ',

      originalCapsuleSize: 'Tamanho da Cápsula original: ',

      copyName: 'Copiar nome do produto para descrição do rótulo',
    },
    subGroups: {
      nameOfSubGroup: 'Nome do Subgrupo original:',
      nameOfGroup: 'Grupo original:',
    },
    groups: {
      nameOfGroup: 'Nome do grupo original:',
    },
  },

  modal: {
    products: {
      title: 'Não é possível desativar o controle de lote.',
      theProduct: 'O produto ',
      amount: 'tem quantidade em estoque e por isso ',
      desative: 'não é possível desativar o controle de lote.',
      warningFooter:
        'Para desativar o controle de lote, esvazie os lotes ativos do sistema criando um ajuste de saldo.',
    },
    cannotChangeClassType: {
      title: 'Não é possível alterar a classe do produto',
      body: 'Não é possível editar a classe do produto {{product}}!',
      footer: 'Esse produto já teve movimentação dentro do sistema e por isso não é possível a sua edição.', 
    },
  },

  productList: {
    searchPlaceholder: 'Pesquise por um produto',
    tableTitle: {
      code: 'Código',
      productName: 'Nome do Produto',
      class: 'Classe',
      group: 'Grupo',
      subGroup: 'Subgrupo',
      saleValue: 'Valor de Venda',
      costValue: 'Valor de custo',
      status: 'Status',
    },
    newButton: 'Novo Produto',
    stockBalance: {
      searchPlaceHolder: 'Pesquise por um local de estoque',
      code: 'Código',
      placeName: 'Nome do local',
      company: 'Empresa',
      availableQuantity: 'Quantidade disponível',
    },
    productMessage: {
      searchPlaceHolder: 'Pesquise por uma menssagem de produto',
      productMessage: 'Mensagem do Produto',
      exhibition: 'Exibição',

      exhibitionType: {
        displaysSale: 'Venda',
        displayLabel: 'Rótulo',
        displaysWeighingSheet: 'Ficha de Pesagem',
        displayLabeling: 'Rotulagem',
      },

      modalRemoveProductLinkedMessage: {
        messageTitle: ' a mensagem do produto',
        messageStartBody:
          'Você tem certeza que deseja remover a mensagem do produto:',
        messageWarning:
          'A mensagem será removida apenas desse produto, ela ainda continuará no sistema de Mensagens do Produto.',
      },

      modalProductLinkedMessage: {
        messageTitle: 'Nova Mensagem do Produto',
        placeholderMessageExisting: 'Pesquise por uma mensagem',
        placeholderNewMessage: 'Cadastre uma nova mensagem do produto',

        messageSystemWarning: 'Exibir aviso no sistema',
        messagePrintWarning: 'Exibir na impressão',
        checkboxDisplay: {
          checkBox1: 'Venda',
          checkBox2: 'Rotulagem',
          checkBox3: 'Ficha de pesagem',
          checkBox4: 'Rótulo',
        },
        newMessageDropdown: 'Nova Mensagem',
      },

      raddioButtonMessageTypes: {
        messageExisting: 'Selecionar uma mensagem existente',
        createNewMessage: 'Cadastrar nova mensagem',
      },

      buttonLinkedProductMessage: 'Vincular Nova Mensagem',
    },
  },
  groupList: {
    tableTitle: {
      description: 'Nome',
      subGroups: 'Subgrupos',
      products: 'Produtos',
    },
    placeholder: 'Pesquise por um grupo',
    newButton: 'Novo Grupo',
    modal: {
      title: 'Cadastro de grupo',

      label: 'Nome do grupo',
      placeholder: 'Ex: Matérias-primas',

      okButton: 'Cadastrar',

      errorGroupNameExist: 'Já existe um grupo com esse nome',
    },
  },
  subGroupList: {
    tableTitle: {
      description: 'Nome',
      groups: 'Grupo',
      products: 'Produtos',
    },
    placeholder: 'Pesquise por um subgrupo',
    newButton: 'Novo Subgrupo',
    goToGroup: 'Ir para Grupo',
    edit: {
      name: 'Nome do Subgrupo',
      group: 'Grupo',

      success: 'Subgroup editado com sucesso',
    },
    deleted: 'Subgrupo deletado',

    modal: {
      title: 'Cadastro de subgrupo',

      subGroupLabel: 'Nome do subgrupo',
      subGroupPlaceholder: 'Ex: Controlados',

      groupLabel: 'Grupo',
      groupPlaceholder: 'Procure por um grupo',

      register: 'Cadastrar',
      signingUp: 'Cadastrando',

      edit: 'Editar',
      editing: 'Editando',

      errorSubGroupNameExist: 'Já existe um subgrupo com esse nome',
      errorNameRequired: 'Nome é obrigatório',
      errorGroupRequired: 'Grupo é obrigatório',
    },
  },
  create: {
    titleInformation: 'Informações',
    financeTitle: 'Financeiro',
    productName: 'Nome do Produto',
    descriptionLabel: 'Descrição do Rótulo',
    descriptionScientific: 'Descrição Científica',

    productCode: 'Código do produto',

    class: 'Classe',
    classPlaceholder: 'Selecione uma classe',

    group: 'Grupo',
    groupPlaceholder: 'Selecione um grupo',

    subGroup: 'Subgrupo',
    subGroupPlaceholder: 'Selecione um subgrupo',

    provider: 'Fornecedor',
    providerPlaceholder: 'Procure por um fornecedor',
    providerTooltip:
      'Informe o fornecedor somente se o produto for exclusivo desse fornecedor.',

    batchControlTooltip: 'Ativar se o saldo de estoque do produto é por lote.',
    qualityControlTooltip:
      'Ativar se o produto precisa realizar o controle de qualidade após a entrada através da nota fiscal.',
    continueUseTooltip:
      'Ativar se o produto for de uso contínuo ou tratamento prolongado.',
    tagTooltip:
      'Ativar se deseja emitir a etiqueta de laboratório do produto após os recebimentos desse produto através da nota fiscal.',

    measure: 'Unidade de Estoque',
    measurePlaceholder: 'Selecione uma un. de medida',

    partControl: 'Controle de Lote',
    qualityControl: 'Controle de Qualidade',

    continueUse: 'Uso contínuo',
    tag: 'Rótulo de Estoque',

    costPrice: 'Valor de Custo',
    costPricePlaceholder: 'Ex: R$10,00',

    profit: 'Margem de Lucro (%)',
    profitPlaceholder: 'Ex: 60%',

    sellPrice: 'Valor de Venda',
    sellPricePlaceholder: 'Ex: R$10,00',

    save: 'Salvar',
    saveLoading: 'Salvando',
    register: 'Cadastrar',
    registerLoading: 'Cadastrando',

    cancel: 'Cancelar',
    errors: {
      requiredName: 'Nome é obrigatório',
      requiredProductClass: 'Classe é obrigatório',
      requiredGroupdId: 'Grupo é obrigatório',
      requiredSubGroupdId: 'Subgrupo é obrigatório',
      requiredMeasure: 'Unidade de medida é obrigatório',

      requiredCostPrice: 'Valor de custo é obrigatório',
      requiredProfit: 'Margem de lucro é obrigatório',
      requiredSellPrice: 'Valor de venda é obrigatório',

      errorNameExist: 'Já existe um produto com esse nome',
      errorProductGroupExist: 'Já existe um grupo de produto com esse nome',
      errorProductSubGroupExist:
        'Já existe um subgrupo de produto com esse nome',
      errorSubGroupExist: 'Já existe um subgrupo com esse nome',
      errorMolecularBase:
        'Valor não pode ser maior que o Peso Molecular do Sal',
    },

    success: {
      productCreated: 'Produto criado!',
      productDeleted: 'Produto deletado!',
      productGroupEdited: 'Grupo de produto editado',
    },

    additionalInformation: 'Informações Adicionais',
    dcb: 'DCB',
    dcbPlaceholder: 'Selecione um DCB',
    cas: 'CAS',
    casPlaceholder: 'Selecione um CAS',
    unitPrescription: 'Unidade de Prescrição',
    componentType: 'Tipo de Componente',
    unitPrescriptionPlaceholder: 'Selecione uma un. de medida',
    daysValidity: 'Dias de Validade',
    baseMolecularWeight: 'Peso Molecular da Base',
    saltMolecularWeight: 'Peso Molecular do Sal',
    equivalenceFactor: 'Fator de Equivalência',
    valence: 'Valência',
    weighingToleranceBelow: 'Tolerância Pesagem Abaixo',
    weighingToleranceAbove: 'Tolerância Pesagem Acima',
    onlyLab: 'Somente Laboratório',
    onlyDiluted: 'Somente Diluído',
    gastroresistantCapsule: 'Cápsula Gastroresistente',
    monodrug: 'Monodroga',
    pellets: 'Pellets',
    excipient: 'Excipiente',
    qsp: 'QSP',
    storageLabelNotes: 'Observações do Rótulo de Armazenagem',
    storageLabelNotesPlaceholder: 'Observações sobre o rótulo de armazenagem',

    infoForFormCalc: 'Informações para Cálculo de Fórmulas',
    infoForFormCalcTooltip:
      'Inserir informações para cálculo caso não haja nenhum lote em uso',
    density: 'Densidade',
    supplierDilution: 'Diluição Fornecedor(%)',
    supplierDilutionFactor: 'Fator de  Diluição do Fornecedor',
    concentrationWater: 'Concentração de Água (%)',
    concentrationWaterFactor: 'Fator de Conc. de Água',
    supplierDilutionTooltip:
      'Percentual de Diluição informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 100%.',
    supplierDilutionFactorTooltip:
      'Fator resultante da Diluição do Fornecedor. Exemplo: Se a Diluição do Fornecedor é 40%, o Fator de Diluição será de 2,5, isto é 100 / 40.',
    concentrationWaterTooltip:
      'Concentração/Teor de Água informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 17%.',
    concentrationWaterFactorTooltip:
      'Fator resultante da Concentração de Água. Exemplo: Se a Concentração de Água for de 8,5%, o Fator de Concentração de Água será de 1,093, isto é 100 / (100 - 8,5).',

    originalDensityTooltip: 'Densidade original: ',
    originalSupplierDilutionTooltip: 'Diluição Fornecedor original: ',
    originalSupplierDilutionFactorTooltip:
      'Fator de Diluição Fornecedor original: ',
    originalConcentrationWaterTooltip: 'Concentração de Água original: ',
    originalConcentrationWaterFactorTooltip:
      'Fator de Concentração de Água original: ',
    originalAlternativeUnit1: 'Unidade Alternativa 1 original: ',
    originalAlternativeUnit2: 'Unidade Alternativa 2 original: ',

    tooltips: {
      molecularBase:
        'Peso Molecular da Base deve ser menor ou igual ao Peso Molecular do Sal.',
      equivalenceFactor:
        'Fator de Equivalência é igual a divisão do Peso Molecular do Sal pelo Peso Molecular da Base.',
      valence: 'Informe a Valência para produtos prescritos em mEq.',
      onlyLab:
        'Ativar se o produto for utilizado somente no laboratório e não estará disponível para prescrição.',
      gastroResistantCapsule:
        'Ativar se o produto for utilizado obrigatoriamente em cápsulas gastroresistentes.',
      excipient: 'Ativar se o produto for um excipiente.',
      onlyDiluted:
        'Ativar se o produto for utilizado obrigatoriamente diluído, isto é, produtos de baixo índice terapeutico.',
      pellets: 'Ativar se o produto for pellets.',
      qsp: 'Ativar se o produto for utilizado preferencialmente como QSP.',
      monodrug:
        'Ativar se o produto não puder ser utilizado juntamente com outros.',
    },

    capsuleType: 'Tipo de Cápsula',
    capsuleNumber: 'Número da Cápsula',
    capsuleColor: 'Cor da Cápsula',
    capsuleTypePlaceholder: 'Selecione o tipo de cápsula',
    capsuleNumberPlaceholder: 'Selecione o número da cápsula',
    capsuleColorPlaceholder: 'Pesquise por cores de cápsula',

    packingClassification: 'Classificação da Embalagem',
    packingClassificationPlaceholder:
      'Selecione uma classificação da embalagem',
    volume: 'Volume',
    associateCapsules: 'Associar tipo Cápsulas',
    associateCapsulesPlaceholder: 'Número da cápsula',
    quantity: 'Quantidade',
    associatePackings: 'Associar Embalagens',
    associatePackingsPlaceHolder: 'Pesquisar por uma embalagem',

    capsuleSize: 'Tamanho da Cápsula',
    capsuleSizePlaceholder: 'Selecione um tamanho de cápsula',
    capsuleSizeTooltip:
      'O tamanho da cápsula será utilizado como referência para a escolha do pote.',
    product: 'Produto',
    productPlaceHolder: 'Pesquise por um produto',
  },

  edit: {
    status: {
      inactivate: 'Inativar',
      confirmation:
        'Você tem certeza que deseja inativar o produto {{description}}',
    },
  },

  incompatible: {
    incompatibleProduct: 'Produto Incompatível',
    incompatibleProducts: 'Produtos Incompatíveis',
    levelIncompatibility: 'Nível de incompatibilidade',
    newIncompatibleProduct: 'Novo Produto Incompatível',
    searchForIncompatibleProductPlaceholder:
      'Pesquise por um produto incompatível',

    product: 'Produto',
    registerIncompatibleProductTitle:
      'Cadastro de Produtos Incompatíveis | Informações',
    description: 'Descrição',

    productIncompatiblePlaceholder: 'Pesquise por um produto',
    levelIncompatibilityPlaceholder: 'Selecione',
    descriptionPlaceholder: 'Insira a descrição da incompatibilidade',

    justWarn: 'Apenas Aviso',
    passwordLiberation: 'Liberação mediante Senha',
    block: 'Bloqueio',
    editSuccess: 'Produto incompatível editado com sucesso',

    originalIncompatibleLevel: 'Nível de Incompatibilidade original: ',
    originalIncompatibleProduct: 'Produto Incompatível original: ',
    originalDescription: 'Descrição original: ',
  },

  synonym: {
    synonym: 'Sinônimo',
    equivalenceFactor: 'Fator de Equivalência',
    correctionPercentage: 'Percentual de Correção',
    newSynonym: 'Novo Sinônimo',
    labelDescription: 'Descrição do Rótulo',
    synonymPlaceholder: 'Insira o sinônimo',
    descriptionPlaceholder: 'Insira a descrição do rótulo',
    searchSynonymPlaceholder:
      'Pesquise por sinônimo, fator de equivalência ou percentual de correção',

    registerSynonymTitle: 'Cadastro de Sinônimos | Informações',
    editSuccess: 'Sinônimo editado com sucesso',
    originalSynonym: 'Sinônimo original: ',
    originalEquivalenceFactor: 'Fator de Equivalência original: ',
    originalCorrectionPercentage: 'Percentual de correção original: ',
    originalLabelDescription: 'Descrição do rótulo original: ',

    thereIsOneSynonymRegisteredWithThisName:
      'Já existe um sinônimo cadastrado com esse nome',
    theSynonymCannotHaveTheSameNameAsTheRegisteredProduct:
      'O Sinônimo não pode ter o mesmo nome do produto cadastrado',
  },

  diluted: {
    dilutedProducts: 'Produtos Diluídos',
    pharmaceuticalForm: 'Forma Farmacêutica',
    minimalDosage: 'Dosagem Mínima',
    maximalDosage: 'Dosagem Máxima',

    newDilutedProduct: 'Nova Diluição',
    newDilutedProductTitle: 'Cadastro de Produtos Diluídos | Informações',
    searchForPharmaceuticalForm: 'Pesquise por uma forma farmacêutica',
    searchForDilutedPlaceholder:
      'Pesquise por forma farmacêutica, dosagem mínima, dosagem máxima ou diluição',
    originalPharmaceuticalForm: 'Forma Farmacêutica Original: ',
    originalMinimalDosage: 'Dosagem mínima original: ',
    originalMaximalDosage: 'Dosagem máxima original: ',
    originalUnit: 'Unidade original: ',
    originalDilution: 'Diluição original: ',

    editSuccess: 'Produto diluído editado com sucesso',

    ratio: 'Razão',

    errorsCannotCreateModal: {
      cannotCreateModal7: {
        title: 'Não foi possível cadastrar o produto diluído',
        description:
          'O produto {{product}} já possui formas farmacêuticas cadastradas com dosagem mínima e máxima.',
        warning:
          'Para cadastrar todas as formas farmacêuticas com qualquer dosagem, remova os produtos diluídos existentes.',
      },
    },
  },

  associated: {
    associatedProduct: 'Produto Associado',
    pharmaceuticalForm: 'Forma Farmacêutica',
    associatedQuantity: 'Quantidade Associada',

    searchForAssociatedProductPlaceholder: 'Pesquise por um produto associado',

    newAssociatedProduct: 'Novo Produto Associado',

    pharmaceuticalFormTooltip:
      'Quando o produto for associado independente da Forma Farmacêutica, esse campo não deve ser preenchido',
    minimalDosageTooltip: 'Dosagem mínima para associar o produto',
    maximalDosageTooltip: 'Dosagem máxima para associar o produto',
    associatedQuantityTooltip: 'Quantidade associada ao produto',
    quantityRelationType: 'Tipo de Relação',
    acumulate: 'Acumula',

    selectReletionShip: 'Selecione o tipo de relação',
    select: 'Selecione',

    acumulateTooltip:
      "Esta opção é utilizada quando o produto associado está associado também a outro produto da fórmula. A opção 'Sim' soma as quantidades associadas nos dois produtos, e a opção 'Não' manterá apenas a maior quantidade associada",
    relationShipTooltip:
      'Se a quantidade que será associada refere-se a quantidade do produto ou a quantidade da fórmula',

    quantityProduct: 'Quantidade sobre Produto',
    quantityForm: 'Quantidade sobre Fórmula',

    yes: 'Sim',
    no: 'Não',

    editSuccess: 'Produto associado editado com sucesso',

    originalAssociatedProduct: 'Produto Associado Original: ',
    originalPharmaceuticalForm: 'Forma Farmacêutica Original: ',
    originalMinimalDosage: 'Dosagem Mínima Original: ',
    originalMaximalDosage: 'Dosagem Máxima Original: ',
    originalAssociatedQuantity: 'Quantidade Associada Original: ',
    originalRelationship: 'Tipo de Relação Original: ',
    originalAcumulate: 'Valor Original: ',
  },

  technicalInformation: {
    associated: 'Associados',
    diluted: 'Diluídos',
    incompatible: 'Incompatíveis',
    synonym: 'Sinônimos',
  },
};
