import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_LOTS } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import { IInvoiceGetLotResponse } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';
import { useParams } from 'react-router';

export const useGetLots = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lots } = useControllerQueryApiHook<IInvoiceGetLotResponse>({
    uniqId: FETCH_LIST_LOTS,
    entityApi: InvoiceAPI.getInvoiceLots,
    externalIds: [externalId],
  });

  return {
    lots,
  };
};
