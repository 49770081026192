import { Icon, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { TFunction } from 'react-i18next';

import styles from './FooterDescription.module.scss';

interface IFooterDescription {
  expectedDuration?: number;
  t: TFunction<'translation', undefined>;
}

export const FooterDescription: FC<IFooterDescription> = ({
  expectedDuration,
  t,
}) => (
  <Divflex className={styles['info-description']}>
    <Icon icon="calendar" size="SM" color="text-300" />
    <Text
      type="link"
      children={
        <ReactMarkdown
          components={{
            strong: ({ node, ...props }) => (
              <strong
                className={
                  props.children.includes(`${expectedDuration} dias`)
                    ? styles['highlighted-text']
                    : undefined
                }
                {...props}
              />
            ),
          }}
        >
          {t(
            `saleOrder.editOrder.SaleOrder.addManipulatedRecipe.details.resell.expectedDuration`,
            {
              info: expectedDuration
                ? `**${expectedDuration} dias**`
                : `${'Preencha as informações acima.'}`,
            }
          )}
        </ReactMarkdown>
      }
      color="text-300"
    />
  </Divflex>
);
