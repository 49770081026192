import { QueryFunctionContext } from '@tanstack/react-query';
import {
  IGetPosology,
  IPosologyList,
} from '../../Interfaces/response/Posology/IPosologyResponse';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  ICreatePosology,
  IDeletePosologyRequest,
  IEditPosology,
  IPutPosologyStatusRequest,
} from '../../Interfaces/request/Posology/IPosologyRequest';

export class PosologyAPI {
  public static async createPosology(body: ICreatePosology) {
    const res = await Http.fetcher('/pharma/producao/v1/Posologia', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getPosologyList(
    context: QueryFunctionContext
  ): Promise<IPosologyList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IPosologyList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/Posologia/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getPosology(
    context: QueryFunctionContext
  ): Promise<IGetPosology> {
    return await Http.fetcher<IGetPosology>(
      `/pharma/producao/v1/Posologia/${context.queryKey[1]}`
    );
  }

  public static async deletePosology(body: IDeletePosologyRequest) {
    const res = await Http.fetcher(`/pharma/producao/v1/Posologia`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async putPosologyStatus(body: IPutPosologyStatusRequest) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/Posologia/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async isPosologyNameAlreadyRegistred(body: {
    descricao?: string;
    formaFarmaceuticaExternalId?: string;
    posologiaExternalId?: string;
  }): Promise<boolean> {
    if (!body.descricao || !body.formaFarmaceuticaExternalId) return false;

    try {
      const result = await Http.fetcher(
        '/pharma/producao/v1/Posologia/ValidarDescricao',
        {
          method: 'POST',
          body: JSON.stringify({ ...body }),
        }
      );

      return result.success;
    } catch(e) {
      return false;
    }

  }

  public static async editPosology(body: IEditPosology) {
    const res = await Http.fetcher('/pharma/producao/v1/Posologia', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }
}
