import * as yup from 'yup';
import i18n from '@/Components/i18n';

export const EditSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
  nome: yup
    .string()
    .test(
      'empty',
      i18n.t('userGroups.editGroups.errors.requiredName'),
      (name) => !!name && name?.replace(/\s/g, '').length > 0
    )
    .required(i18n.t('userGroups.editGroups.errors.requiredName'))
    .test(
			'checkDuplications',
			t('common.error.userGroupNameRegistred'),
			(value) => {
				return new Promise((resolve) => {
					validateName(value, (success) => {
						resolve(success);
					});
				});
			}
		),
  descricao: yup
    .string()
    .max(200, i18n.t('userGroups.editGroups.errors.maxDescriptions')),
});
