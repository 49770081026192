import { useMemo } from 'react';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

export const useUnitaryDiscountMemorizations = ({
  formValues,
  index,
  setTotal,
  getTotalValue,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const discountTooltipValue = useMemo(() => {
    return formValues.discountType === DiscountTypes.porcentage
      ? (formValues.unitValue * formValues.discountValue) / 100
      : (formValues.discountValue * 100) / formValues.unitValue;
  }, [formValues]);

  const discountValue =
    formValues.discountType === DiscountTypes.porcentage
      ? (formValues.discountValue / 100) * formValues.unitValue
      : formValues.discountValue;

  const title =
    formValues.unitValue < discountValue
      ? t('purchasing.purchaseOrder.create.errors.discountCannotBeGreater')
      : formValues.discountType === DiscountTypes.porcentage
      ? currencyFormater(discountTooltipValue)
      : `${discountTooltipValue.toFixed(2)}%`;

  const label =
    index === 0
      ? t('purchasing.purchaseOrder.create.productsCardField.unitaryDiscount')
      : undefined;

  const dropdownLeft = {
    name: `pedidoCompraItens.${index}.tipoDesconto`,
    onChange: (x: string) => {
      form.setFieldValue(`pedidoCompraItens.${index}.tipoDescontoId`, +x);
      setTotal(getTotalValue({ newDiscountType: parseInt(x) }));
    },
  };

  return {
    discountTooltipValue,
    title,
    label,
    dropdownLeft,
    discountValue,
  };
};
