import { FC } from 'react';
import { ModalFuncProps } from 'antd';
import { BodyModal } from '@/Components/Modal';
import { Footer as ModalFooter } from '@/Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import styles from './CannotChangeClassTypeModal.module.scss';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          <div style={{ padding: '20px' }}>
            <Divflex
              style={{
                flexDirection: 'column',
              }}
            >
              <Text 
                color="text-50" 
                type="ui-tiny-content"
                className={styles['text']}
              >
                <ReactMarkdown>
                  {t('products.modal.cannotChangeClassType.body', {
                    product: `**${content[0]}**`,
                  })}
                </ReactMarkdown>
              </Text>
              <Text 
                color="text-50" 
                type="ui-tiny-content"
                className={styles['text']}
              >
                <ReactMarkdown>
                  {t('products.modal.cannotChangeClassType.footer')}
                </ReactMarkdown>
              </Text>
            </Divflex>
          </div>
        }
      />
    </>
  );
};

const Title: FC<ICommonError> = () => {
  const { t } = useTranslation();

  return t('products.modal.cannotChangeClassType.title');
};

const Footer: FC<{
  params: { externalId: string };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      cancelButtonName={t('common.close')}
      onCancelClick={() => instance.destroy()}
      withoutOkButton
    />
  );
};

export const CannotChangeClassTypeModal: (
  error: ICommonError,
  params: { externalId: string },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
  width: 400,
});