import { IUseFunctions } from "./interfaces";
import { App } from "antd";
import { handleModalError } from "@/Utils/ErrorCodeUtils/ErrorHandlingUtils";
import { cannotDeleteUserGroupsWithMovementsInTheSystemProps } from "@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalUserGroups";
import { useTranslation } from "react-i18next";

export const useDeleteModalFunctions = ({ 
  userGroup,
  deleteUserGroup,
  setVisible 
}: IUseFunctions) => {
  const app = App.useApp();
  const { t } = useTranslation();
  
  const onDelete = () => {
    deleteUserGroup.mutateAsync(
      [userGroup.externalId], 
      {
        onError: handleModalError(
          app,
          cannotDeleteUserGroupsWithMovementsInTheSystemProps(t)
        )
      }
    );

    setVisible(false);
  };

  return { onDelete };
};