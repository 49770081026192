import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { UserGroupAPI } from '@/Data/API/Accountmanager/UserGroupApi';
import {
  GET_USER_GROUP_TYPE,
  PATCH_NAME_AND_DESCRIPTION_USER_GROUP,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserGroupKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseCallAPI } from './interfaces';

export const useDetailFormCallAPI = ({ onDisabledForm }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const editUserGroup = useControllerMutateApiHook({
    uniqId: PATCH_NAME_AND_DESCRIPTION_USER_GROUP,
    entityApi: UserGroupAPI.patchNameAndDescription,
    options: {
      onSuccess: () => {
        onDisabledForm(true);
        queryClient.invalidateQueries([GET_USER_GROUP_TYPE]);
      },
    },
  });

  return { editUserGroup };
};
