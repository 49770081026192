import { Button, Dropdown, Icon, Text } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useListSupplyPlace } from '../hooks/useListSupplyPlace';
import { useTranslation } from 'react-i18next';
import { useListGroups } from '../hooks/useListGroups';
import { useFormikContext } from 'formik';
import { useListSubgroups } from '../hooks/useListSubgroups';
import React, { useEffect, useState } from 'react';
import { useFormRows } from '@/Hooks/UtilHooks/useFormRows';

interface ISupplyPlaceRowProps {
  edit: boolean;
}

export const SupplyPlaceRow: React.FC<ISupplyPlaceRowProps> = ({ edit }) => {
  const [isInitial, setIsInitial] = useState(true);

  const form: any = useFormikContext();
  const { addRow, arrayControl, removeRow, pushRow, removeArrayControlLast } =
    useFormRows({
      key: 'inventarioEspecificacao',
    });

  const { t } = useTranslation();
  const { fetchNewSupplyPlacePage, supplyPlaceData } = useListSupplyPlace();
  const { refetchPage, groupsData, fetchNewGroupsPage } = useListGroups();
  const { fetchNewSubgroupsPage, refetchPageSubGroup, subgroupsData } =
    useListSubgroups();

  useEffect(() => {
    if (form.values.inventarioEspecificacao.length > arrayControl.length) {
      const diff =
        form.values.inventarioEspecificacao.length - arrayControl.length;
      Array.from({ length: diff }).forEach(() => {
        pushRow();
      });
    }
  }, [form.values.inventarioEspecificacao]);

  useEffect(() => {
    if (isInitial && edit) {
      pushRow();
      setIsInitial(false);
    }

    if (!edit && !isInitial) {
      removeArrayControlLast();
      setIsInitial(true);
    }
  }, [isInitial, edit]);

  return (
    <div>
      {arrayControl.map((e, index) => {
        const supplyPlace = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].localEstoqueExternalId
          : null;
        const group = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].grupoExternalId
          : null;

        return (
          <div key={e}>
            <Row
              gutter={16}
              style={index !== 0 ? { display: 'none' } : undefined}
            >
              <Col span={14}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.supplyPlaces')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.groups')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.subgroups')}
                </Text>
              </Col>
            </Row>
            <FormBreakLine
              marginTop="10px"
              style={index !== 0 ? { display: 'none' } : undefined}
            />
            <Row gutter={16}>
              <Col span={14}>
                <Dropdown
                  name={`inventarioEspecificacao.${index}.localEstoqueExternalId`}
                  items={supplyPlaceData.supplyPlacesList}
                  placeHolder={t(
                    'supply.inventory.create.supplyPlacesPlaceholder'
                  )}
                  onScrollEnd={fetchNewSupplyPlacePage}
                  onChange={(value: string) => {
                    form.setFieldValue(
                      `inventarioEspecificacao.${index}.grupoExternalId`,
                      undefined
                    );
                    form.setFieldValue(
                      `inventarioEspecificacao.${index}.subGrupoExternalId`,
                      undefined
                    );
                    addRow(index);
                    refetchPage(value);
                  }}
                  disabled={!edit}
                />
              </Col>
              <Col span={4}>
                <Dropdown
                  name={`inventarioEspecificacao.${index}.grupoExternalId`}
                  disabled={!supplyPlace || !edit}
                  placeHolder={t('supply.inventory.create.groupsPlaceholder')}
                  items={groupsData}
                  onChange={(value: string) => {
                    form.setFieldValue(
                      `inventarioEspecificacao.${index}.subGrupoExternalId`,
                      undefined
                    );
                    addRow(index);
                    refetchPageSubGroup(supplyPlace, value);
                  }}
                  onScrollEnd={fetchNewGroupsPage}
                />
              </Col>
              <Col span={4}>
                <Dropdown
                  name={`inventarioEspecificacao.${index}.subGrupoExternalId`}
                  disabled={!group || !edit}
                  items={subgroupsData}
                  placeHolder={t(
                    'supply.inventory.create.subgroupsPlaceholder'
                  )}
                  onChange={() => {
                    addRow(index);
                  }}
                  onScrollEnd={fetchNewSubgroupsPage}
                />
              </Col>
              {edit && (
                <Col span={2}>
                  <Button
                    disabled={
                      index === form.values?.inventarioEspecificacao?.length
                    }
                    type="secondary"
                    onClick={() => {
                      removeRow(index);
                    }}
                  >
                    <Icon
                      style={{
                        cursor:
                          index === form.values?.inventarioEspecificacao?.length
                            ? 'not-allowed'
                            : 'default',
                      }}
                      icon="trash"
                      size="M"
                    />
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
    </div>
  );
};
