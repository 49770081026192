import React, { useState } from 'react';
import { Button, Form } from '@/Components';
import { useTranslation } from 'react-i18next';
import { InvoiceCreateCreateSchema } from './InvoiceCreate.schema';

import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { InfoSection } from './Sections/InfoSection';
import { ValueSection } from './Sections/ValueSection';
import { ProductSection } from './Sections/ProductSection';

import styles from './styles.module.scss';
import { history } from '@/Store';
import { ButtonDraft } from '@/Components/Button/ButtonDraft';
import { useCreateInvoice } from '@/Hooks/HooksServices/Purchase/Invoice/useCreateinvoice';
import { useFormatData } from './Hooks/useFormatData';
import { useQueryClient } from '@tanstack/react-query';
import { FETCH_LIST_INVOICE } from '@/ControllerApiHook/UniqIds/Invoice/InvoiceHooks';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useParams } from 'react-router';
import { DeleteDraftModal } from './components/DeleteDraftModel';
import { useGetInvoice } from './Hooks/useGetInvoice';
import { useConfigInitialValue } from './Hooks/useConfigInitialValue';
import { useCompareValues } from '@/Hooks/UtilHooks/useCompareValues.ts';
import { useUpdateDraft } from './Hooks/useUpdateDraft';
import { FETCH_GET_INVOICE } from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { useConfigChangeText } from './Hooks/useConfigChangeText';
import { useEnableDraft } from '../CreateRefact/Hooks/useEnableDraft';

const DraftInvoice: React.FC = () => {
  const { t } = useTranslation();
  const { createInvoice } = useCreateInvoice();
  const { updateInvoiceDraft } = useUpdateDraft();
  const { formatData } = useFormatData();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { externalId } = useParams<{ externalId: string }>();
  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);
  const { invoice } = useGetInvoice(externalId);
  const { configInvoice } = useConfigInitialValue();
  const initialValue = configInvoice(invoice);
  const { compareValues } = useCompareValues();
  const [firstRender, setFirstRender] = useState(true);
  const { configChangeText } = useConfigChangeText();
  const { enableDraft } = useEnableDraft();

  const handleSubmit = async (values: any) => {
    const valuesToPost = formatData(values);
    await createInvoice.mutateAsync(valuesToPost);
  };

  const handleSaveDraft = async (values: any) => {
    setFirstRender(false);
    const valuesToPost = formatData(values);
    const res = await updateInvoiceDraft.mutateAsync({
      ...valuesToPost,
      notaFiscalEntradaExternalId: externalId,
    });

    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
      queryClient.invalidateQueries([FETCH_GET_INVOICE]);
      dispatch(NotificationActions.setCurrentFormIsDirty(false));
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        schema={InvoiceCreateCreateSchema}
        initialValues={initialValue}
      >
        {(props) => (
          <>
            <ConnectedFocusError />
            <InfoSection />
            <ValueSection />
            <ProductSection />
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => history.push('/order/invoice')}
                />
                <Button
                  type="primary"
                  children={
                    createInvoice.isLoading
                      ? t('common.creating')
                      : t('common.create')
                  }
                  htmlType="submit"
                  loading={createInvoice.isLoading}
                />
              </div>

              <ButtonDraft
                type="secondary"
                htmlType="button"
                onClick={() => {
                  handleSaveDraft(props.values);
                }}
                disabled={!enableDraft(props.values)}
                children={
                  updateInvoiceDraft.isLoading
                    ? t('common.saving')
                    : t('common.saveDraft')
                }
                withStatusIcon={
                  !compareValues(props.values, initialValue).isEqual
                }
                withStatusMessage={configChangeText(
                  compareValues(props.values, initialValue).isEqual,
                  firstRender
                )}
                withDropdownEditButton={{
                  options: [
                    {
                      label: t('common.modalDraftDelete.title'),
                      icon: 'trash',
                      onClick: () => setOpenDeleteDraftModal(true),
                    },
                  ],
                }}
              />
            </div>
            <DeleteDraftModal
              invoiceId={externalId}
              visibleModal={openDeleteDraftModal}
              changeVisibleModal={(value) => setOpenDeleteDraftModal(value)}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default DraftInvoice;
