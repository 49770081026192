import { Avatar, Text, Icon } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import { history } from '@/Store';
import { FormikContextType, useFormikContext } from 'formik';
import { ICreateManipulatedRecipe } from '@/Data/Interfaces/request/Production/ManipulatedRecipe';
import { IGetSimplifiedClientResponse } from '@/Data/Interfaces/response/Person/Client';

import styles from './Title.module.scss';

interface ITitle {
  client?: IGetSimplifiedClientResponse;
}
export const Title: FC<ITitle> = ({ client }) => {
  const form: FormikContextType<ICreateManipulatedRecipe> = useFormikContext();
  return (
    <Divflex className={styles['container']}>
      <Divflex>
        <Avatar
          fisrtName={client?.nome}
          size="M"
          type="small-text-bold"
          color="white"
        />
        <div className={styles['description']}>
          <Text
            type="ui-sub-bold"
            color="text-50"
            children={client?.nome}
            maxWidth={188}
            id="patience-id"
          />
          <Text
            type="ui-tiny-content"
            color="text-300"
            children={`Cliente • #${client?.codigo}`}
          />
        </div>
      </Divflex>
      <div className={styles['link-icon']}>
        <Icon
          icon="external-link"
          size="M"
          color="text-300"
          onClick={() =>
            history.push(
              `/sales/customers/${form?.values?.pacienteExternalId}/details`
            )
          }
        />
      </div>
    </Divflex>
  );
};
