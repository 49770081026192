import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IAddAdressClientRequest,
  IAddContactClientRequest,
  IAddDocumentClientRequest,
  IChangeClientStatusRequest,
  ICreateClientRequest,
  IEditAdressClientRequest,
  IEditContactClientRequest,
  IEditDocumentClientRequest,
  IEditInfoClientRequest,
} from '../../Interfaces/request/Client/IClientRequest';
import {
  IGetClientAdressResponse,
  IGetClientResponse,
  IGetSimplifiedClientResponse,
  IListAddressClientResponse,
  IListClientResponse,
  IListDetailedClientResponse,
} from '@/Data/Interfaces/response/Person/Client';

export class ClientAPI {
  public static async createClient(body: ICreateClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async getClient(
    context: QueryFunctionContext
  ): Promise<IGetClientResponse> {
    return await Http.fetcher<IGetClientResponse>(
      `/pharma/pessoa/v1/Cliente/${context.queryKey[1]}`
    );
  }

  public static async listDetailedClient(
    context: QueryFunctionContext
  ): Promise<IListDetailedClientResponse> {
    return await Http.fetcher<IListDetailedClientResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Cliente/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listClient(
    context: QueryFunctionContext
  ): Promise<IListClientResponse> {
    return await Http.fetcher<IListClientResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Cliente/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async changeClientStatus(body: IChangeClientStatusRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/AtualizarStatus', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async deleteClient(body: { clientesExternalId: string[] }) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente', {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }

  public static async editInfoClient(body: IEditInfoClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async addContactClient(body: IAddContactClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/CadastrarContato', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async editContactClient(body: IEditContactClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/AtualizarContato', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async changeFavoriteContactClient(body: {
    prescritorContatoExternalId: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Cliente/AtualizarContatoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteContactClient(contactExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Cliente/RemoverContato/${contactExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async addAddressClient(body: IAddAdressClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/CadastrarEndereco', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async editAddressClient(body: IEditAdressClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/AtualizarEndereco', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async changeFavoriteAddressClient(body: {
    prescritorEnderecoExternalId?: string;
    clienteEnderecoExternalId?: string;
    principal: boolean;
  }) {
    return await Http.fetcher(
      '/pharma/pessoa/v1/Cliente/AtualizarEnderecoPrincipal',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteAddressClient(clientExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Cliente/RemoverEndereco/${clientExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async getClientAddress(
    context: QueryFunctionContext
  ): Promise<IGetClientAdressResponse | null> {
    if (!!context.queryKey[1])
      return await Http.fetcher<IGetClientAdressResponse>(
        `/pharma/pessoa/v1/Cliente/ObterEndereco/${context.queryKey[1]}`
      );
    else return null;
  }

  public static async addDocumentClient(body: IAddDocumentClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/CadastrarDocumento', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async editDocumentClient(body: IEditDocumentClientRequest) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente/AtualizarDocumento', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public static async deleteDocumentClient(documentExternalId: string) {
    return await Http.fetcher(
      `/pharma/pessoa/v1/Cliente/RemoverDocumento/${documentExternalId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public static async deleteClients(externalId: string[]) {
    return await Http.fetcher('/pharma/pessoa/v1/Cliente', {
      method: 'DELETE',
      body: JSON.stringify({ clientesExternalId: externalId }),
    });
  }

  public static async addressClientList(
    context: QueryFunctionContext
  ): Promise<IListAddressClientResponse> {
    return await Http.fetcher<IListAddressClientResponse>(
      Http.AddQueryParams(
        `/pharma/pessoa/v1/Cliente/ListarEndereco`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getSimplifiedClient(
    context: QueryFunctionContext
  ): Promise<IGetSimplifiedClientResponse | null> {
    if (!!context.queryKey[1])
      return await Http.fetcher<IGetSimplifiedClientResponse>(
        `/pharma/pessoa/v1/Cliente/ObterSimplificado/${context.queryKey[1]}`
      );
    else return null;
  }
}
