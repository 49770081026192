import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_INVENTORY,
  SAVE_PUT_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useEditInventory = () => {
  const queryClient = useQueryClient();

  const editInventory: any = useControllerMutateApiHook({
    uniqId: SAVE_PUT_INVENTORY,
    entityApi: InventoryAPI.savePutInventory,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: (x) => {
        queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
      },
    },
  });

  return { editInventory };
};
