import { useMemo } from 'react';
import { IIListUnitMeasurementAllData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { IUseMemorizations } from './interfaces';

export const useQuantityMemorizations = ({
  unitsMeasurementsDefault,
  listunitsMeasurementsPrescription,
  isUnitOfMeasurePrescriptionLoading,
  index,
}: IUseMemorizations) => {
  const unitMeasureIdDefault = unitsMeasurementsDefault?.unidadeEstoqueId;

  const unitOfMeasureDropdownPrescription = useMemo(() => (
    listunitsMeasurementsPrescription
    ? {
        name: 'unidadeMedidaId',
        loading: isUnitOfMeasurePrescriptionLoading,
        defaultValue: unitMeasureIdDefault,
        options:
          listunitsMeasurementsPrescription?.data?.map(
            (x: IIListUnitMeasurementAllData) => ({
              id: x.id,
              content: x.unidadeAbreviacao,
            })
          ) || [],
      }
    : undefined
  ), [listunitsMeasurementsPrescription, unitMeasureIdDefault, isUnitOfMeasurePrescriptionLoading]);

  const dropdownRight = useMemo(() => (
    {
      options: unitOfMeasureDropdownPrescription
        ? unitOfMeasureDropdownPrescription.options
        : [],
      name: `produtos.${index}.unidadeMedidaId`,
    }
  ),[unitOfMeasureDropdownPrescription, index]);

  return {
    dropdownRight,
  };
};
