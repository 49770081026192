import useMediaQuery from '@/Utils/CustomHooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useConfigColumnsSpacing } from '../hooks/useConfigColumnsSpacing';
import styles from './headerStyle.module.scss';
import { Col, Row } from 'antd';

interface IHeaderRowProps {
  showTechnicalInformation: boolean;
}

export const HeaderRow: React.FC<IHeaderRowProps> = ({
  showTechnicalInformation,
}) => {
  const { configColumnsSpacing } = useConfigColumnsSpacing();
  const { t } = useTranslation();
  const showDatesWidth = '2133px';
  const keepDatesOnScreen = useMediaQuery(`(min-width: ${showDatesWidth})`);
  const { firstColumn, restColumns } = configColumnsSpacing(
    keepDatesOnScreen,
    showTechnicalInformation
  );

  return (
    <Row wrap={false} gutter={[16, 0]} className={styles['headerRow']}>
      <Col span={firstColumn} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.product')}
        <span>*</span>
      </Col>
      <Col span={restColumns} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.quantity')}
        <span>*</span>
      </Col>
      <Col span={restColumns} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.lot')}
        <span>*</span>
      </Col>
      {(!showTechnicalInformation || keepDatesOnScreen) && (
        <>
          <Col span={restColumns} className={styles['headerItem']}>
            {t(
              'purchasing.invoice.registerLots.lotsCardHeader.manufacturingDate'
            )}
            <span>*</span>
          </Col>
          <Col span={restColumns} className={styles['headerItem']}>
            {t('purchasing.invoice.registerLots.lotsCardHeader.expiryDate')}
            <span>*</span>
          </Col>
        </>
      )}
    </Row>
  );
};
