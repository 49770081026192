import React, { CSSProperties, FC, ReactNode, useState } from 'react';
import { Color, Colors } from '../../Utils/ColorUtils';
import { Icon } from '../Icon';
import { Tooltip } from '..';
import { Field, FieldProps } from 'formik';

import styles from './Text.module.scss';

export type TextType =
  | 'heading1'
  | 'heading2'
  | 'heading3-bold'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'ui-main-content'
  | 'ui-main-semibold'
  | 'ui-main-bold'
  | 'ui-sub-content'
  | 'ui-sub-semibold'
  | 'ui-sub-bold'
  | 'paragraph'
  | 'paragraph2'
  | 'paragraph-bold'
  | 'paragraph2-bold'
  | 'ui-tiny-content'
  | 'ui-tiny-semibold'
  | 'ui-tiny-bold'
  | 'link'
  | 'small-text'
  | 'small-text-bold'
  | 'smaller-text-bold';

export interface IText {
  id?: string;
  type: TextType;
  children?: ReactNode;
  color?: Color;
  style?: CSSProperties;
  className?: string;

  onClick?: () => void;

  width?: number;
  maxWidth?: number;
  favoriteIcon?: boolean;

  withoutMarginBottom?: boolean;

  withStatusIcon?: boolean;
  withStatusIconRight?: boolean;
}

export const Text: FC<IText> = ({
  id,
  type,
  style,
  color,
  children,
  className,
  onClick,
  width,
  maxWidth,
  favoriteIcon,
  withoutMarginBottom,
  withStatusIcon,
  withStatusIconRight,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip title={children} showMe={showTooltip}>
      <div
        style={
          color
            ? {
                color: Colors[color],
                width: width,
                maxWidth: maxWidth,
                ...style,
              }
            : { width: width, maxWidth: maxWidth, ...style }
        }
        className={`${styles['Text']} ${styles[type]} ${className} ${
          withoutMarginBottom ? styles['without-margin-bottom'] : ''
        }`}
        onClick={onClick}
        id={id}
        onMouseEnter={(x: any) =>
          setShowTooltip(x.target?.clientWidth === maxWidth)
        }
      >
        {withStatusIcon && (
          <Icon
            icon="status-attention"
            size="SS"
            className={styles['icon-status']}
            style={{ marginRight: 10 }}
          />
        )}
        {children}
        {withStatusIconRight && (
          <Icon
            icon="status-attention"
            size="SS"
            className={styles['icon-status']}
            style={{ marginRight: 10 }}
          />
        )}
        {favoriteIcon && (
          <Icon
            icon="favorite"
            style={{ marginLeft: '5px' }}
            color="users09"
            size="S"
          />
        )}
      </div>
    </Tooltip>
  );
};

interface SimpleTextProps {
  name: string;
  type: TextType;
  color?: Color;
  style?: CSSProperties;
  className?: string;
  width?: number;
  withoutMarginBottom?: boolean;
}

export const SimpleText: React.FC<SimpleTextProps> = ({
  name,
  type,
  color,
  className,
  style,
  width,
  withoutMarginBottom,
}) => {
  return (
    <div>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <>
            <div
              style={
                color
                  ? { color: Colors[color], width: width, ...style }
                  : { width: width, ...style }
              }
              className={`${styles['Text']} ${styles[type]} ${className} ${
                withoutMarginBottom ? styles['without-margin-bottom'] : ''
              }`}
            >
              {field.value}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};

export default SimpleText;
