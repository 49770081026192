import * as yup from 'yup';

export const CreateSchema = (
  validateName: (
    descricao: string | null | undefined,
    empresaId: string | null | undefined,
    localEstoqueExternalId: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    descricao: yup
      .string()
      .required(t('supply.supplyPlaces.errors.requiredName'))
      .test(
        'checkDuplications',
        t('supply.supplyPlaces.errors.errorStorageLocationNameExist'),
        function (value) {
          const { empresaId, localEstoqueExternalId } = this.parent;
          return new Promise((resolve) => {
            validateName(
              value,
              empresaId,
              localEstoqueExternalId,
              (success) => {
                resolve(success);
              }
            );
          });
        }
      ),
    empresaId: yup
      .string()
      .required(t('supply.supplyPlaces.errors.requiredCompany')),
  });
