import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_FAST_PROVIDER } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

interface IUseCreatePrescriberCallAPI {
  changeVisible: (value: boolean) => void;
}

export const useCreatePrescriberCallAPI = ({
  changeVisible,
}: IUseCreatePrescriberCallAPI) => {
  const createPrescriber = useControllerMutateApiHook({
    uniqId: CREATE_FAST_PROVIDER,
    entityApi: PrescribersAPI.createFastPrescriber,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        changeVisible(false);
        messageSucessToast(i18n.t('prescribers.createPage.messages.success'));
      },
    },
  });

  return { createPrescriber };
};
