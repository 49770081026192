import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_FOR_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useFormikContext } from 'formik';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { useState } from 'react';

export const useProductCallAPI = ({ index }: IUseCallAPI) => {
  const [isInitial, setIsInitial] = useState(true);
  const form = useFormikContext<any>();

  useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [form?.values?.pedidoCompraItens?.[index]?.produtoExternalId],
    onSuccess: (value) => {
      if (isInitial) return setIsInitial(false);
      if (!!form?.values?.pedidoCompraItens?.[index]?.precoUnitario) {
        form.setFieldValue(
          `pedidoCompraItens.${index}.precoUnitario`,
          value?.valorCusto
        );
      } else {
        form.setFieldValue(
          `pedidoCompraItens.${index}.precoUnitario`,
          value?.valorCusto
        );
      }
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: productList,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      pageIndex: 1,
      pageSize: 10,
    },
    autoIncrement: true,
  });

  return {
    productList,
    fetchNewProductPage,
    refetchSearchProductPage,
  };
};
