import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { useFormikContext } from 'formik';
import { useUnitaryValueCallAPI } from './Hooks/useUnitaryValueCallAPI';
import { useUnitaryValueFunctions } from './Hooks/useUnitaryValueFunctions';
import { useUnitaryValueMemorizations } from './Hooks/useUnitaryValueMemorizations';
import { IUnitaryValue } from './interfaces';

export const UnitaryValue: FC<IUnitaryValue> = ({
  index,
  unitsMeasurements,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const { convertUnitOfMeasureMonetaryValue } = useUnitaryValueCallAPI();

  const { onUnitOfMeasureChange } = useUnitaryValueFunctions({
    index,
    convertUnitOfMeasureMonetaryValue,
  });

  const { label, dropdownRight } = useUnitaryValueMemorizations({
    index,
    unitsMeasurements,
    onUnitOfMeasureChange,
  });

  return (
    <CurrencyInput
      name={`pedidoCompraItens.${index}.precoUnitario`}
      label={label}
      required
      placeHolder={t(
        'purchasing.purchaseOrder.create.productsCardPlaceHolders.unitaryValue'
      )}
      withoutMarginBottom
      allowExtraDecimal
      dropdownRight={dropdownRight}
      disabled={!form?.values?.pedidoCompraItens?.[index]?.produtoExternalId}
      allowNegativeValue={false}
    />
  );
};
