import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_FOR_DROPDOWN_SUPPLY_PLACE } from '@/ControllerApiHook/UniqIds/Supply/InventoryKeys';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { useTranslation } from 'react-i18next';

export const useListSupplyPlace = () => {
  const { t } = useTranslation();
  const { data, fetchNewPage: fetchNewSupplyPlacePage } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_FOR_DROPDOWN_SUPPLY_PLACE,
      entityApi: InventoryAPI.listDropdownSupplyPlaces,
      customDataKey: 'locaisEstoque',
      autoIncrementCustomId: 'localEstoqueExternalId',
      autoIncrement: true,
      pagination: {
        pageIndex: 1,
        pageSize: 10,
      },
    });

  const supplyPlaceData: any = {
    totalProducts: 0,
    supplyPlacesList: [],
  };

  if (!Array.isArray(data)) {
    supplyPlaceData.totalProducts = data.totalProdutosEmTodosLocaisEstoque;
    supplyPlaceData.supplyPlacesList = data.data.map((e: any) => {
      return {
        id: e.localEstoqueExternalId,
        label: e.localEstoqueDescricao,
        subLabel: `${e.empresaNomeFantasia} · ${e.totalProdutos} ${t(
          'common.products'
        )}`,
      };
    });
  }

  return {
    supplyPlaceData,
    fetchNewSupplyPlacePage,
  };
};
