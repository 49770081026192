import { useCallback, useEffect } from 'react';
import { IUseTableItemFunctions } from './interface';

export const useTableItemFunctions = ({
  setScrollVisible,
  contentItemHeight,
  setContentItemHeight,
  items,
  tableRef,
}: IUseTableItemFunctions) => {
  const changeVisibleScroll = useCallback(
    (contentHeight: number) => {
      const visible =
        contentHeight - 52 <=
        tableRef.current?.querySelector('div')?.clientHeight;

      setScrollVisible(visible);
    },
    [tableRef]
  );

  useEffect(() => {
    const pageHeight =
      document.getElementById('content-oder-item')?.clientHeight;
    pageHeight && setContentItemHeight(pageHeight);
  }, [contentItemHeight]);

  useEffect(() => {
    if (!contentItemHeight || !items) return;
    //set timeout to finish rendering the table before getting the height
    setTimeout(() => changeVisibleScroll(contentItemHeight), 100);
  }, [contentItemHeight, changeVisibleScroll, items]);

  return { changeVisibleScroll };
};
