import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUseCallAPI } from './interfaces';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { 
  FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE 
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import {
  FETCH_GET_PHARMACEUTICAL_FORM,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';

export const useEditCallAPI = ({ externalId, form, t }: IUseCallAPI) => {
  const {
    data: unitMeasurementPosologyList,
    isLoading: isLoadingUnitMeasurement,
  } = useControllerQueryListApiHook({
    entityApi: UnitMeasurementAPI.getListUnitMeasurementPosologyDropDown,
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_POSOLOGY,
    autoIncrement: true,
  });

  const {
    data: laboratoryList,
    isLoading: isLoadingLaboratoryList,
    refetch: refetchSearchLaboratoryPage,
    fetchNewPage: fetchNewPageLaboratory,
  } = useControllerQueryListApiHook({
    entityApi: LaboratoryAPI.getListLaboratoryDropDown,
    uniqId: FETCH_LIST_LABORATORY_FOR_DROPDOWN_TYPE,
    pagination: { sorter: { column: 'laboratorio', direction: 'ASC' } },
    autoIncrement: true,
  });

  const { data: pharmaceuticalForm } =
    useControllerQueryApiHook<IGetPharmaceuticalForm>({
      uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
      entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
      externalIds: [externalId],
    });

  return {
    unitMeasurementPosologyList,
    isLoadingUnitMeasurement,
    laboratoryList,
    isLoadingLaboratoryList,
    refetchSearchLaboratoryPage,
    fetchNewPageLaboratory,
    pharmaceuticalForm,
  };
};
