import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues } from 'formik';
import { CompanyBillingsCard } from '../..';
import { Form } from '@/Components';
import { IChangeCompanyPayingRequest } from '@/Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import {
  CHANGE_PAYING_TYPE,
  GET_COMPANY_ADMIN_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

interface ICompanyBillings {
  company: IGetCompanyResponse;
  radioCompanyGroup: boolean;
  radioBillings: boolean;
  onChangeRadioBillings: (value: boolean) => void;
}

export const CompanyEditBillingsCard: React.FC<ICompanyBillings> = ({
  company,
  radioCompanyGroup,
  radioBillings,
  onChangeRadioBillings,
}) => {
  const [closeEditButton, setCloseEditButton] = useState<boolean>(false);

  const { mutateAsync, isLoading } = useControllerMutateApiHook<any>({
    uniqId: CHANGE_PAYING_TYPE,
    entityApi: CompanyAPI.changeCompanyPaying,
    options: {
      onSuccess: () => {
        setCloseEditButton(true);
      }
    }
  });

  const { 
    data: empresaPagadora, 
  } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_ADMIN_TYPE,
    entityApi: CompanyAPI.getCompanyAdmin,
    externalIds: [company?.empresaPagadoraExternalId],
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    !isLoading &&
      onChangeRadioBillings(
        company?.empresaPagadoraExternalId === company?.externalId
      );
  }, [company, onChangeRadioBillings, isLoading]);

  const onSubmit = async (
    values: IChangeCompanyPayingRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IChangeCompanyPayingRequest = {
      ...values,
      helpers,
    };

    const res = await mutateAsync(body);

    if (res.success) queryClient.invalidateQueries([GET_COMPANY_ADMIN_TYPE]);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        externalId: company?.externalId,
        empresaPagadoraExternalId: company?.empresaPagadoraExternalId,
        empresaPagadoraInitialValue: {
          id: company?.empresaPagadoraExternalId,
          label: empresaPagadora?.nomeFantasia,
          avatar: {
            name: empresaPagadora?.nomeFantasia,
          }
        }
      }}
    >
      <ConnectedFocusError />
      <CompanyBillingsCard
        editForm
        companyGroupExternalId={company?.conglomeradoExternalId}
        radioCompanyGroup={radioCompanyGroup}
        onButtonChange={onChangeRadioBillings}
        radioBillings={radioBillings}
        isLoading={isLoading}
        closeEditButton={closeEditButton}
        onChangeCloseEditButton={setCloseEditButton}
      />
    </Form>
  );
};
