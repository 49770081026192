import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';
import { THERE_IS_CAPSULE_COLORS_TYPE } from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { debounce } from 'lodash';
import { IUseCheckNameDuplications } from './interfaces';

export const useCheckNameDuplicationAPI = ({
  initialCapsuleColor
}: IUseCheckNameDuplications) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_CAPSULE_COLORS_TYPE,
    entityApi: CapsulseColorsAPI.isCapsuleColorNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = initialCapsuleColor !== value?.trim() 
          ? (await mutateAsync(value)) as boolean
          : true;
          
        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return { checkNameDuplications: checkNameDuplicationsDebounced };
};
