import { ICreateStorageLocation } from '@/Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import _ from 'lodash';

export const useCreateFunctions = ({
  createStorageLocation,
  selectedOptionsStock,
}: IUseFunctions) => {
  const onSubmit = async (
    values: ICreateStorageLocation,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: ICreateStorageLocation = { ...values, helpers };
      return await createStorageLocation({
        empresaId: body?.empresaId,
        descricao: body?.descricao,
        ativo: true,
        tipoEstoque: selectedOptionsStock,
        helpers,
      });
    } catch {
      helpers.setSubmitting(false);
    }
  };

  return {
    onSubmit,
  };
};
