import { InputWithSearch } from '@/Components';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  FETCH_LIST_CLIENT,
  GET_SIMPLIFIED_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  IGetSimplifiedClientResponse,
  IListClientData,
} from '@/Data/Interfaces/response/Person/Client';
import { Title } from './Popover/Title';
import { Content } from './Popover/Content';
import { FormikContextType, useFormikContext } from 'formik';
import { ICreateManipulatedRecipe } from '@/Data/Interfaces/request/Production/ManipulatedRecipe';

export const PatientInput: FC = () => {
  const { t } = useTranslation();

  const form: FormikContextType<ICreateManipulatedRecipe> = useFormikContext();

  const {
    data: clientList,
    isLoading: clientListLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_CLIENT,
    entityApi: ClientAPI.listClient,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  const { data: clientGet, isLoading: clientLoading } =
    useControllerQueryApiHook<IGetSimplifiedClientResponse>({
      uniqId: GET_SIMPLIFIED_CLIENT,
      entityApi: ClientAPI.getSimplifiedClient,
      externalIds: [form.values.pacienteExternalId],
    });

  return (
    <Col span={24}>
      <InputWithSearch
        label={t('saleOrder.editOrder.SaleOrder.addManipulatedRecipe.patient')}
        items={clientList?.data?.map((x: IListClientData) => ({
          avatar: { name: x.nome },
          id: x.externalId,
          label: x.nome,
          subLabel: `${t('common.code')} ${x.codigo} | ${
            x.cpf ? `CPF: ${MaskForCPF(x.cpf)}` : `CNPJ: ${MaskForCNPJ(x.cnpj)}`
          }`,
          allItem: x,
        }))}
        name="pacienteExternalId"
        withoutSearchIcon
        placeholderWithAvatar
        isLoading={clientListLoading}
        onScrollEnd={fetchNewProductPage}
        onSearch={(search) =>
          refetchClientList({
            search: search,
          })
        }
        selectedOptionRender="withoutSubLabel"
        withoutMarginBottom
        popover={{
          title: <Title client={clientGet} />,
          content: <Content client={clientGet} clientLoading={clientLoading} />,
          width: 300,
        }}
        selectedItemFromForm="pacienteExternalIdInitialValue"
      />
    </Col>
  );
};
