import { InputWithSearch } from '@/Components';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { IListSupplyPlaceToLaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY } from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSupplyPlaceList } from '@/Hooks/HooksServices/Supply/SupplyPlace/useListSupplyPlace';

interface ISixthRowProps {
  selectSupplyPlace: (supplyPlaceId: string) => void;
}

export const SixthRow: React.FC<ISixthRowProps> = ({ selectSupplyPlace }) => {
  const { t } = useTranslation();
  const supplyPlaceResult = useSupplyPlaceList({
    fieldName: 'localEstoque',
  });

  return (
    <Row gutter={[16, 0]} style={{ marginTop: '10px' }}>
      <Col span={5}>
        <InputWithSearch
          label={t('purchasing.invoice.registerLots.stockLocations')}
          required
          placeHolder={t(
            'purchasing.invoice.registerLots.supplyPlacesPlaceholder'
          )}
          onChange={selectSupplyPlace}
          {...supplyPlaceResult}
        />
      </Col>
    </Row>
  );
};
