import { IUseMemorizations } from './interfaces';
import DateUtils from '@/Utils/DateUtils';
import { useTranslation } from 'react-i18next';

export const useEditMemorizations = ({
  transfers,
  preferences,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const initialValuesInfoCard = {
    ...transfers,
    sequenciaNumeroTransferencia: transfers
      ? transfers?.sequenciaNumeroTransferencia
      : '',
    dataTransferencia: transfers
      ? `${DateUtils.convertDate(
          transfers?.dataTransferencia,
          preferences?.padraoData
        )} ${t('common.dateBreakApart')} ${DateUtils.convertDate(
          transfers?.dataTransferencia,
          preferences?.padraoHora
        )}`
      : '',
    responsavel: transfers ? transfers?.responsavelExternalId : '',
    localDeEstoqueOrigem: transfers
      ? `${transfers?.localEstoqueOrigemDescricao} (${transfers?.empresaOrigemDescricao})`
      : '',
    localEstoqueDestino: transfers
      ? `${transfers?.localEstoqueDestinoDescricao} (${transfers?.empresaDestinoDescricao})`
      : '',
    observacao: transfers ? transfers?.observacao : '',
  };

  const initialValuesTransfersCard = {
    ...transfers,
    produtoResponse: transfers
      ? transfers?.transferenciaLoteItens?.map((x) => x?.produtoDescricao)
      : '',

    loteResponse: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => `${x?.loteNumero} / NF: ${x?.notaFiscalNumero}`
        )
      : '',

    movimentoEstoqueOrigem: transfers
      ? transfers?.transferenciaLoteItens?.map((x) => x?.saldoFinalOrigem)
      : '',

    quantidadeTransferida: transfers
      ? transfers?.transferenciaLoteItens?.map((x) => x.quantidadeTransferida)
      : '',

    movimentoEstoqueDestino: transfers
      ? transfers?.transferenciaLoteItens?.map((x) => x?.saldoFinalDestino)
      : '',

    unidadeMedidaId: transfers
      ? transfers?.transferenciaLoteItens?.map(
          (x) => x?.unidadeMedidaAbreviacao
        )
      : '',
  };

  return {
    initialValuesInfoCard,
    initialValuesTransfersCard,
  };
};
