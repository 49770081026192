import { Collapse } from 'antd';
import { CSSProperties, FC, ReactNode, useState } from 'react';
import { Icon, Text } from '..';

import styles from './CardSmaller.module.scss';

const { Panel } = Collapse;

interface ICardSmaller {
  title?: string;
  children: ReactNode;

  style?: CSSProperties;
}

export const CardSmaller: FC<ICardSmaller> = ({ title, children, style }) => {
  const [isActiveIcon, setIsActiveIcon] = useState(false);

  return (
    <Collapse
      className={`${styles[isActiveIcon ? 'collapse-open' : '']} ${
        styles['collapse']
      }`}
      style={style}
      expandIcon={({ isActive }) => {
        setIsActiveIcon(isActive ?? false);
        return (
          <Icon
            className={styles['rotate-' + isActive]}
            size="ML"
            icon="chevron-down"
            color="text-300"
          />
        );
      }}
    >
      <Panel
        header={
          <Text
            className={styles['title']}
            type="ui-tiny-content"
            color="text-100"
            children={title}
          />
        }
        key="1"
      >
        <div className={styles['body']}>{children}</div>
      </Panel>
    </Collapse>
  );
};
