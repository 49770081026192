import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE } from '@/Hooks/Supply/UnitMeasurementHooks';

export const useListUnitMeasure = (unitType: string) => {
  const { data: unitOfMeasure } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementDropDown,
    pagination: {
      pageSize: 50,
      filter: [{ filterName: 'tipoUnidade', value: unitType }],
    },
  });

  return {
    unitOfMeasure:
      unitOfMeasure && unitOfMeasure.data ? unitOfMeasure.data : [],
  };
};
