import * as yup from 'yup';
import { i18n } from '@/Components';

export const CreateSchema = (
  validateName: (
    descricao: string | null | undefined,
    formaFarmaceuticaExternalId: string | null | undefined,
    posologiaExternalId: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('production.posology.create.errors.requiredName'))
    .test(
      'checkDuplications',
      t(
        'production.posology.create.errors.errorPosologyNameExist'
      ),
      function (value) {
        const { 
          formaFarmaceuticaExternalId, 
          posologiaExternalId 
        } = this.parent;

        return new Promise((resolve) => {
          validateName(
            value, 
            formaFarmaceuticaExternalId,
            posologiaExternalId,
            (success) => {
              resolve(success);
            }
          );
        });
      }
    ),
  formaFarmaceuticaExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.posology.create.errors.requiredPharmaceuticalForm')
    ),
  quantidadeDosePorPeriodo: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.posology.create.errors.requiredDosePeriodForm')
    ),
  periodo: yup
    .string()
    .required(i18n.t('production.posology.create.errors.requiredPeriod')),
});
