import { Col, Row } from 'antd';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';

import styles from '../../FooterButton/PackingClassification.module.scss';

export const RowDescription = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={12}>
        <TextInput
          className={styles['text-input']}
          name="descricao"
          label={t('production.packingClassification.create.name')}
          placeHolder={t(
            'production.packingClassification.create.namePlaceholder'
          )}
          required
          dataTestId="packaging-classification-description"
        />
      </Col>
    </Row>
  );
};
