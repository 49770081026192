import React, { FC } from 'react';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { v4 as uuidV4 } from 'uuid';
import { Color } from '../../../../../Utils/ColorUtils';
import ReactMarkdown from 'react-markdown';
import { IConfirmEdit } from '@/Components/Table/components/Modals/EditStatusModal';

import styles from './List.module.scss';

interface IDeleteModal {
  selectedRowsOnTable: any;
}

export const DeleteModal: FC<IDeleteModal> = ({ selectedRowsOnTable }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['delete-modal-container']}>
      <Divflex>
        <Text
          type="ui-tiny-content"
          children={
            <ReactMarkdown>
              {t('adminBootis.companyList.deleteModal.description', {
                company: `**${selectedRowsOnTable[0]?.nomeFantasia}**`,
              })}
            </ReactMarkdown>
          }
          color="text-50"
          withoutMarginBottom
        />
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};

export const ConfirmEditModal = (
  selectedRowsOnTable: any,
  t: any
): IConfirmEdit => ({
  body:
    selectedRowsOnTable?.length === 1 ? (
      <div className={styles['one-selected-container']}>
        <Text
          type="ui-tiny-content"
          children={
            <ReactMarkdown>
              {t('modal.editModal.single.confirm.areYouSureCompany', {
                description: `**${selectedRowsOnTable[0]?.nomeFantasia}**`,
              })}
            </ReactMarkdown>
          }
          color="text-50"
          withoutMarginBottom
        />
        <Text
          type="ui-tiny-content"
          children={t('modal.editModal.single.confirm.footerCompany')}
        />
      </div>
    ) : (
      <div className={styles['many-selected-container']}>
        <Text
          type="ui-tiny-content"
          children={t(
            'adminBootis.companyList.editModal.confirmDescriptionForManyCompanys'
          )}
        />
        <ul className={styles['content']}>
          {selectedRowsOnTable?.map(
            (x: any, index: any) =>
              index < 4 && (
                <li key={uuidV4()}>
                  <Text color="text-50" type="ui-tiny-bold">
                    {x.nomeFantasia}
                  </Text>
                </li>
              )
          )}
          {selectedRowsOnTable?.length > 4 && (
            <li className={styles['company']}>
              <Text
                color="text-300"
                type="ui-tiny-content"
                children={`+ ${selectedRowsOnTable?.length - 4} ${t(
                  'common.companys'
                )}`}
              />
            </li>
          )}
        </ul>
        <Text
          type="ui-tiny-content"
          children={t(
            'adminBootis.companyList.editModal.confirmFooterForManyCompanys'
          )}
        />
      </div>
    ),
  title: `${t('common.inactivate')} ${
    selectedRowsOnTable?.length === 1
      ? `"${selectedRowsOnTable[0]?.nomeFantasia}"`
      : `${selectedRowsOnTable?.length} ${t('common.companys')}`
  }?`,
  okButtonColor: 'status-danger-base' as Color,
  okButtonName: t('common.inactivate'),
});
