import { useMemo } from "react";
import { 
  IListUnitMeasurementByClassData 
} from "@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse";
import { IUseMemorizations } from "./interfaces";

export const useAmountDefaultMemorizations = ({
  unidadeMedidaId,
  listunitsMeasurements
}: IUseMemorizations) => {
  const dropdownRight = useMemo(() => (
    {
      name: 'unidadeMedidaId',
      defaultValue: unidadeMedidaId,
      options:
        listunitsMeasurements?.data?.map((x: IListUnitMeasurementByClassData) => ({
          id: x.id,
          content: x.unidadeAbreviacao,
        })) || [],
    }
  ) , [unidadeMedidaId, listunitsMeasurements]);

  return {
    dropdownRight
  };
};