import { NumberInput, TextArea, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { ValidateCnpj, ValidateCpf } from '@/Utils/ValidationUtils';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PersonType } from '../../../Tabs/Details/Components/InfoCard';
import { IGetClientResponse } from '@/Data/Interfaces/response/Person/Client';

interface IInfoModalBody {
  client?: IGetClientResponse;
}

export const InfoModalBody: React.FC<IInfoModalBody> = ({ client }) => {
  const form = useFormikContext();
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]}>
        {client?.tipoPessoa === PersonType.legalEntity ? (
          <>
            <Col flex="60%">
              <TextInput
                name="nome"
                placeHolder={t('client.createPage.fullNamePlaceholder')}
                label={t('client.createPage.fullName')}
                withStatusIcon
                required
              />
            </Col>
            <Col flex="20%">
              <TextInputWithMask
                name="cpf"
                placeHolder={'000.000.000-00'}
                label={'CPF'}
                withStatusIcon
                required
                mask="cpfMask"
                onChange={(cpf) => ValidateCpf(cpf, form, t)}
              />
            </Col>
            <Col flex="20%">
              <TextInputWithMask
                name="dataNascimento"
                placeHolder={`${t('common.exampleAbbreviation')}: 01/03/1988`}
                label={t('client.editPage.birthDate')}
                mask="dataMask"
                withStatusIcon
                leftIcon="calendar"
              />
            </Col>
          </>
        ) : (
          <>
            <Col flex="40%">
              <TextInput
                name="nome"
                placeHolder={t('client.editPage.fantasyNamePlaceholder')}
                label={t('client.editPage.fantasyName')}
                withStatusIcon
                required
              />
            </Col>
            <Col flex="40%">
              <TextInput
                name="razaoSocial"
                label={'Razão Social'}
                withStatusIcon
                required
              />
            </Col>
            <Col flex="20%">
              <TextInputWithMask
                name="cnpj"
                placeHolder={'00.000.000/0000-00'}
                label={'CNPJ'}
                withStatusIcon
                required
                mask="cnpjMask"
                onChange={(cnpj) =>
                  cnpj
                    ? ValidateCnpj(cnpj, form, t)
                    : form.setFieldError('cnpj', undefined)
                }
              />
            </Col>
          </>
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="23%">
          <NumberInput
            name="descontoProdutosAcabados"
            label={[{ children: t('client.editPage.descProduct') }]}
            placeHolder="0,00"
            leftIcon={{ titleString: '%' }}
            fixedDecimalScale
            decimalScale={2}
            withDecimalSeparator
            withStatusIcon
            withThousandSeparator
          />
        </Col>
        <Col flex="23%">
          <NumberInput
            name="descontoFormulas"
            label={[{ children: t('client.editPage.descFormula') }]}
            placeHolder="0,00"
            leftIcon={{ titleString: '%' }}
            fixedDecimalScale
            decimalScale={2}
            withDecimalSeparator
            withStatusIcon
            withThousandSeparator
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <TextArea
            name="observacao"
            label={t('client.createPage.observation')}
            maxLength={500}
            heightTextArea={104}
            withStatusIcon
            placeholder={t('client.createPage.observationPlaceholder')}
          />
        </Col>
      </Row>
    </div>
  );
};
