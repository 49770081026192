import React from 'react';

interface IFormBreakLineProps {
  marginBottom?: string;
  marginTop?: string;
  style?: any;
}

const FormBreakLine: React.FC<IFormBreakLineProps> = ({
  marginBottom = '20px',
  marginTop = '0px',
  style,
}) => {
  return (
    <div
      style={{
        borderTop: '1px solid var(--color-background-04)',
        marginBottom,
        marginTop,
        ...style,
      }}
    />
  );
};

export default FormBreakLine;
