import { useEffect, useMemo } from 'react';
import { IUseFunctions } from './interfaces';
import _ from 'lodash';

export const useEditFunctions = ({
  setPharmaceuticalFormExternalIdState,
  pharmaceuticaFormDetail,
  setIdUntabbreviated,
  setUntabbreviated,
  posology,
}: IUseFunctions) => {
  useEffect(() => {
    if (posology?.formaFarmaceuticaExternalId) {
      setPharmaceuticalFormExternalIdState(
        posology?.formaFarmaceuticaExternalId
      );
    }
  }, [posology?.formaFarmaceuticaExternalId]);

  const handlerInputPharmaceuticalFormChange = (value: string) => {
    setPharmaceuticalFormExternalIdState(value);
  };

  const { abreviacaoUnidadeMedida, unidadeMedidaId } =
    pharmaceuticaFormDetail ?? {};

  if (abreviacaoUnidadeMedida && unidadeMedidaId) {
    setUntabbreviated(abreviacaoUnidadeMedida);
    setIdUntabbreviated(unidadeMedidaId);
  }

  return {
    handlerInputPharmaceuticalFormChange,
  };
};
