import { useTranslation } from 'react-i18next';

export const useConfigButtonCardText = () => {
  const { t } = useTranslation();

  const configButtonText = (showTechnicalInformation: boolean) => {
    if (showTechnicalInformation) {
      return t('purchasing.invoice.registerLots.hideDetails');
    } else {
      return t('purchasing.invoice.registerLots.showDetails');
    }
  };

  return { configButtonText };
};
