import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

const itemSchema = yup.object().shape({
  produtoExternalId: yup
    .string()
    .nullable()
    .required(i18n.t('purchasing.invoice.create.errors.requiredProduct'))
    .test(
      'not-null',
      i18n.t('purchasing.invoice.create.errors.requiredProduct'),
      function (value) {
        return value !== null;
      }
    ),
  ncmId: yup
    .number()
    .nullable()
    .required(i18n.t('purchasing.invoice.create.errors.requiredNcm'))
    .test(
      'not-null',
      i18n.t('purchasing.invoice.create.errors.requiredNcm'),
      function (value) {
        return value !== null;
      }
    ),
  cstCsosnId: yup
    .number()
    .nullable()
    .required(i18n.t('purchasing.invoice.create.errors.requiredCST'))
    .test(
      'not-null',
      i18n.t('purchasing.invoice.create.errors.requiredCST'),
      function (value) {
        return value !== null;
      }
    ),
  cfopId: yup
    .number()
    .nullable()
    .required(i18n.t('purchasing.invoice.create.errors.requiredCFOP'))
    .test(
      'not-null',
      i18n.t('purchasing.invoice.create.errors.requiredCFOP'),
      function (value) {
        return value !== null;
      }
    ),
  valorUnitario: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredUnitValue')),
  quantidadeComprada: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredQuantity')),
});

export const InvoiceCreateCreateSchema = yup.object().shape({
  numero: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredNumber')),
  serie: yup
    .number()
    .required(i18n.t('purchasing.invoice.create.errors.requiredSeries')),
  naturezaOperacaoId: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredNatureOperation')
    ),
  dataEmissao: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceIssueDate')
    ),
  dataEntrega: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceDeliveryDate')
    ),
  fornecedorExternalId: yup
    .string()
    .required(i18n.t('purchasing.invoice.create.errors.requiredProvider')),
  tipoFreteId: yup
    .string()
    .required(
      i18n.t('purchasing.invoice.create.errors.requiredInvoiceFreight')
    ),
  itens: yup
    .array()
    .of(itemSchema)
    .test(
      'validate-all-but-last',
      'Invalid item in array',
      function (itens): boolean {
        if (!itens) return true;

        if (itens.length === 1) {
          return itemSchema.isValidSync(itens[0]);
        } else {
          const itemsToValidate = itens.slice(0, -1);
          return itemsToValidate.every((item) => itemSchema.isValidSync(item));
        }
      }
    ),
});
