import DateUtils from '@/Utils/DateUtils';

export const useConfigDatePlaceholder = () => {
  const configDatePlaceHolder = (datePattern: any) => {
    if (datePattern) {
      return DateUtils.getDatePlaceholder(
        datePattern.preferencias?.idioma,
        datePattern.preferencias?.padraoData
      );
    }
  };

  return { configDatePlaceHolder };
};
