import { Card } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useConfigButtonCardText } from './hooks/useConfigButtonCardText';
import { useConfigProducts } from './hooks/useConfigProducts';
import { HeaderRow } from './components/HeaderRow';
import { ProductRowLot } from './components/ProductRow';
import FormBreakLine from '@/Components/FormBreakLine';

interface ILotsSectionProps {
  selectedRow: number | null;
  toggleShowTechnicalInformation: () => void;
  showTechnicalInformation: boolean;
  selectRowIndex: (index: number) => void;
}

export const LotsSection: React.FC<ILotsSectionProps> = ({
  selectedRow,
  toggleShowTechnicalInformation,
  showTechnicalInformation,
  selectRowIndex,
}) => {
  const { t } = useTranslation();
  const { configButtonText } = useConfigButtonCardText();
  const { products } = useConfigProducts();

  return (
    <Card
      withoutCollapse
      title={t('purchasing.invoice.registerLots.lotsCardTitle')}
      showButtonTitle
      isButtonTitleDisabled={selectedRow === null}
      onButtonTitleClick={toggleShowTechnicalInformation}
      buttonTittleText={configButtonText(showTechnicalInformation)}
    >
      <HeaderRow showTechnicalInformation={showTechnicalInformation} />
      <FormBreakLine />
      {products.map((e, i) => {
        return (
          <>
            <ProductRowLot
              key={e.key}
              showTechnicalInformation={showTechnicalInformation}
              {...e}
              selectRowIndex={selectRowIndex}
              selectedRow={selectedRow}
              toggleShowTechnicalInformation={toggleShowTechnicalInformation}
            />
            {i < products.length - 1 && <FormBreakLine marginBottom="0px" />}
          </>
        );
      })}
    </Card>
  );
};
