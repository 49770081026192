import { IEditCapsuleColor } from '@/Data/Interfaces/request/CapsuleColors/ICapsuleColorsRequest';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';
import { useCallback } from 'react';
import _ from 'lodash';
import {
  ICannotDeleteModalInfo,
  handleModalError,
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';

export const useEditFunctions = ({
  editCapsuleColor,
  capsuleColor,
  deleteCapsuleColor,
  t,
}: IUseFunctions) => {
  const app = App.useApp();

  const onSubmit = async (
    values: IEditCapsuleColor,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditCapsuleColor = { ...values, helpers };
    if (capsuleColor) {
      const capsuleColorName: string = body.corCapsula.trim();
      return await editCapsuleColor({
        externalId: capsuleColor.externalId,
        corCapsula: capsuleColorName,
        transparente: body.transparente,
        helpers,
      });
    }
  };

  const onDelete = useCallback(async () => {
    const cannotDeleteDescription: ICannotDeleteModalInfo = {
      formName: 'descricao',
      oneErrorModal: {
        titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
          'production.capsuleColors.modalCannotDelete.messageTitle'
        )}`,
        subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
          'production.capsuleColors.modalCannotDelete.messageSubtitle'
        )}`,
        reasonTranslation: `${t(
          'production.capsuleColors.modalCannotDelete.messageReasonCannotDelete'
        )}`,
      },
      manyErrorsModal: {
        entity: `${t('common.modalCannotDelete.messageTitle')} ${t(
          'production.capsuleColors.modalCannotDelete.messageTitle'
        )}`,
        subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
          'production.capsuleColors.modalCannotDelete.messageSubtitle'
        )}`,
        reasonTranslation: `${t(
          'production.capsuleColors.modalCannotDelete.messageReasonCannotDelete'
        )}`,
      },
    };
    try {
      if (!capsuleColor) return;
      return await deleteCapsuleColor(
        { externalId: [capsuleColor.externalId] },
        {
          onError: handleModalError(app, cannotDeleteDescription),
        }
      );
    } catch {}
  }, [deleteCapsuleColor, capsuleColor]);

  return {
    onSubmit,
    onDelete,
  };
};
