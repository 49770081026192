import { Avatar, Text, Status, Tooltip } from '../../../../../Components';
import { TFunction } from 'i18next';
import DateUtils from '../../../../../Utils/DateUtils';

import { IPreferences } from '@/Utils/ThemeUtils';
import { statusTypes } from '@/Utils/StatusUtils';

const waitingRelease = 1;
const released = 2;
const conference = 3;
const finished = 4;
const canceled = 5;

const filterStateColor = (state: number): statusTypes => {
  if (state === waitingRelease) return 'default';
  if (state === released) return 'attention';
  if (state === conference) return 'primary';
  if (state === finished) return 'success';
  if (state === canceled) return 'danger';

  return 'default';
};

const filterStateText = (state: number): string => {
  if (state === waitingRelease) return 'supply.inventory.list.waitingRelease';
  if (state === released) return 'supply.inventory.list.released';
  if (state === conference) return 'supply.inventory.list.conference';
  if (state === finished) return 'supply.inventory.list.finished';
  if (state === canceled) return 'supply.inventory.list.canceled';

  return 'supply.inventory.list.waitingRelease';
};

export const inventoryColumn = (
  t: TFunction,
  preference: IPreferences | undefined
) => {
  return [
    {
      title: t('supply.inventory.list.code'),
      dataIndex: 'codigo',
      key: 'codigo',
      width: '10%',
      sorter: true,
      isHighlightable: true,
      sortColumn: 'codigo',
      defaultSortOrder: 'ascend',
      render: (x: string, row: any) => (
        <Text type="ui-tiny-bold" color="text-50">
          {row.codigo.toString().padStart(7, '0')}
        </Text>
      ),
    },
    {
      title: t('supply.inventory.list.supplyPlaces'),
      dataIndex: 'locaisEstoque',
      key: 'locaisEstoque',
      width: '50%',
      isHighlightable: true,
      render: (x: string, row: any) => (
        <Text
          style={{ display: 'flex', gap: 5 }}
          type="ui-tiny-content"
          color="text-50"
        >
          {row.locaisEstoque.map((e: any, index: number) => {
            if (index >= 5) return '';
            return (
              <Status iconColor="status-disabled-base" type={'default'}>
                {e.localEstoqueDescricao}
              </Status>
            );
          })}
          {row.locaisEstoque.map((e: any, index: number) => {
            if (index === 5) {
              const concatenatedText = row.locaisEstoque
                .slice(index)
                .map((item: any) => item.localEstoqueDescricao)
                .join(', ');

              return (
                <Tooltip title={concatenatedText} showMe>
                  <Status iconColor="status-disabled-base" type={'default'}>
                    ...
                  </Status>
                </Tooltip>
              );
            }
          })}
        </Text>
      ),
    },
    {
      title: t('supply.inventory.list.creationDate'),
      dataIndex: 'dataCriacao',
      key: 'dataCriacao',
      width: '13.3%',
      isHighlightable: true,
      render: (x: string, row: any) => (
        <Text type="ui-tiny-content" color="text-50">
          {`${DateUtils.convertDate(x, preference?.padraoData)} ${t(
            'common.dateBreakApart'
          )} ${DateUtils.convertDate(x, preference?.padraoHora)}`}
        </Text>
      ),
    },
    {
      title: t('supply.inventory.list.responsible'),
      dataIndex: 'responsavelNomeCompleto',
      key: 'responsavelNomeCompleto',
      width: '13.3%',
      isHighlightable: true,
      render: (x: string, row: any) => (
        <Avatar
          type="ui-tiny-bold"
          size="M"
          fisrtName={row.responsavelNomeCompleto.split(' ')[0]}
          lastName={row.responsavelNomeCompleto.split(' ')[1]}
          description={{
            description: row.responsavelNomeCompleto,
            type: 'ui-tiny-content',
            color: 'text-50',
          }}
          color="white"
        />
      ),
    },
    {
      title: t('supply.inventory.list.status'),
      dataIndex: 'statusInventario',
      key: 'statusInventario',
      width: '13.3%',
      render: (x: string, row: any) => (
        <Status
          iconColor="status-disabled-base"
          type={filterStateColor(row.statusInventario)}
        >
          {t(filterStateText(row.statusInventario))}
        </Status>
      ),
    },
  ];
};
