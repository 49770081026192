import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const LaboratoryCreateSchema = (
  validateName: (
    nomeLaboratorio: string | null | undefined,
    empresaExternalId: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
  nomeLaboratorio: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.laboratory.create.errors.requiredLaboratoryName')
    )
    .test(
      'checkDuplications',
      t('production.laboratory.create.validation'),
      function (value) {
        const { empresaExternalId } = this.parent;
        return new Promise((resolve) => {
          validateName(
            value, 
            empresaExternalId,
            (success) => {
              resolve(success);
            }
          );
        });
      }
    ),

  empresaExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.laboratory.create.errors.requiredNomeFantasia')
    ),

  localEstoqueExternalId: yup
    .string()
    .nullable()
    .required(i18n.t('production.laboratory.create.errors.requiredDescricao')),
});
