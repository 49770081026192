import * as yup from 'yup'
import { i18n } from '../../../../../Components'

export const CreateSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
	nome: yup
		.string()
		.required(i18n.t('admin.createUserGroupPage.errors.requiredName'))
		.test(
			'checkDuplications',
			t('common.error.userGroupNameRegistred'),
			(value) => {
				return new Promise((resolve) => {
					validateName(value, (success) => {
						resolve(success);
					});
				});
			}
		),
})
