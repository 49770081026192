import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { CompanyResponsibleUserCard } from '..';
import { Form, i18n } from '../../../../Components';
import { IChangeResponsibilityUserRequest } from '../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { initialValues } from '../CompanyResponsibleUserCard.InitialValues';
import { CompanyResponsibleUserCardSchema } from '../CompanyResponsibleUserCard.schema';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { GET_ACTIVE_USER_ADMIN_LIST_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { CHANGE_RESPONSIBLE_USER_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

interface ICompanyResponsibleUserCardWrapperAdmin {
  company: IGetCompanyResponse;
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
}

export const EditCompanyUserAdmin: React.FC<
  ICompanyResponsibleUserCardWrapperAdmin
> = ({ company, radioCompanyGroup, radioUser, onChangeRadioUser }) => {
  const { mutateAsync, isLoading } = useControllerMutateApiHook({
    uniqId: CHANGE_RESPONSIBLE_USER_TYPE,
    entityApi: CompanyAPI.changeResponsibilytiUser,
    options: {
      onSuccess: () =>
        messageSucessToast(
          i18n.t('admin.companyList.changeResponsibleUserSuccess')
        ),
    },
  });
  return (
    <Form
      onSubmit={(values: IChangeResponsibilityUserRequest) =>
        mutateAsync(values)
      }
      initialValues={company && initialValues(company)}
      schema={CompanyResponsibleUserCardSchema}
    >
      <ConnectedFocusError />
      <CompanyResponsibleUserCardWrapperAdmin
        radioCompanyGroup={!!radioCompanyGroup}
        radioUser={radioUser}
        onChangeRadioUser={onChangeRadioUser}
        isLoading={isLoading}
        editForm
      />
    </Form>
  );
};

interface IEditCompanyUserAdmin {
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
  editForm?: boolean;

  isLoading: boolean;
}

export const CompanyResponsibleUserCardWrapperAdmin: React.FC<
  IEditCompanyUserAdmin
> = ({
  radioCompanyGroup,
  radioUser,
  onChangeRadioUser,
  isLoading,
  editForm,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(!!editForm);
  const form: any = useFormikContext();
  const { data: userList, refetch } = useControllerQueryListApiHook({
    uniqId: GET_ACTIVE_USER_ADMIN_LIST_TYPE,
    entityApi: UserAPI.listActiveUserAdmin,
  });

  useEffect(() => {
    refetch({
      filter: [
        {
          filterName: 'conglomeradoExternalId',
          value: editForm
            ? form.values.empresasExternalId
            : form?.values.conglomeradoExternalId,
        },
      ],
    });
  }, [form?.values?.conglomeradoExternalId, refetch]);

  return (
    <CompanyResponsibleUserCard
      editForm={editForm}
      radioCompanyGroup={!!radioCompanyGroup}
      radioUser={radioUser}
      onChangeRadioUser={onChangeRadioUser}
      isLoading={isLoading}
      users={userList}
      buttonDisabled={buttonDisabled}
      setButtonDisabled={setButtonDisabled}
    />
  );
};
