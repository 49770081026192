import { useState } from 'react';

export const useEditStates = () => {
  const [productModalOpen, setProductModalOpen] = useState(false);

  return {
    productModalOpen,
    setProductModalOpen,
  };
};
