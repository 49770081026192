import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '../../../../../Components';
import { LaboratoryCreateSchema } from './laboratoryCreateSchema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { RowDrescription } from './Components/Rows/DescriptionRow';
import { FooterButtons } from './Components/FooterButton';
import { 
  ConnectedFocusError 
} from '@/Components/ConnectedFocusError/ConnectedFocusError';
import { 
  useCheckNameDuplicationCallAPI 
} from './Components/Rows/DescriptionRow/Hooks/useCheckNameDuplicationsCallAPI';

const CreateLaboratory: React.FC = () => {
  const { t } = useTranslation();

  const {
    checkNameDuplicationsDebounced,
  } = useCheckNameDuplicationCallAPI({});

  const { createLaboratory, isLoadingCreateLaboratory } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({ createLaboratory });

  return (
    <>
      <Form 
        onSubmit={onSubmit} 
        schema={LaboratoryCreateSchema(checkNameDuplicationsDebounced, t)}
        individualValidateOnChange
      >
        <Card title={t('common.information')} withoutCollapse={true}>
          <RowDrescription />
        </Card>
        <FooterButtons isLoadingCreateLaboratory={isLoadingCreateLaboratory} />
        <ConnectedFocusError />
      </Form>
    </>
  );
};

export default CreateLaboratory;
