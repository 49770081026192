import { FC } from 'react';
import { TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const PhysicalPerson: FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="nome"
          required
          label={t('client.createPage.fullName')}
          placeHolder={t('client.createPage.fullNamePlaceholder')}
        />
      </Col>
      <Col span={6}>
        <TextInputWithMask
          name="cpf"
          required
          label={'CPF'}
          placeHolder={`${t('common.exampleAbbreviation')}: 914.708.028-05`}
          mask="cpfMask"
        />
      </Col>
      <Col flex="17%">
        <TextInputWithMask
          name="dataNascimento"
          placeHolder={`${t('common.exampleAbbreviation')}: 01/06/1988`}
          label={t('client.createPage.birthDate')}
          mask="dataMask"
          leftIcon="calendar"
        />
      </Col>
    </Row>
  );
};
