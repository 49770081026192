import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteUserGroupsWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'userGroups.editGroups.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'userGroups.editGroups.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'userGroups.editGroups.modalCannotDelete.messageReasonCannotDelete'
    )}`,
    warningModal: `${t('userGroups.editGroups.modalCannotDelete.warning')}`,
  },
  manyErrorsModal: {
    entity: `${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t(
      'userGroups.editGroups.modalCannotDelete.manyMessageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'userGroups.editGroups.modalCannotDelete.manyMessageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'userGroups.editGroups.modalCannotDelete.manyMessageReasonCannotDelete'
    )}`,
    warningModal: `${t('userGroups.editGroups.modalCannotDelete.warning')}`,
  },
});
