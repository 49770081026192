import { useFormikContext } from 'formik';
import { currencyFormater } from '@/Utils/Currency';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { TypePurchaseOrderEnum } from '@/Data/Interfaces/model/typePurchaseOrderEnum';

export const useTotalOrderMemorizations = ({
  purchaseOrder,
  withSelectedRows,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const hasProducts =
    form.values.pedidoCompraItensExternalId?.filter((x: any) => !!x).length > 0;

  const totalProducts = purchaseOrder?.pedidoCompraItens
    .filter((x: any) =>
      form.values.pedidoCompraItensExternalId.includes(
        x.pedidoCompraItemExternalId
      )
    )
    .reduce((total: any, item: any) => {
      const productTotal = item.precoUnitario * item.quantidade;
      return total + productTotal;
    }, 0);

  const totalDiscounts = purchaseOrder?.pedidoCompraItens
    .filter((x: any) =>
      form.values.pedidoCompraItensExternalId.includes(
        x.pedidoCompraItemExternalId
      )
    )
    .reduce((totalDiscount: any, item: any) => {
      const discountPerQuantity = item.valorDescontoUnitario * item.quantidade;
      return totalDiscount + discountPerQuantity;
    }, 0);

  const totalFullProducts = totalProducts - totalDiscounts;

  const totalOrder =
    totalFullProducts +
    form?.values?.frete +
    form?.values?.valorAdicional -
    form?.values?.valorDesconto;

  const labelValue = !hasProducts
    ? currencyFormater(0)
    : withSelectedRows && hasProducts
    ? currencyFormater(purchaseOrder?.totalizadores?.totalPedido ?? 0)
    : currencyFormater(totalOrder);

  const withTooltip =
    withSelectedRows && hasProducts
      ? {
          title: `${t('common.originalValue')} ${currencyFormater(
            form.values.totalizadores.totalPedido
          )}`,
          showMe: form.dirty,
        }
      : undefined;

  const style =
    form.values.status === TypePurchaseOrderEnum.canceled ||
    form.values.status === TypePurchaseOrderEnum.disapproved
      ? { textDecorationLine: 'line-through' }
      : {};

  return {
    hasProducts,
    totalOrder,
    labelValue,
    withTooltip,
    style,
  };
};
