import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const useValidateDates = () => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const parseDate = (date: string) => {
    const parts = date.split('/');
    return new Date(+parts[2], +parts[1] - 1, +parts[0]);
  };

  const validateDates = (
    lotDataIndex: number
  ): {
    error: boolean;
    messageExpirationDate?: string;
    errorReason?: string;
    messageManufacturingDate?: string;
  } => {
    const formManufacturingDate =
      form.values.lotes[lotDataIndex]?.dataFabricacao;
    const formExpirationDate = form.values.lotes[lotDataIndex]?.dataValidade;
    const formReleaseDate = form.values.dataLancamento;
    const formDeliveryDate = form.values.dataEntrega;

    if (formManufacturingDate && formExpirationDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const expirationDate = parseDate(formExpirationDate);
      if (manufacturingDate > expirationDate) {
        return {
          error: manufacturingDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.expiryDateError'
          ),
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.manufacturingDateError'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formDeliveryDate && formManufacturingDate) {
      const manufacturingDate = parseDate(formManufacturingDate);
      const deliveryDate = parseDate(formDeliveryDate);

      if (manufacturingDate > deliveryDate) {
        return {
          error: manufacturingDate > deliveryDate,
          messageManufacturingDate: t(
            'purchasing.invoice.registerLots.releaseDateErrorManufacturing'
          ),
          errorReason: 'manufacturingDate',
        };
      }
    }

    if (formReleaseDate && formExpirationDate) {
      const expirationDate = parseDate(formExpirationDate);
      const releaseDate = parseDate(formReleaseDate);
      if (releaseDate > expirationDate) {
        return {
          error: releaseDate > expirationDate,
          messageExpirationDate: t(
            'purchasing.invoice.registerLots.releaseDateError'
          ),
          errorReason: 'releaseDate',
        };
      }
    }

    return { error: false };
  };

  return { validateDates };
};
