import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { 
  THERE_IS_POSOLOGY_TYPE 
} from "@/ControllerApiHook/UniqIds/Production/PosologyKeys";
import { PosologyAPI } from "@/Data/API/Production/Posology";
import { debounce } from "lodash";
import { IUseCheckNameDuplicationCallAPI } from "./interfaces";

export const useCheckNameDuplicationCallAPI = ({
  initialPosology,
  initialFormaFarmaceutica
}: IUseCheckNameDuplicationCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_POSOLOGY_TYPE,
    entityApi: PosologyAPI.isPosologyNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      descricao: string | null | undefined,
      formaFarmaceuticaExternalId: string | null | undefined,
      posologiaExternalId: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = 
          initialPosology !== descricao?.trim() &&
          initialFormaFarmaceutica !== formaFarmaceuticaExternalId &&
          formaFarmaceuticaExternalId
          ? (await mutateAsync({
            descricao,
            formaFarmaceuticaExternalId,
            posologiaExternalId
          })) as boolean
          : true;

        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkNameDuplicationsDebounced
  };
};