import { QueryFunctionContext } from '@tanstack/react-query';
import {
  IGetLaboratory,
  ILaboratoryList,
  ILaboratoryListResponse,
  IListCompanyToLaboratoryResponse,
  IListSupplyPlaceToLaboratoryResponse,
} from '../../Interfaces/response/Laboratory/ILaboratoryResponse';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  ICreateLaboratory,
  IDeleteLaboratoryRequest,
  IEditLaboratory,
  ILaboratoryBindCompanyRequest,
  ILaboratoryStatusRequest,
} from '../../Interfaces/request/Laboratory/ILaboratoryRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class LaboratoryAPI {
  public static async getListLaboratoryDropDown(
    context: QueryFunctionContext
  ): Promise<ILaboratoryListResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<ILaboratoryListResponse>(
      Http.AddQueryParams(
        '/pharma/producao/v1/Laboratorio/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteLabortory(body: IDeleteLaboratoryRequest) {
    const res = await Http.fetcher(`/pharma/producao/v1/Laboratorio`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getLaboratoryList(
    context: QueryFunctionContext
  ): Promise<ILaboratoryList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<ILaboratoryList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/laboratorio/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async laboratoryStatus(body: ILaboratoryStatusRequest) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/Laboratorio/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async createLaboratory(body: ICreateLaboratory) {
    const res = await Http.fetcher(`/pharma/producao/v1/Laboratorio`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async listCompanyForDropdownToLaboratory(
    context: QueryFunctionContext
  ): Promise<IListCompanyToLaboratoryResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IListCompanyToLaboratoryResponse>(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Empresa/ListarNomeFantasia',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listSupplyPlaceForDropdownToLaboratory(
    context: any
  ): Promise<IListSupplyPlaceToLaboratoryResponse | null> {
    return await Http.fetcher<IListSupplyPlaceToLaboratoryResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/localEstoque/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async laboratoryName(
    laboratoryName?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!laboratoryName) return null;
    return await Http.fetcher(
      '/pharma/producao/v1/Laboratorio/ValidarDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ laboratoryName }),
      }
    );
  }

  public static async getCurrentLaboratory(
    context: QueryFunctionContext
  ): Promise<IGetLaboratory | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetLaboratory>(
      `/pharma/producao/v1/Laboratorio/${context.queryKey[1]}`
    );
  }

  public static async deleteLaboratoryDetails(body: IDeleteLaboratoryRequest) {
    const res = await Http.fetcher(`/pharma/producao/v1/Laboratorio`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async laboratoryBindCompany(
    body: ILaboratoryBindCompanyRequest
  ): Promise<boolean> {
    if (!body.empresaExternalId || !body.descricao) return false;

    try {
      const result = await Http.fetcher(
        '/pharma/producao/v1/Laboratorio/ValidarDescricaoPorEmpresa',
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );

      return result.success;
    } catch(e) {
      return false;
    }
  }

  public static async editLaboratory(body: IEditLaboratory) {
    const res = await Http.fetcher('/pharma/producao/v1/Laboratorio', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }
}
