import { InputWithSearch, TextInput, Tooltip } from '@/Components';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useParams } from 'react-router';
import { useEditMemorizations } from './Hooks/useEditMemorizations';

interface IDescriptionRow {
  disabled: boolean;
  descriptionOriginPosology?: string;
  externalIdOriginPharmaceuticalForm?: string;
  setUntabbreviated: React.Dispatch<React.SetStateAction<string>>;
  setIdUntabbreviated: React.Dispatch<React.SetStateAction<number>>;
}

export const DescriptionRow: FC<IDescriptionRow> = ({
  disabled,
  setUntabbreviated,
  setIdUntabbreviated,
  descriptionOriginPosology,
  externalIdOriginPharmaceuticalForm,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const {
    setPharmaceuticalFormExternalIdState,
    pharmaceuticalFormExternalIdChanged,
    setPharmaceuticalFormExternalIdStateChanged,
  } = useEditStates();

  const {
    posology,
    listPharmaceuticalForm,
    fetchNewFarmaceuticalFormPage,
    isPharmaceuticalFormLoading,
    refetchSearchPharmaceuticalFormPage,
    pharmaceuticaFormDetail,
  } = useEditCallAPI({
    externalIdOriginPharmaceuticalForm,
    externalId,
    pharmaceuticalFormExternalIdChanged,
  });

  const {
    handlerInputPharmaceuticalFormChange,
  } = useEditFunctions({
    setPharmaceuticalFormExternalIdState,
    pharmaceuticaFormDetail,
    setIdUntabbreviated,
    setUntabbreviated,
    posology,
  });

  const { pharmaceuticalFormList } = useEditMemorizations({
    listPharmaceuticalForm,
  });

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={16}>
        <Tooltip
          title={`${t(
            'production.posology.details.toolTips.descriptionPosology'
          )} ${descriptionOriginPosology}`}
          showMe={
            !disabled && descriptionOriginPosology !== form.values.descricao
          }
          placement="top"
          targetOffset={[5, -18]}
        >
          <TextInput
            name="descricao"
            label={t('production.posology.create.posology')}
            placeHolder={t('production.posology.create.namePlaceholder')}
            disabled={disabled}
            required={!disabled}
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={8}>
        <Tooltip
          title={`${t(
            'production.posology.details.toolTips.pharmaceuticalForm'
          )} ${posology?.formaFarmaceuticaDescricao}`}
          showMe={
            !disabled &&
            posology?.formaFarmaceuticaExternalId !==
              form.values.formaFarmaceuticaExternalId
          }
          placement="top"
          targetOffset={[-34, -18]}
        >
          <InputWithSearch
            items={pharmaceuticalFormList}
            name="formaFarmaceuticaExternalId"
            label={t('production.posology.create.pharmaceuticalForm')}
            placeHolder={t(
              'production.posology.create.pharmaceuticalFormPlaceholder'
            )}
            required={!disabled}
            disabled={disabled}
            onChange={(value) => {
              form?.setFieldValue('formaFarmaceuticaExternalId', value);
              setTimeout(
                () => form?.validateField('descricao'), 
                0
              );
              handlerInputPharmaceuticalFormChange(value);
              setPharmaceuticalFormExternalIdStateChanged(value);
            }}
            selectedItemFromForm="pharmaceuticalFormLabelInitialValue"
            isLoading={isPharmaceuticalFormLoading}
            withStatusIcon
            onScrollEnd={fetchNewFarmaceuticalFormPage}
            onSearch={(search) =>
              refetchSearchPharmaceuticalFormPage({
                search: search,
              })
            }
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
