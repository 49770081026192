import { FC } from 'react';
import { TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const LegalEntity: FC = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <TextInput
          name="fantasyName"
          required
          label={t('client.createPage.fantasyName')}
          placeHolder={t('client.createPage.fantasyNamePlaceholder')}
        />
      </Col>
      <Col span={6}>
        <TextInput
          name="razaoSocial"
          required
          label={t('client.createPage.corporateName')}
          placeHolder={t('client.createPage.fantasyNamePlaceholder')}
        />
      </Col>
      <Col flex="17%">
        <TextInputWithMask
          name="cnpj"
          placeHolder={`${t('common.exampleAbbreviation')}: 42.163.509/0001-03`}
          label={'CNPJ'}
          mask="cnpjMask"
        />
      </Col>
    </Row>
  );
};
