import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { IUseCheckNameDuplicationCallAPI } from "./interface";
import { PharmaceuticalFormAPI } from "@/Data/API/Production/PharmaceuticalForm";
import { 
  THERE_IS_PHARMACEUTICAL_FORM_TYPE 
} from "@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys";
import { debounce } from "lodash";

export const useCheckNameDuplicationCallAPI = ({
  initialPharmaceuticalFormDescription
}: IUseCheckNameDuplicationCallAPI) => {
  const { mutateAsync } = useControllerMutateApiHook(
    {
      entityApi: PharmaceuticalFormAPI.pharmaceuticalFormDescription,
      uniqId: THERE_IS_PHARMACEUTICAL_FORM_TYPE,
    }
  );

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = initialPharmaceuticalFormDescription?.trim() !== value 
          ? (await mutateAsync(value)) as boolean
          : true;

        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkNameDuplicationsDebounced
  }
};