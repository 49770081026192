import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components/Inputs/TextInput';

export const UserGroupNameField: FC = () => {
  const { t } = useTranslation();

  return (
    <TextInput
      name="nome"
      dataTestId='nome'
      label={t('admin.createUserGroupPage.detaildCard.name')}
      placeHolder={t('admin.createUserGroupPage.detaildCard.namePlaceholder')}
      required
    />
  );
};
