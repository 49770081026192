import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { history } from '../../../../../../Store';
import {
  ADD_ADRESS_SUPPLIER,
  ADD_CONTACT_SUPPLIER,
  ADD_DOCUMENT_SUPPLIER,
  CHANGE_FAVORITE_ADRESS_SUPPLIER,
  CHANGE_FAVORITE_CONTACT_SUPPLIER,
  DELETE_ADRESS_SUPPLIER,
  DELETE_CONTACT_SUPPLIER,
  DELETE_DOCUMENT_SUPPLIER,
  DELETE_SUPPLIER,
  EDIT_ADRESS_SUPPLIER,
  EDIT_CONTACT_SUPPLIER,
  EDIT_DOCUMENT_SUPPLIER,
  EDIT_INFO_SUPPLIER,
  FETCH_GET_SUPPLIER,
  FETCH_LIST_SUPPLIER,
} from '@/ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';
import { IGetSupplierResponse } from '@/Data/Interfaces/response/Purchase/ISupplierResponse';
import { FETCH_PROVIDER_TYPE } from '@/ControllerApiHook/UniqIds/People/PersonKeys';
import { PersonAPI } from '@/Data/API/People/PersonApi';
import { LIST_DOCUMENT } from '@/ControllerApiHook/UniqIds/People/DocumentKeys';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const { data: providerTypes } = useControllerQueryListApiHook({
    uniqId: FETCH_PROVIDER_TYPE,
    entityApi: PersonAPI.listProviderTypes,
  });

  const { data: supplier, isLoading: isLoadingSupplier } =
    useControllerQueryApiHook<IGetSupplierResponse>({
      uniqId: FETCH_GET_SUPPLIER,
      entityApi: SupplierAPI.getSupplier,
      externalIds: [externalId],
    });

  const { mutateAsync: deleteSupplier, isLoading: isLoadingDeleteSupplier } =
    useControllerMutateApiHook({
      uniqId: DELETE_SUPPLIER,
      entityApi: SupplierAPI.deleteSupplier,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_SUPPLIER]);
          history.push(`/order/supplier`);
        },
      },
    });

  const {
    mutateAsync: editSupplierInfo,
    isLoading: isLoadingEditSupplierInfo,
  } = useControllerMutateApiHook({
    uniqId: EDIT_INFO_SUPPLIER,
    entityApi: SupplierAPI.editSupplierInfo,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const addContact = useControllerMutateApiHook({
    uniqId: ADD_CONTACT_SUPPLIER,
    entityApi: SupplierAPI.addContactSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const editContact = useControllerMutateApiHook({
    uniqId: EDIT_CONTACT_SUPPLIER,
    entityApi: SupplierAPI.editContactSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const deleteContact = useControllerMutateApiHook({
    uniqId: DELETE_CONTACT_SUPPLIER,
    entityApi: SupplierAPI.deleteContactSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const changeFavoriteContact = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_CONTACT_SUPPLIER,
    entityApi: SupplierAPI.changeFavoriteContactSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const addAddress = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_SUPPLIER,
    entityApi: SupplierAPI.addAddressSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const editAddress = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_SUPPLIER,
    entityApi: SupplierAPI.editAddressSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const deleteAddress = useControllerMutateApiHook({
    uniqId: DELETE_ADRESS_SUPPLIER,
    entityApi: SupplierAPI.deleteAddressSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const changeFavoriteAddress = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_ADRESS_SUPPLIER,
    entityApi: SupplierAPI.changeFavoriteAddressSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const addDocument = useControllerMutateApiHook({
    uniqId: ADD_DOCUMENT_SUPPLIER,
    entityApi: SupplierAPI.addDocumentSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const editDocument = useControllerMutateApiHook({
    uniqId: EDIT_DOCUMENT_SUPPLIER,
    entityApi: SupplierAPI.editDocumentSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const deleteDocument = useControllerMutateApiHook({
    uniqId: DELETE_DOCUMENT_SUPPLIER,
    entityApi: SupplierAPI.deleteDocumentSupplier,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SUPPLIER]);
      },
    },
  });

  const {
    data: documentList,
    fetchNewPage: fetchNewPageDocument,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: LIST_DOCUMENT,
    entityApi: DocumentAPI.listDocument,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
    enabled: !isNil(supplier?.tipoPessoa),
  });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        {
          filterName: 'tipoPessoa',
          value: supplier?.tipoPessoa === 1 ? 1 : '0',
        },
        { filterName: 'ordem', value: [0, 1, 2] },
      ],
    });
  }, [supplier?.tipoPessoa]);

  return {
    providerTypes,
    supplier,
    isLoadingSupplier,
    deleteSupplier,
    isLoadingDeleteSupplier,
    editSupplierInfo,
    isLoadingEditSupplierInfo,
    addContact,
    editContact,
    deleteContact,
    changeFavoriteContact,
    addAddress,
    editAddress,
    deleteAddress,
    changeFavoriteAddress,
    addDocument,
    editDocument,
    deleteDocument,
    documentList,
    fetchNewPageDocument,
  };
};
