import { RadioButton } from '@/Components/StateInputs/RadioButton';
import { RadioGroup } from '@/Components/StateInputs/RadioButton/RadioGroup';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { InputWithSearch, TextArea } from '@/Components';
import { IRadioButtonMessageType } from './interfaces';
import { useRadioButtonMessageTypeStates } from './Hooks/useRadioButtonMessageTypeStates';
import { useRadioButtonMessageTypeCallAPI } from './Hooks/useRadioButtonMessageTypeCallAPI';
import { useRadioButtonMessageTypeFunctions } from './Hooks/useRadioButtonMessageTypeFunctions';
import { useRadioButtonMessageTypeMemorizations } from './Hooks/useRadioButtonMessageTypeMemorizations';

import styles from './RadioButtonMessageType.module.scss';
import { FormikValues, useFormikContext } from 'formik';

export const RadioButtonMessageType: FC<IRadioButtonMessageType> = ({
  onTypeMessageSelected,
  setProductMessageId,
}) => {
  const { t } = useTranslation();

  const form = useFormikContext<FormikValues>();

  const {
    selectedOptionsMessageType,
    setSelectedOptionsMessageType,
    messageSearch,
    setMessageSearch,
  } = useRadioButtonMessageTypeStates();

  const {
    listMessageProductDropdown,
    isLoadingMessageProductDropdown,
    fetchNewMessageProductDropdown,
    refetchMessageProductDropdown,
  } = useRadioButtonMessageTypeCallAPI();

  const {
    createMessageProductLinked,
    onSearchMessageExisting,
    onChangeInputTextMessageExisting,
  } = useRadioButtonMessageTypeFunctions({
    setSelectedOptionsMessageType,
    refetchMessageProductDropdown,
    setMessageSearch,
  });

  const { items, staticOptions } = useRadioButtonMessageTypeMemorizations({
    listMessageProductDropdown,
    messageSearch,
    createMessageProductLinked,
  });

  useEffect(() => {
    form.setFieldValue('mensagemExternalId', null);
    onTypeMessageSelected(selectedOptionsMessageType);
  }, [selectedOptionsMessageType]);

  return (
    <RadioGroup
      className={styles['radio-group']}
      valueChecked={selectedOptionsMessageType}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <RadioButton
            value={1}
            label={t(
              'products.productList.productMessage.raddioButtonMessageTypes.messageExisting'
            )}
            onChange={() => setSelectedOptionsMessageType(1)}
          />
          {selectedOptionsMessageType === 1 && (
            <div style={{ marginTop: '15px', marginBottom: '-15px' }}>
              <InputWithSearch
                items={items}
                name="mensagemExternalId"
                placeHolder={t(
                  'products.productList.productMessage.modalProductLinkedMessage.placeholderMessageExisting'
                )}
                staticOptions={staticOptions}
                isLoading={isLoadingMessageProductDropdown}
                required
                onScrollEnd={fetchNewMessageProductDropdown}
                onSearch={onSearchMessageExisting}
                onChangeInputText={onChangeInputTextMessageExisting}
                onChange={(x: string) => setProductMessageId(x)}
              />
            </div>
          )}
        </Col>
        <Col span={24}>
          <RadioButton
            value={2}
            label={t(
              'products.productList.productMessage.raddioButtonMessageTypes.createNewMessage'
            )}
            onChange={() => setSelectedOptionsMessageType(2)}
          />

          {selectedOptionsMessageType === 2 && (
            <div style={{ marginTop: '15px' }}>
              <TextArea
                name="mensagem"
                label={''}
                placeholder={t(
                  'products.productList.productMessage.modalProductLinkedMessage.placeholderNewMessage'
                )}
                maxLength={5000}
                rows={1}
                heightTextArea={100}
                required
              />
            </div>
          )}
        </Col>
      </Row>
    </RadioGroup>
  );
};
