import { IListDetailedClientData } from '@/Data/Interfaces/response/Person/Client';
import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListDetailedClientData[]
  >([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
  };
};
