import { Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITechnicalInfoDilutionsRows } from './interfaces';
import { SupplierDilution } from './Components/SupplierDilution';
import { SupplierDilutionFactor } from './Components/SupplierDilutionFactor';
import { WaterConcentration } from './Components/WaterConcentration';
import { WaterConcentrationFactor } from './Components/WaterConcentrationFactor';
import { InternalDilution } from './Components/InternalDilution';
import { InternalDilutionFactor } from './Components/InternalDilutionFactor';
import { BatchSearch } from './Components/BatchSearch';
import { useTechnicalInfoDilutionsRowsCallAPI } from './Hooks/useTechnicalInfoDilutionsRowsCallAPI';
import { ICreateLotRequest } from '@/Data/Interfaces/request/Lot/ILotResquest';
import { CardSmaller } from '@/Components/CardSmaller';

export const TechnicalInfoDilutionsRows: FC<ITechnicalInfoDilutionsRows> = ({
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<ICreateLotRequest> = useFormikContext();

  const { lotList, isLotLoading, fetchNewLotPage } =
    useTechnicalInfoDilutionsRowsCallAPI({ form });

  return (
    <>
      <Row gutter={[16, 0]}>
        <SupplierDilution />
        <SupplierDilutionFactor />
        <WaterConcentration />
        <WaterConcentrationFactor />
      </Row>
      <CardSmaller
        title={t('supply.lot.create.titleInternalDilution')}
        style={{ marginBottom: '20px' }}
      >
        <Row gutter={[16, 0]}>
          <InternalDilution />
          <InternalDilutionFactor />
          <BatchSearch
            lotList={lotList}
            disabledForm={disabledForm}
            isLotLoading={isLotLoading}
            fetchNewLotPage={fetchNewLotPage}
          />
        </Row>
      </CardSmaller>
    </>
  );
};
