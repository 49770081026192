import { Avatar, Text, Icon } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import { history } from '@/Store';
import { FormikContextType, useFormikContext } from 'formik';
import { ICreateManipulatedRecipe } from '@/Data/Interfaces/request/Production/ManipulatedRecipe';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';

import styles from './Title.module.scss';

interface ITitle {
  selectedPrescriber?: ISimpleListPrescribersData;
}

export const Title: FC<ITitle> = ({ selectedPrescriber }) => {
  const form: FormikContextType<ICreateManipulatedRecipe> = useFormikContext();
  return (
    <Divflex>
      <Avatar
        fisrtName={selectedPrescriber?.nomeCompleto}
        size="M"
        type="small-text-bold"
        color="white"
      />
      <div className={styles['description']}>
        <Text
          type="ui-sub-bold"
          color="text-50"
          children={selectedPrescriber?.nomeCompleto}
          width={188}
          id="prescriber-id"
        />
        <Text
          type="ui-tiny-content"
          color="text-300"
          children={`Prescritor • #${selectedPrescriber?.codigoRegistro}`}
        />
      </div>
      <div className={styles['link-icon']}>
        <Icon
          icon="external-link"
          size="M"
          color="text-300"
          onClick={() =>
            history.push(
              `/sales/prescribers/${form?.values?.prescritorExternalId}/details`
            )
          }
        />
      </div>
    </Divflex>
  );
};
