import { FC } from 'react';
import { history } from '@/Store';
import { TFunction } from 'react-i18next';
import { Modal } from '@/Components';
import { ModalBody } from './Body';
import { useCreatePrescriberStates } from './Hooks/useCreateFastPrescriberStates';
import { useCreatePrescriberCallAPI } from './Hooks/useCreateFastPrescriberCallAPI';
import { useCreatePrescriberFunctions } from './Hooks/useCreateFastPrescriberFunctions';

interface ICreateFastPrescriberModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  nameClient: string;
  t: TFunction<'translation', undefined>;
}

export const CreateFastPrescriberModal: FC<ICreateFastPrescriberModal> = ({
  changeVisible,
  visible,
  nameClient,
  t,
}) => {
  const { formValues, setFormValues } = useCreatePrescriberStates();

  const { createPrescriber } = useCreatePrescriberCallAPI({ changeVisible });

  const { getUrlFormValues } = useCreatePrescriberFunctions();

  return (
    <Modal
      noLeaveWithoutLeaving
      onOkClickForm={(x, formik) =>
        createPrescriber.mutateAsync(
          {
            ...x,
            identificacaoContato: x.identificacaoContato,
          },
          { onError: () => formik.setSubmitting(false) }
        )
      }
      title={t('common.createNewPrescriber')}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      onClose={() => changeVisible(!visible)}
      withForm
      widthModal={650}
      loading={createPrescriber.isLoading}
      disabledButton={createPrescriber.isLoading}
      initialValues={{ nomeCompleto: nameClient }}
      okButtonName={t('common.create')}
      formValues={setFormValues}
      dropdownRight={[
        {
          label: t(
            'callCenter.createService.createClientModal.createCompleteData'
          ),
          onClick: () =>
            history.push(
              `/sales/prescribers/create?${getUrlFormValues(formValues)}`
            ),
        },
      ]}
    />
  );
};
