import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const formatQuantityExcess = (value: number): string => {
  const formattedValue = value.toFixed(4);
  return formattedValue.replace('.', ',');
};

const debounceQuantity = _.debounce((configNewRow: () => void) => {
  configNewRow();
}, 1000);

export const useConfigQuantityExcessOrDeficit = (
  itemId: string,
  quantityMax: number,
  currentUnitAbbreviation: string
) => {
  const form: any = useFormikContext();
  const [quantityErrorMessage, setQuantityErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  const [productExcess, setProductExcess] = useState<number>(0);
  const [productDeficit, setProductDeficit] = useState<number>(0);

  const calculateExcessOrDeficit = (quantity: string, lotIndex: number) => {
    const numberQuantity = Number(quantity.replace(',', '.'));

    let totalQuantity = 0;
    const allProductLots = form.values.lotes.filter(
      (e: any, indexLotForm: number) => {
        return (
          e.notaFiscalEntradaItemId === itemId && indexLotForm !== lotIndex
        );
      }
    );

    allProductLots.forEach((e: any) => {
      totalQuantity += e.quantidade;
    });
    totalQuantity = totalQuantity + numberQuantity;

    setProductExcess(0);
    setProductDeficit(0);
    debounceQuantity(() => {
      if (totalQuantity > quantityMax) {
        setProductExcess(totalQuantity - quantityMax);
        setProductDeficit(0);
        setQuantityErrorMessage(
          t('purchasing.invoice.registerLots.quantityError', {
            excess: `${formatQuantityExcess(
              totalQuantity - quantityMax
            )} ${currentUnitAbbreviation}`,
          })
        );
      } else if (totalQuantity < quantityMax) {
        form.setFieldValue(
          `lotes.${form.values.lotes.length}.notaFiscalEntradaItemId`,
          itemId
        );
        form.setFieldValue(
          `lotes.${form.values.lotes.length}.quantidade`,
          quantityMax - totalQuantity
        );
        form.setFieldValue(
          `lotes.${form.values.lotes.length}.unidadeMedidaId`,
          form.values.lotes[lotIndex].unidadeMedidaId
        );
      } else {
        setProductExcess(0);
        setProductDeficit(0);
      }
    });
  };

  const deleteRow = (lotIndex: number, quantity: number) => {
    const allProductLots = form.values.lotes.filter(
      (e: any, indexLotForm: number) => {
        return (
          e.notaFiscalEntradaItemId === itemId && indexLotForm !== lotIndex
        );
      }
    );

    let totalQuantity = 0;
    allProductLots.forEach((e: any) => {
      totalQuantity += e.quantidade;
    });
    totalQuantity = totalQuantity - quantity;

    totalQuantity = totalQuantity + quantity;

    if (totalQuantity < quantityMax) {
      setProductDeficit(quantityMax - totalQuantity);
    }
  };

  useEffect(() => {
    if (productDeficit > 0) {
      setProductExcess(0);

      setQuantityErrorMessage(
        t('purchasing.invoice.registerLots.deficitError', {
          deficit: `${formatQuantityExcess(
            productDeficit
          )} ${currentUnitAbbreviation}`,
        })
      );
    }
  }, [productDeficit]);

  return {
    productExcess,
    productDeficit,
    quantityErrorMessage,
    calculateExcessOrDeficit,
    deleteRow,
  };
};
