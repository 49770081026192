//@ts-nocheck
import * as yup from 'yup';
import i18n from '@/Components/i18n';
import { cnpj, cpf } from 'cpf-cnpj-validator';

yup.addMethod(yup.string, 'cnpj', function (message) {
  return this.test('validar-cnpj', message, function (value) {
    if (!value) return true;

    return cnpj.isValid(value);
  });
});

yup.addMethod(yup.string, 'cpf', function (message) {
  return this.test('validar-cpf', message, function (value) {
    if (!value) return true;

    return cpf.isValid(value);
  });
});

export const InfoCardSchema = (tipoPessoa: number) =>
  yup.object().shape({
    tipoFornecedorId: yup
      .number()
      .required(
        i18n.t('purchasing.provider.create.errors.requiredProviderType')
      ),
    nome: yup
      .string()
      .required(
        i18n.t('purchasing.provider.create.errors.requiredName')
      ),
    razaoSocial: yup
      .string()
      .nullable()
      .when('tipoPessoa', {
        is: 1,
        then: yup.string().required(i18n.t('purchasing.provider.create.errors.social')),
        otherwise: yup.string().notRequired()
      }),
    cnpj: yup
      .string()
      .nullable()
      .cnpj(i18n.t('purchasing.provider.create.errors.requiredCnpj'))
      .when('tipoPessoa', {
        is: 1,
        then: yup.string().required(i18n.t('purchasing.provider.create.errors.requiredCnpj')),
        otherwise: yup.string().notRequired()
      }),
    cpf: yup
      .string()
      .nullable()
      .cpf(i18n.t('purchasing.provider.create.errors.requiredCpf'))
      .when('tipoPessoa', {
        is: 0,
        then: yup.string().required(i18n.t('purchasing.provider.create.errors.requiredCpf')),
        otherwise: yup.string().notRequired()
      }),
    observacao: yup
      .string()
  });
