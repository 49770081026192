import { useState } from 'react';

export const usePrescriptionStates = () => {
  const [dateGreaterThanCurrentDay, setDateGreaterThanCurrentDay] =
    useState(false);

  return {
    dateGreaterThanCurrentDay,
    setDateGreaterThanCurrentDay,
  };
};
