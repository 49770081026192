import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { GET_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { IGetClientAdressResponse } from '@/Data/Interfaces/response/Person/Client';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';

interface IuseSelectedAddressCardCallAPI {
  saleOrder?: IGetSaleOrderResponse;
}
export const useSelectedAddressCardCallAPI = ({
  saleOrder,
}: IuseSelectedAddressCardCallAPI) => {
  const { data: addressClient } =
    useControllerQueryApiHook<IGetClientAdressResponse>({
      uniqId: GET_CLIENT,
      entityApi: ClientAPI.getClientAddress,
      externalIds: [saleOrder?.clienteEnderecoExternalId],
    });
  return { addressClient };
};
