import { FC, ReactNode, useEffect, useState } from 'react';
import { Checkbox } from '../StateInputs/Checkbox';
import { Text, Icon } from '../';
import { Divflex } from '@/Layouts/DivWhithFlex';

import styles from './CollapseCard.module.scss';

interface ICollapseCard {
  title?: string;
  subTitles?: string;

  children?: ReactNode;
  onCheck?: (value: boolean) => void;
}

export const CollapseCard: FC<ICollapseCard> = ({
  title,
  subTitles,
  children,
  onCheck,
}) => {
  const [open, setOpen] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(
      document.getElementById('Content-Children-Collapse-Card')?.clientHeight ??
        0
    );
  }, []);

  useEffect(() => {}, []);
  return (
    <div
      className={styles['container']}
      style={{
        maxHeight: open ? contentHeight + 36 : '36px',
      }}
    >
      <Divflex>
        <Divflex className={styles['title-div']}>
          <Checkbox
            checked={checkbox}
            onChange={() => {
              onCheck && onCheck(!checkbox);
              setCheckbox((value) => !value);
            }}
          />
          <Text type="ui-tiny-bold" children={title} color="text-50" />
          <Text type="link" children={subTitles} color="text-300" />
          <Icon icon="exclamation-upside-down" color="text-300" size="SM" />
        </Divflex>
        <Icon
          className={styles[`chevron-open-${open}`]}
          icon={'chevron-down'}
          size="M"
          color="text-50"
          onClick={() => setOpen((value) => !value)}
        />
      </Divflex>
      <div id="Content-Children-Collapse-Card">{children}</div>
    </div>
  );
};
