import { Dropdown, TextInput } from '@/Components';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../StorageLocation.module.scss';
import { useCreateCallAPI } from '../../../Hooks/useCreateCallAPI';
import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';

export const RowDescription: FC = ({}) => {
  const { t } = useTranslation();

  const { listCompanysDropdown, isCompanyLoading } = useCreateCallAPI({ t });

  return (
    <Row gutter={[16, 0]} justify="space-between">
      <Col span={12}>
        <TextInput
          className={styles['text-input']}
          name="descricao"
          label={t('supply.supplyPlaces.create.name')}
          placeHolder={t('supply.supplyPlaces.create.namePlaceholder')}
          required
        />
      </Col>
      <Col span={12}>
        <Dropdown
          items={listCompanysDropdown?.data?.map((value: IListCompanyData) => ({
            id: value.externalId,
            label: value.nomeFantasia,
          }))}
          isLoading={isCompanyLoading}
          disabled={listCompanysDropdown?.data?.length === 1}
          name="empresaId"
          label={t('supply.supplyPlaces.create.company')}
          required
          placeHolder={t('supply.supplyPlaces.create.companyPlaceholder')}
        />
      </Col>
    </Row>
  );
};
