export const useEnableDraft = () => {
  const requiredFields = [
    'numero',
    'serie',
    'naturezaOperacaoId',
    'dataEmissao',
    'dataEntrega',
    'fornecedorExternalId',
    'tipoFreteId',
    'itens',
  ];

  const itemFields = [
    'produtoExternalId',
    'ncmId',
    'cstCsosnId',
    'cfopId',
    'valorUnitario',
    'quantidadeComprada',
  ];

  const enableDraft = (values: any) => {
    let enable = true;
    requiredFields.forEach((field: string) => {
      if (!values[field]) enable = false;
    });

    if (enable) {
      values.itens.forEach((item: any) => {
        itemFields.forEach((field: string) => {
          if (!item[field]) enable = false;
        });
      });
    }

    return enable;
  };

  return { enableDraft };
};
