import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '../../../../../Components';
import { CreateSchema } from './CreateForm.schema';
import _ from 'lodash';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { RowDescription } from './Components/Rows/DescriptionRow';
import { OtherRow } from './Components/Rows/OtherRow';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCheckNameDuplicationAPI } from './Hooks/useCheckNameDuplicationAPI';

export const StorageLocation: React.FC = () => {
  const { t } = useTranslation();

  const { selectedOptionsStock, setSelectedOptionsStock } = useCreateStates();

  const { checkNameDuplications } = useCheckNameDuplicationAPI();

  const {
    createStorageLocation,
    listCompanysDropdown,
    isLoadingCreateStorageLocation,
  } = useCreateCallAPI({
    t,
  });

  const { onSubmit } = useCreateFunctions({
    createStorageLocation,
    selectedOptionsStock,
  });

  return (
    <Form
      initialValues={{
        empresaId: listCompanysDropdown?.data
          ? listCompanysDropdown.data[0].externalId
          : undefined,
      }}
      onSubmit={onSubmit}
      schema={CreateSchema(checkNameDuplications, t)}
      validateOnChange
    >
      {(props) => (
        <>
          <Card
            title={t('supply.supplyPlaces.create.detail')}
            withoutCollapse={true}
          >
            <RowDescription />
            <OtherRow
              setSelectedOptionsStock={setSelectedOptionsStock}
              selectedOptionsStock={selectedOptionsStock}
            />
          </Card>
          <FooterButtons
            isLoadingCreateStorageLocation={isLoadingCreateStorageLocation}
            props={props}
          />
          <ConnectedFocusError />
        </>
      )}
    </Form>
  );
};

export default StorageLocation;
