import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useInternalDilutionsFactorFunctions } from './Hooks/useInternalDilutionFactorFunctions';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';

export const InternalDilutionFactor: FC = () => {
  const { t } = useTranslation();

  const { onChange } = useInternalDilutionsFactorFunctions();

  return (
    <Col span={6}>
      <TextInputNumber
        name="loteInformacaoTecnica.fatorDiluicaoInterna"
        label={[
          {
            children: t('supply.lot.create.internalDilutionFactor'),
          },
        ]}
        placeHolder={t('supply.lot.create.internalDilutionFactor')}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.internalDilutionFactor'),
        }}
        withoutArrows
        onChange={(x) => onChange(x)}
        withoutMarginBottom
      />
    </Col>
  );
};
