import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { IUseFunctions } from './interfaces';

export const useTransfersFunctions = ({
  index,
  refetchProducts,
  addRow,
  balanceOriginDestiny,
  productSelected,
  setProductSelected,
}: IUseFunctions) => {
  const form = useFormikContext<any>();

  const onChangeProducts = useCallback(
    (_: string, productSelected: IProductGroupListData) => {
      form.setFieldValue(`transfers.${index}.lot`, undefined);

      setProductSelected?.(productSelected);

      refetchProducts({
        search: '',
      });
    },
    [refetchProducts, form.values]
  );

  const onSearchProduct = useCallback(
    (search: string | undefined) => {
      refetchProducts({
        search: search,
      });
    },
    [refetchProducts]
  );

  const onChangeLots = useCallback(() => {
    form.setFieldValue(
      `transfers.${index}.unidadeMedidaId`,
      productSelected?.unidadeMedidaId
    );

    form.setFieldValue(
      `transfers.${index}.originFunds`,
      balanceOriginDestiny?.saldoLoteOrigem
    );

    addRow();
  }, [balanceOriginDestiny, productSelected, addRow]);

  return {
    onChangeProducts,
    onSearchProduct,
    onChangeLots,
  };
};
