import { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Icon, InputWithSearch, Tooltip } from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useCreateCallAPI } from '../Hooks/useCreateCallAPI';
import { useCreateFunctions } from '../Hooks/useCreateFunctions';
import { useTransfersMemorizations } from './Hooks/useTransfersMemorizations';
import { useTransfersFunctions } from './Hooks/useTransfersFunctions';
import { ITransferLine } from './interfaces';
import { useTransfersStates } from './Hooks/useTransfersStates';
import { IListUnitMeasurementByClassData } from '@/Services/Supply/UnitMeasurement/ListUnitByClass.service';

export const TransferLine: FC<ITransferLine> = ({
  index,
  originSupplyPlaceId,
  destinySupplyPlaceId,
  totalRows,
  uuidV4,
  setProductRowAmount,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const selectedProductId = form.values?.transfers[index]?.product;
  const selectedLotId = form.values?.transfers[index]?.lot;

  const { productSelected, setProductSelected } = useTransfersStates();

  const {
    convertUnitOfMeasureAsync,
    fetchLotsNewPage,
    fetchNewProductPage,
    lotLoading,
    lotsList,
    lotsRefetch,
    productsList,
    balanceOriginDestiny,
    unitsMeasurements,
    isLoadingProduct,
    refetchProducts,
  } = useCreateCallAPI({
    selectedLotId,
    originSupplyPlaceId,
    destinySupplyPlaceId,
    form,
    index,
    selectedProductId,
    productSelected,
  });

  const { addRow, deleteRow, onUnitOfMeasureChange, selectedUnitOfMeasure } =
    useCreateFunctions({
      index,
      totalRows,
      setProductRowAmount,
      uuidV4,
      form,
      balanceOriginDestiny,
      destinySupplyPlaceId,
      lotsRefetch,
      originSupplyPlaceId,
      convertUnitOfMeasureAsync,
      unitsMeasurements,
    });

  const { onChangeProducts, onSearchProduct, onChangeLots } =
    useTransfersFunctions({
      index,
      refetchProducts,
      addRow,
      balanceOriginDestiny,
      productSelected,
      setProductSelected,
    });

  const { productsItems, lotsItems } = useTransfersMemorizations({
    productsList,
    lotsList,
    index,
    unitsMeasurements,
    onUnitOfMeasureChange,
    form,
  });

  useEffect(() => {
    lotsRefetch({
      pageSize: 50,
      filter: [
        {
          filterName: 'produtoExternalId',
          value: selectedProductId,
        },
        {
          filterName: 'localEstoqueExternalId',
          value: originSupplyPlaceId,
        },
      ],
    });
  }, [selectedProductId, originSupplyPlaceId]);

  return (
    <Row
      justify="space-between"
      style={{ marginBottom: '16px', display: 'flex', flexWrap: 'nowrap' }}
      gutter={[8, 0]}
    >
      <Col span={6}>
        <InputWithSearch
          name={`transfers.${index}.product`}
          label={index === 0 ? t('supply.transfers.fields.product') : ''}
          required
          onChange={onChangeProducts}
          onClear={() => {
            form.setFieldValue(`transfers.${index}.lot`, undefined);
            form.setFieldValue(`transfers.${index}.originFunds`, undefined);
            form.setFieldValue(`transfers.${index}.qntTransfers`, undefined);
            form.setFieldValue(`transfers.${index}.destinyFunds`, undefined);
          }}
          placeHolder={t('supply.transfers.placeHolders.product')}
          withoutMarginBottom
          onScrollEnd={fetchNewProductPage}
          items={productsItems}
          isLoading={isLoadingProduct}
          onSearch={onSearchProduct}
        />
      </Col>
      <Col span={5}>
        <InputWithSearch
          name={`transfers.${index}.lot`}
          label={index === 0 ? t('supply.transfers.fields.lot') : ''}
          required
          withoutMarginBottom
          placeHolder={t('supply.transfers.placeHolders.lot')}
          items={lotsItems}
          onScrollEnd={fetchLotsNewPage}
          disabled={
            !form.values?.transfers[index]?.product || !originSupplyPlaceId
              ? true
              : false
          }
          onChange={onChangeLots}
          isLoading={lotLoading}
          onClear={() => {
            form.setFieldValue(`transfers.${index}.originFunds`, undefined);
            form.setFieldValue(`transfers.${index}.qntTransfers`, undefined);
            form.setFieldValue(`transfers.${index}.destinyFunds`, undefined);
          }}
        />
      </Col>
      <Col span={4}>
        <WeightInput
          name={`transfers.${index}.originFunds`}
          label={
            index === 0 ? t('supply.transfers.fields.originFunds') : undefined
          }
          withoutMarginBottom
          disabled
          rightIcon={{
            titleString: selectedUnitOfMeasure?.unidadeAbreviacao || '-',
          }}
        />
      </Col>
      <Col span={4}>
        <Tooltip
          title={t('supply.transfers.toolTips.currentBalanceNegative')}
          showMe={
            form.values.transfers[index]?.qntTransfers >
            form.values.transfers[index]?.initialOriginFunds
          }
        >
          <WeightInput
            name={`transfers.${index}.qntTransfers`}
            label={
              index === 0
                ? t('supply.transfers.fields.qntTransfers')
                : undefined
            }
            disabled={
              !form.values?.transfers[index]?.product ||
              !form.values?.transfers[index]?.lot
            }
            required
            withoutMarginBottom
            dropdownRight={{
              name: `transfers.${index}.unidadeMedidaId`,
              options: unitsMeasurements?.data?.map(
                (x: IListUnitMeasurementByClassData) => ({
                  id: x.id,
                  content: x.unidadeAbreviacao,
                })
              ),
            }}
            error={
              form.values.transfers[index]?.qntTransfers >
              form.values.transfers[index]?.initialOriginFunds
            }
          />
        </Tooltip>
      </Col>
      <Col span={4}>
        <WeightInput
          name={`transfers.${index}.destinyFunds`}
          label={
            index === 0 ? t('supply.transfers.fields.destinyFunds') : undefined
          }
          withoutMarginBottom
          disabled
          rightIcon={{
            titleString: selectedUnitOfMeasure?.unidadeAbreviacao || '-',
          }}
        />
      </Col>
      <Col
        style={{
          width: '50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          type="secondary"
          onClick={deleteRow}
          disabled={index === totalRows - 1}
          style={{ marginTop: `${index === 0 && '20px'}` }}
        >
          <Icon
            icon="trash-line"
            size="L"
            style={{
              cursor: index === totalRows - 1 ? 'not-allowed' : 'default',
              color:
                index === totalRows - 1
                  ? 'var(--color-status-disabled-base)'
                  : '',
            }}
          />
        </Button>
      </Col>
    </Row>
  );
};
