import { useState } from 'react';
import { InfoSection } from './Sections/InfoSection';
import { Form } from '@/Components';
import { useGetInvoice } from '../EditRefact/Hooks/useGetInvoice';
import { useParams } from 'react-router';
import { useConfigInitialValue } from '../EditRefact/Hooks/useConfigInitialValue';
import styles from './registerLots.module.scss';
import { LotsSection } from './Sections/LotsSection';
import { useGetLots } from './hooks/useGetLots';
import { useListLots } from './hooks/useListLots';

export const RegisterLotsRefactor: React.FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const [_, setDefaultSupplyPlace] = useState<string>('');
  const { lots: lotsResponse } = useGetLots();
  const { listLots } = useListLots();
  const lots = listLots(lotsResponse ? lotsResponse.itens : []);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [showTechnicalInformation, setShowTechnicalInformation] =
    useState<boolean>(false);

  const { configInvoice } = useConfigInitialValue();
  const { invoice } = useGetInvoice(externalId);
  const initialValue = configInvoice(invoice);

  return (
    <>
      <Form
        initialValues={{
          ...initialValue,
          lotes: lots,
          notaFiscalEntradaExternalId: invoice?.externalId,
        }}
      >
        <InfoSection
          selectSupplyPlace={(supplyPlaceId: string) =>
            setDefaultSupplyPlace(supplyPlaceId)
          }
        />
        <div
          className={` ${
            showTechnicalInformation
              ? styles['showTechnicalCard']
              : styles['hideTechnicalCard']
          }`}
        >
          <LotsSection
            selectedRow={selectedRow}
            toggleShowTechnicalInformation={() =>
              setShowTechnicalInformation(!showTechnicalInformation)
            }
            showTechnicalInformation={showTechnicalInformation}
            selectRowIndex={(index: number) => setSelectedRow(index)}
          />
        </div>
      </Form>
    </>
  );
};
