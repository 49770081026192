import { useTranslation } from 'react-i18next';
import { Text } from '@/Components/Text';
import { Col, Row } from 'antd';
import { Checkbox } from '@/Components';
import { FormikValues, useFormikContext } from 'formik';

interface IDisplayType {
  productMessage?: any;
  typeMessageSelected: number;
}

export const DisplayType = ({
  productMessage,
  typeMessageSelected,
}: IDisplayType) => {
  const { t } = useTranslation();

  const form = useFormikContext<FormikValues>();

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]} style={{ marginTop: '-10px' }}>
        <Col span={24}>
          <div style={{ marginBottom: '10px' }}>
            <Text
              type="ui-tiny-semibold"
              color="text-100"
              children={t(
                'products.productList.productMessage.modalProductLinkedMessage.messageSystemWarning'
              )}
              style={{ fontSize: '12px' }}
            />
          </div>
          <Checkbox
            name="exibeVenda"
            label={t(
              'products.productList.productMessage.modalProductLinkedMessage.checkboxDisplay.checkBox1'
            )}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={
              form.values.mensagemExternalId &&
              productMessage?.exibeVenda &&
              typeMessageSelected === 1
                ? true
                : false
            }
            disabled={typeMessageSelected === 1}
          />
          <Checkbox
            name="exibeRotulagem"
            label={t(
              'products.productList.productMessage.modalProductLinkedMessage.checkboxDisplay.checkBox2'
            )}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={
              form.values.mensagemExternalId &&
              productMessage?.exibeRotulagem &&
              typeMessageSelected === 1
                ? true
                : false
            }
            disabled={typeMessageSelected === 1}
          />
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Text
              type="ui-tiny-semibold"
              color="text-100"
              children={t(
                'products.productList.productMessage.modalProductLinkedMessage.messagePrintWarning'
              )}
              style={{ fontSize: '12px' }}
            />
          </div>
          <Checkbox
            name="exibeFichaPesagem"
            label={t(
              'products.productList.productMessage.modalProductLinkedMessage.checkboxDisplay.checkBox3'
            )}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={
              form.values.mensagemExternalId &&
              productMessage?.exibeFichaPesagem &&
              typeMessageSelected === 1
                ? true
                : false
            }
            disabled={typeMessageSelected === 1}
          />
          <Checkbox
            name="exibeImpressaoRotulo"
            label={t(
              'products.productList.productMessage.modalProductLinkedMessage.checkboxDisplay.checkBox4'
            )}
            style={{
              fontWeight: 'var(--font-weight-light)',
              fontSize: 'var(--font-size-xs)',
              marginBottom: 5,
            }}
            checked={
              form.values.mensagemExternalId &&
              productMessage?.exibeImpressaoRotulo &&
              typeMessageSelected === 1
                ? true
                : false
            }
            disabled={typeMessageSelected === 1}
          />
        </Col>
      </Row>
    </div>
  );
};
