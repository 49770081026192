import { Table } from '@/Components';
import StorageUtils from '@/Utils/StorageUtils';
import { inventoryColumn } from './inventory.columns';
import { useTranslation } from 'react-i18next';
import { useInventoryListMemorizations } from './hooks/useInventoryListMemorizations';
import { useInventoryListCallAPI } from './hooks/useInventoryListCallAPI';
import { PageLoading } from '@/Layouts/PageLoading';

export const ListInventory: React.FC = () => {
  const { t } = useTranslation();
  const { headerOptions, rowOptions } = useInventoryListMemorizations();

  const { inventoryList } = useInventoryListCallAPI();

  const preferences = StorageUtils.getPreference();

  return (
    <PageLoading loading={inventoryList.isLoading}>
      <Table
        headerOptions={headerOptions}
        columns={inventoryColumn(t, preferences)}
        pagination={inventoryList.data}
        onRefetch={inventoryList.refetch}
        loading={inventoryList.isLoading}
        rowsOptions={rowOptions}
      />
    </PageLoading>
  );
};
