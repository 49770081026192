import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export interface ISupplyPlaceListResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: ISupplyPlaceListData[];
}

export interface ISupplyPlaceListData {
  externalId: string;
  sequenciaGroupTenant: number;
  descricao: string;
  classeProdutoId: number;
  unidadeMedidaId: number;
  classeProdutoDescricao: string;
  valorUnitario: number;
  seVinculoProduto?: boolean;
}

export const ListSupplyPlace = async (
  context: QueryFunctionContext
): Promise<ISupplyPlaceListResponse | undefined> => {
  return await Http.fetcher<ISupplyPlaceListResponse>(
    Http.AddQueryParams(
      '/pharma/estoque/v1/Produto/Listar',
      context.queryKey[1] as IQueryListParams
    )
  );
};
