import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';
import { useProductMemorizations } from './Hooks/useProductMemorizations';
import { useProductFunctions } from './Hooks/useProductFunctions';
import { useFormikContext } from 'formik';

export const Product: FC<IProduct> = ({
  index,
  totalRows,
  setProductRowAmount,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const { productList, fetchNewProductPage, refetchSearchProductPage } =
    useProductCallAPI();

  const { items } = useProductMemorizations({ productList });

  const { addRow, onSearch, selectedItemFromForm } = useProductFunctions({
    index,
    totalRows,
    setProductRowAmount,
    refetchSearchProductPage,
  });

  useEffect(() => {
    if (
      form.values.pedidoCompraItens[index] &&
      form.values.pedidoCompraItens[index].produtoExternalId === null
    ) {
      form.setFieldValue(`pedidoCompraItens.${index}.quantidade`, undefined);
      form.setFieldValue(
        `pedidoCompraItens.${index}.produtoExternalId`,
        undefined
      );
      form.setFieldValue(`pedidoCompraItens.${index}.precoUnitario`, undefined);
      form.setFieldValue(
        `pedidoCompraItens.${index}.unidadeMedidaId`,
        undefined
      );
      form.setFieldValue(
        `pedidoCompraItens.${index}.valorDescontoUnitario`,
        undefined
      );
    }
  }, [form.values.pedidoCompraItens]);

  return (
    <>
      <InputWithSearch
        name={`pedidoCompraItens.${index}.produtoExternalId`}
        items={items}
        onChange={() => addRow()}
        label={
          index === 0
            ? t('purchasing.purchaseOrder.create.productsCardField.product')
            : ''
        }
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.product'
        )}
        onScrollEnd={fetchNewProductPage}
        withoutMarginBottom
        onSearch={onSearch}
        selectedItemFromForm={selectedItemFromForm}
      />
    </>
  );
};
