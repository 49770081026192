import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export const useConfigProducts = () => {
  const [products, setProducts] = useState<any[]>([]);
  const form: any = useFormikContext();
  const items = form.values.itens;

  useEffect(() => {
    const mappedProducts = items.map((item: any) => {
      return {
        productDescription: item.produtoDescricao,
        quantity: item.quantidadeComprada,
        unityId: item.unidadeMedidaId,
        unityAbbreviation: item.unidadeMedidaAbreviacao,
        unitType: item.tipoUnidade,
        key: item.notaFiscalEntradaItemId,
        itemId: item.notaFiscalEntradaItemId,
        productClass: item.classeProdutoDescricao,
      };
    });

    setProducts(mappedProducts);
  }, [form.values.itens]);

  return { products };
};
