import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import { cnpj } from 'cpf-cnpj-validator';
export const CreateLegalClientSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    fantasyName: yup
      .string()
      .nullable()
      .required(i18n.t(`client.error.${'requiredFantasyName'}`)),

    cnpj: yup
      .string()
      .nullable()
      .required(i18n.t('common.requiredCNPJ'))
      .test(
        'is-valid-cnpj',
        i18n.t('purchasing.provider.create.errors.requiredCnpj'),
        (value) => (value ? cnpj.isValid(value) : false)
      )
      .test(
        'checkDuplications',
        t('purchasing.provider.create.errors.cnpjAlreadyUsed'),
        (value) => {
          return new Promise((resolve) => {
            validateName(value, (success) => {
              resolve(success);
            });
          });
        }
      ),
  });
