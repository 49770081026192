import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@/Components';
import { useFormikContext } from 'formik';
import { NumberInput as StateNumberInput } from '@/Components/StateInputs/NumberInput';
import { IProductLine } from './interfaces';
import { Product } from './Components/Product';
import { UnitaryValue } from './Components/UnitaryValue';
import { UnitaryDiscount } from './Components/UnitaryDiscount';
import { Amount } from './Components/Amount';
import { useProductLineStates } from './Hooks/useProductLineStates';
import { useProductLineMemorizations } from './Hooks/useProductLineMemorizations';
import { useProductLineFunctions } from './Hooks/useProductLineFunctions';
import { useGetRowTotalValue } from '@/Pages/Sectors/Order/Purchase/Edit/EditOrder/Cards/ProductCard/Components/ProductLine/Hooks/useGetRowTotalValue';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';

export const ProductLine: FC<IProductLine> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const calculateTotalValue = useGetRowTotalValue();

  const { setTotal } = useProductLineStates();

  const { formValues } = useProductLineMemorizations({
    index,
  });

  const { getTotalValue } = useProductLineFunctions({
    formValues,
  });

  return (
    <Row justify="start" style={{ marginBottom: '16px' }} gutter={[16, 0]}>
      <Col span={6}>
        <Product
          index={index}
          totalRows={totalRows}
          setProductRowAmount={setProductRowAmount}
        />
      </Col>
      <Col span={4}>
        <UnitaryValue
          index={index}
          formValues={formValues}
          setTotal={setTotal}
          getTotalValue={getTotalValue}
        />
      </Col>
      <Col span={4}>
        <UnitaryDiscount
          index={index}
          formValues={formValues}
          setTotal={setTotal}
          getTotalValue={getTotalValue}
        />
      </Col>
      <Col span={4}>
        <Amount
          index={index}
          formValues={formValues}
          setTotal={setTotal}
          getTotalValue={getTotalValue}
        />
      </Col>
      <Col span={4}>
        <CurrencyInput
          name={`pedidoCompraItens.${index}.total`}
          label={
            index === 0
              ? t('purchasing.purchaseOrder.create.productsCardField.total')
              : ''
          }
          placeHolder={t(
            'purchasing.purchaseOrder.create.productsCardPlaceHolders.total'
          )}
          withoutMarginBottom={true}
          disabled
          value={
            calculateTotalValue?.(index)?.toString().replace('.', ',') || ''
          }
        />
      </Col>
      <Col
        style={{
          width: '50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          type="secondary"
          onClick={() => onDelete(index)}
          disabled={index === form?.values?.pedidoCompraItens?.length}
          style={{ marginTop: `${index === 0 ? '20px' : 0}` }}
        >
          <Icon
            icon="trash-line"
            size="L"
            disabled={index === form?.values?.pedidoCompraItens?.length}
          />
        </Button>
      </Col>
    </Row>
  );
};
