import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_FOR_DROPDOWN_GROUPS } from '@/ControllerApiHook/UniqIds/Supply/InventoryKeys';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { useFormikContext } from 'formik';

export const useListGroups = () => {
  const form: any = useFormikContext();
  const supplyPlace = form.values.localEstoque;
  const {
    data,
    fetchNewPage: fetchNewGroupsPage,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_GROUPS,
    entityApi: InventoryAPI.listDropdownGroups,
    autoIncrementCustomId: 'grupoExternalId',
    autoIncrement: true,
    pagination: {
      pageIndex: 1,
      pageSize: 10,
    },
    enabled: !!supplyPlace,
  });

  const refetchPage = (supplyPlaceId: string) => {
    if (!supplyPlaceId) return;
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [{ filterName: 'LocalEstoqueExternalId', value: supplyPlaceId }],
    });
  };

  return {
    groupsData:
      data && data.data
        ? data.data.map((e: any) => {
            return {
              id: e.grupoExternalId,
              label: e.grupoDescricao,
            };
          })
        : [],
    fetchNewGroupsPage,
    refetchPage,
  };
};
