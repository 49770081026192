export const contactEN = {
  contacts: 'Contacts',
  contact: 'Contact',
  contactType: 'Contact Type',
  requiredTypeContact: 'Contact type is required',
  requiredContact: 'Contact is required',

  addContact: 'Add contact',
  editContact: 'Edit contact',

  observation: 'Observation',
  observationPlaceholder: 'Add a note...',

  contactEmpyt: 'No contact registered',

  deleteTitleQuestion: 'Delete contact?',
  deleteQuestion: 'Are you sure you want to delete the contact {{contato}}?',
  areYouSureDelete: 'Are you sure you want to delete this contact?',
  messages: {
    createContact: 'Contact created!',
    changeContact: 'Contact edited!',
    deleteContact: 'Contact deleted!',
    changeFavoriteContact: 'Updated favorite contact!',
  },

  descriptions: {
    email: 'E-mail',
    phone: 'Phone',
    mobile: 'Cell Phone',
    whatsapp: 'Whatsapp',
    telegran: 'Telegran',
    facebook: 'Facebook',
    instagram: 'Instagram',
    skype: 'Skype',
  },
};

export const contactPT = {
  contacts: 'Contatos',
  contact: 'Contato',
  contactType: 'Tipo de Contato',
  requiredTypeContact: 'Tipo de contato é obrigatório',
  requiredContact: 'Contato é obrigatório',

  addContact: 'Adicionar contato',
  editContact: 'Editar contato',

  observation: 'Observações',
  observationPlaceholder: 'Adicione uma observação...',

  contactEmpyt: 'Nenhum endereço cadastrado',

  deleteTitleQuestion: 'Excluir contato?',
  deleteQuestion: 'Você tem certeza que deseja excluir o contato {{contato}}?',

  areYouSureDelete: 'Você tem certeza que deseja excluir este contato?',
  messages: {
    createContact: 'Contato criado!',
    changeContact: 'Contato editado!',
    deleteContact: 'Contato deletado!',
    changeFavoriteContact: 'Contato favorito atualizado!',
  },

  descriptions: {
    email: 'E-mail',
    phone: 'Telefone',
    mobile: 'Celular',
    whatsapp: 'Whatsapp',
    telegran: 'Telegran',
    facebook: 'Facebook',
    instagram: 'Instagram',
    skype: 'Skype',
  },
};
