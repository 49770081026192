import { IMenuItem } from '../../Components/SideMenu/MenuItem';
import { Color } from '../../Utils/ColorUtils';
import { IconName } from '../Icon/IconClasses';
import { PermissionEnum } from '../../Data/Interfaces/model/permissionEnum';

export interface IContext {
  id: string;
  icon: IconName;
  name: string;
  backgroundColor: Color;
}

export interface IMenu {
  context: IContext;
  items: IMenuItem[];
}

export const getMenuById = (contextId?: string) => {
  if (!contextId) return SideMenuItems[0];
  return SideMenuItems.find((x) => x.context.id === contextId);
};

export const SideMenuItems: IMenu[] = [
  {
    context: {
      id: 'Administrativo',
      icon: 'setting',
      name: 'sideMenu.administrative',
      backgroundColor: 'primary-base',
    },
    items: [
      {
        id: 'user',
        icon: 'user',
        name: 'sideMenu.administrativeItems.users',
        link: '/admin/user',
        permission: {
          permission: 1001,
          tooltip: 'noPermission.administrative.users.route',
        },
      },
      {
        id: 'users',
        icon: 'users',
        name: 'sideMenu.administrativeItems.userGroups',
        link: '/admin/user-group',
        permission: {
          permission: 1009,
          tooltip: 'noPermission.administrative.userGroups.route',
        },
      },
      {
        id: 'company',
        icon: 'buildings',
        name: 'sideMenu.administrativeItems.company',
        link: '/admin/company',
        permission: {
          permission: 1016,
          tooltip: 'noPermission.administrative.company.route',
        },
      },
    ],
  },
  {
    context: {
      id: 'Admin',
      icon: 'simple-logo',
      name: 'sideMenu.adminBootis',
      backgroundColor: 'primary-base',
    },
    items: [
      {
        id: 'company',
        icon: 'Building',
        name: 'sideMenu.adminBootisItems.company',
        link: '/admin-bootis/company',
      },
      {
        id: 'companys',
        icon: 'buildings',
        name: 'sideMenu.adminBootisItems.companyGroups',
        link: '/admin-bootis/conglomerate',
      },
    ],
  },
  {
    context: {
      id: 'Estoque',
      icon: 'archive',
      name: 'sideMenu.inventory',
      backgroundColor: 'black',
    },
    items: [
      {
        id: 'Box',
        icon: 'Box',
        name: 'sideMenu.products',
        link: '/inventory/products',
        shouldRedirect: true,
        permission: {
          permission: 3001,
          tooltip: 'noPermission.supply.products.route',
        },
        children: [
          {
            id: 'tag',
            icon: 'tag',
            name: 'sideMenu.productsItems.group',
            link: '/inventory/groups',
            permission: {
              permission: 3008,
              tooltip: 'noPermission.supply.groups.route',
            },
          },
          {
            id: 'tags',
            icon: 'tags',
            name: 'sideMenu.productsItems.subGroup',
            link: '/inventory/sub-groups',
            permission: {
              permission: 3013,
              tooltip: 'noPermission.supply.subGroups.route',
            },
          },
        ],
      },
      {
        id: 'cube',
        icon: 'cube',
        name: 'sideMenu.inventoryItems.lots',
        link: '/inventory/lot',
        permission: {
          permission: 2001,
          tooltip: 'noPermission.supply.lot.route',
        },
      },
      {
        id: 'cubes',
        icon: 'cubes',
        name: 'sideMenu.inventoryItems.stockLocations',
        link: '/inventory/supply-places',
        permission: {
          permission: 2007,
          tooltip: 'noPermission.supply.supplyPlaces.route',
        },
      },
      {
        id: 'inventory',
        icon: 'cube',
        name: 'sideMenu.inventoryItems.inventory',
        link: '/inventory/stock-taking',
        permission: {
          permission: 2013,
          tooltip: 'noPermission.supply.transfers.route',
        },
      },
      {
        id: 'connection',
        icon: 'connection',
        name: 'sideMenu.inventoryItems.transfers',
        link: '/inventory/transfers',
        permission: {
          permission: 2013,
          tooltip: 'noPermission.supply.transfers.route',
        },
      },
      {
        id: 'bin-full',
        icon: 'bin-full',
        name: 'sideMenu.inventoryItems.losses',
        link: '/inventory/losses',
        permission: {
          permission: 2016,
          tooltip: 'noPermission.supply.losses.route',
        },
      },
      {
        id: 'document',
        icon: 'document',
        name: 'sideMenu.inventoryItems.balanceAdjustment',
        link: '/inventory/balance-adjustments',
        permission: {
          permission: 2020,
          tooltip: 'noPermission.supply.balanceAdjustments.route',
        },
      },
      {
        id: 'invoice-list',
        icon: 'invoiceList',
        name: 'sideMenu.inventoryItems.movimentation',
        link: '/inventory/movimentation',
      },
      {
        id: 'product-message',
        icon: 'landscape-text-file',
        name: 'sideMenu.inventoryItems.productMensage',
        link: '/inventory/product-message',
        permission: {
          permission: PermissionEnum.Estoque_MensagemProduto_VerLista,
          tooltip: 'noPermission.supply.messageProduct.route',
        },
      },
    ],
  },
  {
    context: {
      id: 'Produção',
      icon: 'conical-flask-gear',
      name: 'sideMenu.production',
      backgroundColor: 'users02',
    },
    items: [
      {
        id: 'document',
        icon: 'document',
        name: 'sideMenu.productionItems.recipes',
        link: '/recipes',
        permission: {
          permission: 6035,
          tooltip: 'noPermission.production.prescription.route',
        },
      },
      {
        id: 'search-action',
        icon: 'search-action',
        name: 'sideMenu.productionItems.doubleCheck',
        link: '/double-check',
        permission: {
          // TODO: colocar a permissao correta
          permission: 1001,
          tooltip: 'noPermission.administrative.users.route',
        },
      },
      {
        id: 'add-book',
        icon: 'add-book',
        name: 'sideMenu.productionItems.records',
        link: '/records',
        shouldRedirect: false,
        children: [
          {
            id: 'conical-flask',
            icon: 'conical-flask',
            name: 'sideMenu.productionItems.laboratory',
            link: '/production/labs',
            permission: {
              permission: PermissionEnum.Producao_Laboratorio_VerLista,
              tooltip: 'noPermission.production.laboratory.list',
            },
          },
          {
            id: 'edit-file',
            icon: 'edit-file',
            name: 'sideMenu.productionItems.defaultFormula',
            link: '/production/standard-formula',
            permission: {
              permission: PermissionEnum.Producao_FormulaPadrao_VerLista,
              tooltip: 'noPermission.production.standardFormula.list',
            },
          },
          {
            id: 'pharmaceutical-form',
            icon: 'pharmaceutical-form',
            name: 'sideMenu.productionItems.form',
            link: '/production/dosage-form',
            permission: {
              permission: PermissionEnum.Producao_FormaFarmaceutica_VerLista,
              tooltip: 'noPermission.production.pharmaceuticalForm.route',
            },
          },
          {
            id: 'dosage',
            icon: 'dosage',
            name: 'sideMenu.productionItems.dosage',
            link: '/production/posology',
            permission: {
              permission: PermissionEnum.Producao_Posologia_VerLista,
              tooltip: 'noPermission.production.posology.list',
            },
          },
          {
            id: 'packaging-classification',
            icon: 'packaging-classification',
            name: 'sideMenu.productionItems.packagingClassification',
            link: '/production/packing-classification',
            permission: {
              permission:
                PermissionEnum.Producao_ClassificacaoEmbalagem_VerLista,
              tooltip: 'noPermission.production.packagingClassification.list',
            },
          },
          {
            id: 'capsules-colors',
            icon: 'capsules-colors',
            name: 'sideMenu.productionItems.capsulesColors',
            link: '/production/capsule-colors',
            permission: {
              permission: PermissionEnum.Producao_CapsulaCor_VerLista,
              tooltip: 'noPermission.production.capsuleColors.route',
            },
          },
        ],
      },
    ],
  },
  {
    context: {
      id: 'Compras',
      icon: 'shopping-bag',
      name: 'sideMenu.purchases',
      backgroundColor: 'users03',
    },
    items: [
      {
        id: 'shipping-truck',
        icon: 'shipping-truck',
        name: 'sideMenu.purchasesItems.providers',
        link: '/order/supplier',
        permission: {
          permission: PermissionEnum.Compras_Fornecedores_VerLista,
          tooltip: 'noPermission.purchasing.supplier.route',
        },
      },
      {
        id: 'basket',
        icon: 'basket',
        name: 'sideMenu.purchasesItems.purchaseRequests',
        link: '/order/purchase',
        permission: {
          permission: PermissionEnum.Compras_PedidoCompra_VerLista,
          tooltip: 'noPermission.purchasing.purchaseOrder.route',
        },
      },
      {
        id: 'invoice-list',
        icon: 'invoiceList',
        name: 'sideMenu.purchasesItems.entryInvoices',
        link: '/order/invoice',
        permission: {
          permission: PermissionEnum.Compras_NotaFiscalEntrada_VerLista,
          tooltip: 'noPermission.purchasing.invoice.route',
        },
      },
    ],
  },
  {
    context: {
      id: 'Vendas',
      icon: 'invoice',
      name: 'sideMenu.sales',
      backgroundColor: 'users13',
    },
    items: [
      {
        id: 'tickets',
        icon: 'chat-bubble',
        name: 'sideMenu.salesItems.service',
        link: '/sales/tickets',
        permission: {
          permission: PermissionEnum.Vendas_Atendimentos_VerLista,
          tooltip: 'noPermission.sale.service.route',
        },
      },
      {
        id: 'sale-order',
        icon: 'cart',
        name: 'sideMenu.salesItems.saleOrder',
        link: '/sales/order',
        permission: {
          permission: PermissionEnum.Vendas_PedidosVenda_VerLista,
          tooltip: 'noPermission.sale.order.route',
        },
      },
      {
        id: 'client',
        icon: 'user',
        name: 'sideMenu.salesItems.client',
        link: '/sales/customers',
        permission: {
          permission: PermissionEnum.Vendas_Clientes_VerLista,
          tooltip: 'noPermission.sale.client.route',
        },
      },

      {
        id: 'prescriber',
        icon: 'prescriber',
        name: 'sideMenu.salesItems.prescribers',
        link: '/sales/prescribers',
        shouldRedirect: true,
        permission: {
          permission: PermissionEnum.Vendas_Prescritores_VerLista,
          tooltip: 'noPermission.sale.prescriber.route',
        },
        children: [
          {
            id: 'tag',
            name: 'sideMenu.salesItems.prescribersItems.specialties',
            link: '/sales/expertise',
            permission: {
              permission:
                PermissionEnum.Vendas_EspecialidadePrescritor_VerLista,
              tooltip: 'noPermission.sale.specialties.route',
            },
          },
        ],
      },
    ],
  },
  {
    context: {
      id: 'Financeiro',
      icon: 'cash',
      name: 'sideMenu.financial',
      backgroundColor: 'users11',
    },
    items: [],
  },
  {
    context: {
      id: 'Reports',
      icon: 'document',
      name: 'sideMenu.reports',
      backgroundColor: 'status-danger-base',
    },
    items: [],
  },
];
