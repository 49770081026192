import { Dropdown, Tooltip } from '@/Components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { IGetPharmaceuticalForm } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useFormikContext } from 'formik';
import { calculationTypeDropdown } from '../../../../utils';
import { DescriptionPharmaceuticalFormInput } from './pharmaceuticalFormDescriptionDetails';

interface IRowDescriptionPosology {
  disabled: boolean;
  pharmaceuticalForm: IGetPharmaceuticalForm | undefined;
  calculationType: any;
}

export const RowDescription: FC<IRowDescriptionPosology> = ({
  disabled,
  pharmaceuticalForm,
  calculationType,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={2}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousOrder'
          )} ${pharmaceuticalForm?.ordem}`}
          targetOffset={[0, -12]}
          showMe={!disabled && pharmaceuticalForm?.ordem !== form.values.ordem}
        >
          <TextInputNumber
            name="ordem"
            placeHolder={t(
              'production.pharmaceuticalForm.create.orderPlaceholder'
            )}
            formaterNumberZeroMask={3}
            required={!disabled}
            withStatusIcon
            disabled={disabled}
            label={t('production.pharmaceuticalForm.create.order')}
            withoutArrows={true}
          />
        </Tooltip>
      </Col>
      <Col span={11}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousDescription'
          )} ${pharmaceuticalForm?.descricao}`}
          targetOffset={[0, -12]}
          showMe={
            !disabled && pharmaceuticalForm?.descricao !== form.values.descricao
          }
        >
          <DescriptionPharmaceuticalFormInput
            disabled={disabled}
          />
        </Tooltip>
      </Col>
      <Col span={6}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousCalculationType'
          )} ${calculationType?.name}`}
          targetOffset={[0, -12]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.tipoCalculo !== form.values.tipoCalculo
          }
        >
          <Dropdown
            name="tipoCalculo"
            items={calculationTypeDropdown?.map((values) => ({
              id: values.id,
              label: values.name,
            }))}
            placeHolder={t(
              'production.pharmaceuticalForm.create.calculationTypePlaceholder'
            )}
            required={!disabled}
            withStatusIcon
            disabled={disabled}
            label={t('production.pharmaceuticalForm.create.calculationType')}
          />
        </Tooltip>
      </Col>
      <Col span={5}>
        <Tooltip
          title={`${t(
            'production.pharmaceuticalForm.details.tooltips.previousExcipientMinimum'
          )} ${pharmaceuticalForm?.percentualMinimoExcipiente} ${'%'}`}
          targetOffset={[-26, -5]}
          showMe={
            !disabled &&
            pharmaceuticalForm?.percentualMinimoExcipiente !==
              form.values.percentualMinimoExcipiente
          }
        >
          <PercentageInput
            name="percentualMinimoExcipiente"
            placeHolder={t(
              'production.pharmaceuticalForm.create.excipientMinimumPlaceholder'
            )}
            required={!disabled}
            withStatusIcon
            disabled={disabled}
            label={t('production.pharmaceuticalForm.create.excipientMinimum')}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
