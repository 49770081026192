import { IUseFunctions } from './interfaces';
import { calculationTypeDropdown, useTypes } from '../../../utils';
import React from 'react';
import { ILaboratoryListData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';

export const useEditFunctions = ({
  pharmaceuticalForm,
  laboratoryList,
}: IUseFunctions) => {
  const idcalculationTypeOrigem: any = pharmaceuticalForm?.tipoCalculo;
  let calculationType = null;

  for (let i = 0; calculationTypeDropdown.length; i++) {
    if (calculationTypeDropdown[i]?.id === idcalculationTypeOrigem) {
      calculationType = calculationTypeDropdown[i];
      break;
    }
  }

  const idUseTypesOrigem: any = pharmaceuticalForm?.usoFormaFarmaceutica;
  let useType = null;

  for (let i = 0; useTypes.length; i++) {
    if (useTypes[i]?.id === idUseTypesOrigem) {
      useType = useTypes[i];
      break;
    }
  }

  const initialLaboratoryName = React.useMemo(() => {
    const initialValue = laboratoryList?.data?.find(
      (value: ILaboratoryListData) =>
        value?.externalId === pharmaceuticalForm?.laboratorioExternalId
    )?.laboratorio;

    return initialValue || '';
  }, [laboratoryList, pharmaceuticalForm?.laboratorioExternalId]);

  return {
    initialLaboratoryName,
    calculationType,
    useType,
  };
};
