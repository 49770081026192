import { FC, useState } from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Sections/InfoSection';
import { ProviderPhysicalSchema } from './ProviderPhysicalCreate.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButton';
import { ContactCard } from './Components/Cards/ContactCard';
import { AddressCard } from './Components/Cards/AddressCard';
import { DocumentCard } from './Components/Cards/DocumentCard';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { ProviderLegalSchema } from './ProviderLegalCreate.schema';
import { useTranslation } from 'react-i18next';
import { useCheckCnpjAlreadyUsed } from './Hooks/useInputCnpjFunctions';
import { useCheckCpfAlreadyUsed } from './Hooks/useInputCpfFunctions';

export const CreateSupplier: FC = () => {
  const { t } = useTranslation();

  const [personType, setPersonType] = useState(1);

  const {
    createProvider,
    isLoadingCreateProvider,
    documentList,
    refetchDocument,
    fetchNewPageDocument,
  } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({ createProvider, personType });

  const { checkCnpjDuplicationsDebounced } = useCheckCnpjAlreadyUsed();

  const { checkCpfDuplicationsDebounced } = useCheckCpfAlreadyUsed();

  return (
    <Form
      initialValues={{ tipoPessoa: 1 }}
      onSubmit={onSubmit}
      schema={
        personType === 0
          ? ProviderPhysicalSchema(checkCpfDuplicationsDebounced, t)
          : ProviderLegalSchema(checkCnpjDuplicationsDebounced, t)
      }
      individualValidateOnChange
    >
      {(props) => (
        <>
          <ConnectedFocusError />
          <InfoCard
            refetch={refetchDocument}
            personType={personType}
            setPersonType={setPersonType}
          />
          <ContactCard props={props} />
          <AddressCard props={props} />
          <DocumentCard
            props={props}
            documentList={documentList}
            fetchNewPageDocument={fetchNewPageDocument}
          />
          <FooterButtons
            isLoadingCreateProvider={isLoadingCreateProvider}
            props={props}
          />
        </>
      )}
    </Form>
  );
};
