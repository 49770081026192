import { Col, Row } from 'antd';
import React from 'react';
import { Button, Icon } from '@/Components';
import { IProductLine } from './interfaces';
import { ProductCode } from './Components/ProductCode';
import { ProductName } from './Components/ProductName';
import { Quantity } from './Components/Quantity';
import { Phase } from './Components/Phase';
import { Type } from './Components/Type';
import { useProductLineStates } from './Hooks/useProductLineStates';
import { useProductLineFunctions } from './Hooks/useProductLineFunctions';

export const ProductLine: React.FC<IProductLine> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
  uuidV4,
  disabled,
  pharmaceuticalFormExternalId,
  classeProdutoId,
}) => {
  const { currentProduct, setCurrentProduct } = useProductLineStates();

  const { deleteRow, addRow } = useProductLineFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    onDelete,
  });

  return (
    <>
      <Row wrap={false} gutter={[16, 0]} style={{ position: 'relative' }}>
        <ProductCode
          index={index}
          disabled={disabled}
          currentProduct={currentProduct}
        />
        <ProductName
          index={index}
          disabled={disabled}
          setCurrentProduct={setCurrentProduct}
          addRow={addRow}
        />
        <Quantity
          index={index}
          disabled={disabled}
          pharmaceuticalFormExternalId={pharmaceuticalFormExternalId}
          addRow={addRow}
          classeProdutoId={classeProdutoId || currentProduct?.classeProdutoId}
        />
        <Phase index={index} disabled={disabled} addRow={addRow} />
        <Type index={index} disabled={disabled} addRow={addRow} />
        <Col
          style={{
            width: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            type="secondary"
            onClick={deleteRow}
            disabled={index === totalRows - 1 || disabled}
            style={{ marginTop: `${index === 0 ? '5px' : '-20px'}` }}
          >
            <Icon
              icon="trash-line"
              size="L"
              style={{
                cursor:
                  index === totalRows - 1 || disabled
                    ? 'not-allowed'
                    : 'default',
                color:
                  index === totalRows - 1 || disabled
                    ? 'var(--color-status-disabled-base)'
                    : '',
              }}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};
