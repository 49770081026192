import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Card, Form } from '../../../../../../Components';
import { getCardStatus } from '../../../../../../Components/Card';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../../Layouts/PageLoading';
import { CreateSchema } from './DetailsForm.schema';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { RowDescription } from './Components/Rows/DescriptionRow';
import { OtherRow } from './Components/Rows/OtherRow';
import { FooterButtons } from './Components/FooterButtons';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCheckNameDuplicationAPI } from '../../Create/Hooks/useCheckNameDuplicationAPI';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const StorageLocationDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { selectedOptionsStock, setSelectedOptionsStock } = useEditStates();

  const { checkNameDuplications } = useCheckNameDuplicationAPI();

  const {
    editStorageLocation,
    isLoadingEditStorageLocation,
    storageLocation,
    loading,
  } = useEditCallAPI({ externalId, t });

  const { onSubmit } = useEditFunctions({
    editStorageLocation,
    selectedOptionsStock,
    storageLocation,
  });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={{
          ...storageLocation,
          empresaId: storageLocation?.empresaExternalID,
        }}
        onSubmit={onSubmit}
        schema={CreateSchema(
          checkNameDuplications,
          t,
          storageLocation?.externalId
        )}
        validateOnChange
      >
        {(props) => (
          <>
            <Card
              title={t('common.information')}
              withoutCollapse={true}
              status={getCardStatus(['descricao', 'empresaExternalID'], props)}
            >
              <RowDescription disabled={disabled} />
              <OtherRow
                disabled={disabled}
                selectedOptionsStock={selectedOptionsStock}
                setSelectedOptionsStock={setSelectedOptionsStock}
              />
            </Card>
            {!disabled && (
              <FooterButtons
                isLoadingEditStorageLocation={isLoadingEditStorageLocation}
                onChangeDisabled={onChangeDisabled}
                props={props}
              />
            )}
            <ConnectedFocusError />
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default StorageLocationDetail;
