import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { 
  THERE_IS_PACKING_CLASSIFICATION_TYPE 
} from '@/ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { debounce } from 'lodash';
import { IUseCheckNameDuplicationsCallAPI } from './interfaces';

export const useCheckNameDuplicationCallAPI = ({
  initialPackingClassificationDescription
}: IUseCheckNameDuplicationsCallAPI) => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_PACKING_CLASSIFICATION_TYPE,
    entityApi: PackingClassificationAPI.isPackingClassificationNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = initialPackingClassificationDescription !== value 
          ? (await mutateAsync(value)) as boolean
          : true;

        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return { 
    mutateAsync,
    checkNameDuplicationsDebounced 
  };
};
