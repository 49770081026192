import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useState } from 'react';
import { useConfigTabs } from './tabs/Details/hooks/useConfigTabs';
import { useTabs } from './tabs/Details/hooks/useTabs';
import { DeleteModal } from './tabs/Details/components/DeleteModal';

export const DetailsInventory: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const { rightButtonConfig, dropdownItems, dropdownTitle } = useConfigTabs(
    edit,
    setEdit
  );
  const { tabs } = useTabs(edit, setEdit);

  return (
    <>
      <PageLoading>
        <HorizontalTabs
          type="secondary"
          items={tabs}
          dropdownDirection="bottomRight"
          dropdownTitle={dropdownTitle}
          leftIcon="menu-ellipsis-horizontal"
          rightButton={rightButtonConfig}
          dropdownItens={dropdownItems(() => setVisibleDeleteModal(true))}
        />
      </PageLoading>
      <DeleteModal
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
      />
    </>
  );
};
