import { useFormikContext } from 'formik';

export const useControlRows = () => {
  const form: any = useFormikContext();
  const lots = form.values.lotes;

  const configRows = (invoiceItemId: string) => {
    const lotsIndexed = lots.map((e: any, i: number) => {
      return { ...e, lotIndex: i };
    });
    const rows = lotsIndexed.filter((e: any) => {
      return e.notaFiscalEntradaItemId === invoiceItemId;
    });

    return rows;
  };

  return { configRows };
};
