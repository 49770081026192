import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/Components';
import { IBatchSearch } from './interfaces';
import { useBatchSearchMemorizations } from './Hooks/useBatchSearchMemorizations';

export const BatchSearch: FC<IBatchSearch> = ({
  lotList,
  disabledForm,
  isLotLoading,
  fetchNewLotPage,
}) => {
  const { t } = useTranslation();

  const { items, disabled } = useBatchSearchMemorizations({
    lotList,
    disabledForm,
  });

  return (
    <Col span={6}>
      <Dropdown
        items={items}
        name="loteInformacaoTecnica.loteOrigemExternalId"
        label={t('supply.lot.create.lotOrigin')}
        placeHolder={t('supply.lot.create.searchForLot')}
        disabled={disabled}
        isLoading={isLotLoading}
        withTooltip={{
          icon: 'exclamation-upside-down',
          title: t('supply.lot.create.toolTips.lotOrigin'),
        }}
        onScrollEnd={fetchNewLotPage}
        withoutMarginBottom
      />
    </Col>
  );
};
