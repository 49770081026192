import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@/Components';

import styles from './pharmaceuticalFormDescriptionDetails.module.scss';

interface IDescriptionPharmaceuticalFormInput {
  disabled: boolean;
}

export const DescriptionPharmaceuticalFormInput: FC<
  IDescriptionPharmaceuticalFormInput
> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <TextInput
      className={styles['text-input']}
      name="descricao"
      placeHolder={t(
        'production.pharmaceuticalForm.create.descriptionPlaceholder'
      )}
      required={!disabled}
      withStatusIcon
      label={t('production.pharmaceuticalForm.create.description')}
      disabled={disabled}
    />
  );
};
