import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const PharmaceuticalFormDetailsSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
  ordem: yup
    .number()
    .nullable()
    .required(
      i18n.t('production.pharmaceuticalForm.create.errors.requiredOrder')
    ),

  descricao: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.pharmaceuticalForm.create.errors.requiredDescription')
    )
    .test(
      'checkDuplications',
      t('production.pharmaceuticalForm.create.errors.errorPharmaceuticalFormDescriptionExist'),
      (value) => {
        return new Promise((resolve) => {
          validateName(value, (success) => {
            resolve(success);
          });
        });
      }
    ),

  apresentacao: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.pharmaceuticalForm.create.errors.requiredPresentation')
    ),

  usoFormaFarmaceutica: yup
    .number()
    .nullable()
    .required(
      i18n.t('production.pharmaceuticalForm.create.errors.requiredUse')
    ),

  tipoCalculo: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.pharmaceuticalForm.create.errors.requiredCalculationType'
      )
    ),

  unidadeMedidaId: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.pharmaceuticalForm.create.errors.requiredUnitOfMensurament'
      )
    ),

  percentualMinimoExcipiente: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.pharmaceuticalForm.create.errors.requiredMinimunExcipient'
      )
    ),

  validadeDias: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.pharmaceuticalForm.create.errors.requiredExpirationDays'
      )
    ),

  laboratorioExternalId: yup
    .string()
    .nullable()
    .required(
      i18n.t('production.pharmaceuticalForm.create.errors.requiredLaboratory')
    ),

  custoOperacional: yup
    .number()
    .nullable()
    .required(
      i18n.t(
        'production.pharmaceuticalForm.create.errors.requiredOperationalCost'
      )
    ),
});
