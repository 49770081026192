import { Dropdown, Icon, NumberInput } from '@/Components';
import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FooterDescription } from './FooterDescription';
import { Col, Row } from 'antd';

import styles from './ResellCard.module.scss';

export const ResellCard: FC = () => {
  const form: any = useFormikContext();

  const [expectedDuration, setExpectedDuration] = useState<number | undefined>(
    undefined
  );

  const { t } = useTranslation();

  return (
    <div>
      <Row gutter={[6, 12]}>
        <Col span={8}>
          <Dropdown
            name="calc"
            label="Tipo de Cálculo"
            withoutMarginBottom
            items={[
              { id: 0, label: 'Por dose' },
              { id: 1, label: 'Por duração' },
            ]}
            value={form.values?.calc}
          />
        </Col>
        <Col span={7}>
          <NumberInput
            name="amount"
            label={form.values?.calc === 0 ? 'Quant. Doses' : 'Quantidade'}
            withoutMarginBottom
            placeHolder={form.values?.calc === 0 ? 'Dose' : 'Ex: 5'}
          />
        </Col>
        <Col span={1} className={styles['close-icon']}>
          <Icon icon="close-x" color="text-300" size="S" />
        </Col>
        <Col span={8}>
          <Dropdown
            name="peri"
            label="Período"
            withoutMarginBottom
            placeHolder="Ex: Dia"
            items={[
              { id: 0, label: 'Dia' },
              { id: 2, label: 'Semana' },
              { id: 3, label: 'Mes' },
            ]}
            onChange={(x) => setExpectedDuration(x ? 60 : undefined)}
          />
        </Col>
        <Col span={24}>
          <FooterDescription t={t} expectedDuration={expectedDuration} />
        </Col>
      </Row>
    </div>
  );
};
