import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../Components';
import { Modal } from '../../../../../../Components/Modal';
import { FooterThisActionIsPermanent } from '../../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetCompanyResponse } from '../../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import {
  FETCH_DELETE_TYPE,
  LIST_COMPANY_ADMIN_TYPE,
} from '../../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { history } from '../../../../../../Store';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import ReactMarkdown from 'react-markdown';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeleteCompanyWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalCompany';
import { App } from 'antd';

interface IDeleteProductModal {
  company?: IGetCompanyResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteCompanyModal: FC<IDeleteProductModal> = ({
  company,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const app = App.useApp();

  const deleteCompany = useControllerMutateApiHook<any>({
    uniqId: FETCH_DELETE_TYPE,
    entityApi: CompanyAPI.deleteCompany,
  });

  const onOkClick = useCallback(async () => {
    changeVisibleModal(false);
    if (!company) return;
    try {
      if (company) {
        const res = await deleteCompany.mutateAsync(
          {
            empresasExternalId: [company?.externalId],
          },
          {
            onError: handleModalError(
              app,
              cannotDeleteCompanyWithMovementsInTheSystemProps(t)
            ),
          }
        );

        if (res.success) {
          queryClient.invalidateQueries([LIST_COMPANY_ADMIN_TYPE]);
          history.push(`/admin-bootis/company`);
        }
      }
    } catch {}
  }, [changeVisibleModal, company, deleteCompany]);

  return (
    <Modal
      title={`${t('common.delete')} "${company?.nomeFantasia}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text
              type="ui-tiny-content"
              children={
                <ReactMarkdown>
                  {t('adminBootis.companyList.deleteModal.description', {
                    company: `**${company?.nomeFantasia}**`,
                  })}
                </ReactMarkdown>
              }
              color="text-50"
              withoutMarginBottom
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={onOkClick}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      loading={deleteCompany?.isLoading}
    />
  );
};
