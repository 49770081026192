import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  InputWithSearch,
  TextInput,
  Tooltip,
} from '@/Components';
import { Col, Row } from 'antd';
import { 
  FETCH_GET_LABORATORY 
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { FormikContextType, useFormikContext } from 'formik';
import { useParams } from 'react-router';
import { 
  IGetLaboratory 
} from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useCreateMemorizations } from './Hooks/useEditMemorizations';
import { 
  IEditLaboratory 
} from '@/Data/Interfaces/request/Laboratory/ILaboratoryRequest';

import styles from './laboratoryName.module.scss';

interface IDescriptionRow {
  disabled: boolean;
}

export const DescriptionRow: FC<IDescriptionRow> = ({ 
  disabled,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<IEditLaboratory> = useFormikContext();
  const { externalId } = useParams<{ externalId: string }>();

  const { data: laboratory } = useControllerQueryApiHook<IGetLaboratory>({
    uniqId: FETCH_GET_LABORATORY,
    entityApi: LaboratoryAPI.getCurrentLaboratory,
    externalIds: [externalId],
  });
  
  const {
    companyList,
    isLoadingCompany,
    refetchSearchCompanyPage,
    fetchInputCompany,
    isLoadingSupplyPlace,
    refetchSupplyPlace,
    supplyPlaceList,
    fetchNewPageSupplyPlace,
  } = useEditCallAPI({
    form,
    t,
  });

  const { 
    suplyFormList, 
    companyListlFormList 
  } = useCreateMemorizations({
    supplyPlaceList,
    companyList,
  });

  useEffect(() => {
    refetchSupplyPlace({
      filter: [
        {
          filterName: 'empresaExternalId',
          value: form?.values?.empresaExternalId,
        }
      ]
    });
  }, [form?.values?.empresaExternalId]);

  return (
    <Card title={t('common.information')} withoutCollapse={true}>
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousLaboratory'
            )}: ${laboratory?.laboratorio}`}
            targetOffset={[0, -12]}
            showMe={!disabled && laboratory?.laboratorio !== form?.values?.nome}
          >
            <TextInput
              className={styles['text-input']}
              name="nome"
              placeHolder={t('production.laboratory.create.namePlaceholder')}
              required={!disabled}
              withStatusIcon
              disabled={disabled}
              label={t('production.laboratory.create.name')}
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousCompany'
            )}: ${laboratory?.empresa}`}
            targetOffset={[0, -12]}
            showMe={
              !disabled &&
              laboratory?.empresaExternalId !== form.values.empresaExternalId
            }
          >
            <InputWithSearch
              items={companyListlFormList}
              name="empresaExternalId"
              placeHolder={t('production.laboratory.create.companyPlaceholder')}
              label={t('production.laboratory.create.company')}
              required={!disabled}
              withoutMarginBottom
              withStatusIcon
              disabled={disabled}
              selectedItemFromForm='companyInitialValue'
              isLoading={isLoadingCompany}
              onScrollEnd={fetchInputCompany}
              onChange={(value) => {
                form.setFieldValue("empresaExternalId", value);
                setTimeout(() => {
                  form.validateField("nome");
                }, 0);
              }}
              onSearch={(search) =>
                refetchSearchCompanyPage({
                  search: search,
                })
              }
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <Tooltip
            title={`${t(
              'production.laboratory.details.tooltips.previousSupplyPlace'
            )}: ${laboratory?.localEstoque}`}
            targetOffset={[0, -12]}
            showMe={
              !disabled &&
              laboratory?.localEstoque !== form.values.localEstoqueExternalId
            }
          >
            <InputWithSearch
              items={suplyFormList}
              name="localEstoqueExternalId"
              placeHolder={t(
                'production.laboratory.create.supplyPlacesPlaceholder'
              )}
              label={t('production.laboratory.create.supplyPlaces')}
              required={!disabled}
              withoutSearchIcon
              withStatusIcon
              withoutMarginBottom
              disabled={disabled}
              selectedItemFromForm='localEstoqueInitialValue'
              isLoading={isLoadingSupplyPlace}
              onScrollEnd={fetchNewPageSupplyPlace}
              onSearch={(search) =>
                refetchSupplyPlace({
                  search: search,
                })
              }
              onChange={(value) => {
                form.setFieldValue('localEstoqueInitialValue', {
                  id: value,
                  label: suplyFormList.find((item) => item.id === value)?.label,
                });
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

export default DescriptionRow;
