import { Modal } from '@/Components';
import { UseMutationResult } from '@tanstack/react-query';
import { FormikContextType, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuficientFunds } from './Body';
import { useInsuficientFunds } from './Hooks/useInsuficientFundsFunctions';

interface IInsufficientFunds {
  productName?: string;
  balanceStock?: number;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  editModal: boolean;
  changeVisible: () => void;
  visible: boolean;
}

export const InsufficientFunds: FC<IInsufficientFunds> = ({
  productName,
  balanceStock,
  addProduct,
  editProduct,
  editModal,
  changeVisible,
  visible,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<any> = useFormikContext();

  const { onOkClick, onCancelClick, onClose } = useInsuficientFunds({
    addProduct,
    changeVisible,
    editModal,
    editProduct,
    form,
  });

  return (
    <Modal
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.insufficientFund'
      )}
      body={
        <InsuficientFunds
          balanceStock={balanceStock}
          productName={productName}
        />
      }
      visible={visible}
      maxWidth={400}
      okButtonColor="status-danger-base"
      okButtonName={t('common.continue')}
      onOkClick={onOkClick}
      onCancelClick={onCancelClick}
      onClose={onClose}
    />
  );
};
