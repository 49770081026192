import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { DeleteModalBody } from '../Modal/DeleteModalBody';
import { IDeleteModalTable } from '@/Components/Table';

export const useDilutedProductMemorizations = ({
  currentProduct,
  selectedRowsOnTable,
  productExternalId,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () => history.push('diluted/createDiluted'),
      label: t('products.diluted.newDilutedProduct'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosDiluido_Cadastrar,
        tooltip: 'noPermission.supply.products.createDiluted',
      },
    },
    deleteButton: {
      label: t('common.remove'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosDiluido_Excluir,
        tooltip: 'noPermission.supply.products.removeDiluted',
      },
    },
    searchPlaceholder: t('products.diluted.searchForDilutedPlaceholder'),
  };

  const deleteModal: IDeleteModalTable | undefined = {
    title:
      selectedRowsOnTable.length === 1
        ? t('modal.deleteModal.single.dilutedProductTitle')
        : t('modal.deleteModal.many.dilutedProductTitle'),
    body: (
      <DeleteModalBody
        selectedRowsOnTable={selectedRowsOnTable}
        currentProduct={currentProduct ? currentProduct.descricao : ''}
      />
    ),
    buttons: {
      okButtonColor: 'status-danger-base',
    },
    okButtonName: t('common.remove'),
  };

  const rowsOptions = {
    onRowDoubleClick: (x: any) => {
      history.push(
        `/inventory/products/${productExternalId}/diluted/${x.externalId}/dilutedDetails`
      );
    },
  };

  return {
    headerOptions,
    deleteModal,
    rowsOptions,
  };
};
