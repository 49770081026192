import { useCallback } from 'react';
import { IStatus, IUseFunctions } from './interfaces';
import { IListUserGroupData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { cannotDeleteUserGroupsWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalUserGroups';

export const useListFunctions = ({
  changeUserGroupStatus,
  deleteUserGroup,
}: IUseFunctions) => {
  const app = App.useApp();
  const { t } = useTranslation();

  const onEdit = useCallback(
    async (userGroup: IListUserGroupData[], status: IStatus) => {
      try {
        await changeUserGroupStatus.mutateAsync({
          gruposId: userGroup?.map((x) => x?.externalId),
          ativa: status?.status === 'success' ? true : false,
        });
      } catch {};
    },
    [changeUserGroupStatus]
  );

  const onDelete = useCallback((userGroups: IListUserGroupData[]) => {
    try {
      deleteUserGroup.mutateAsync(
        userGroups?.map((x) => x?.externalId),
        {
          onError: handleModalError(
            app,
            cannotDeleteUserGroupsWithMovementsInTheSystemProps(t)
          )
        }
      );
    } catch {};
  }, [deleteUserGroup]);

  return { onEdit, onDelete };
};
