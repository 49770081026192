import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal } from '@/Components/Modal';
import { AddManipulatedRecipeModalBody } from './Modals/AddManipulatedRecipe/AddManipulatedRecipeModalBody';
import { DetailsManipulatedRecipeModalBody } from './Modals/DetailsManipulatedRecipe/DetailsManipulatedRecipeModalBody';
import { Steps } from '@/Components/Steps';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues } from 'formik';
import { v4 as uuidV4 } from 'uuid';

import styles from './ManipulatedRecipe.module.scss';
import { ManipulatedRecipeSchema } from './ManipulatedRecipe.schema';

interface IAddManipulatedRecipeModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;

  countRecipe: number;

  client?: {
    externalId: string;
    name: string;
  };
}

export const AddManipulatedRecipeModal: FC<IAddManipulatedRecipeModal> = ({
  changeVisibleModal,
  visibleModal,
  countRecipe,
  client,
}: IAddManipulatedRecipeModal) => {
  const [stepCurrent, setStepCurrent] = useState(0);

  const [componentsEditing, setComponentsEditing] = useState<number[]>([]);
  const [validatingComponents, setValidatingComponents] =
    useState<boolean>(false);

  useEffect(() => {
    componentsEditing.length === 0 && setValidatingComponents(false);
  }, [componentsEditing.length]);

  const steps = useMemo(
    () => [
      <AddManipulatedRecipeModalBody
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
      />,
      <DetailsManipulatedRecipeModalBody />,
    ],
    [componentsEditing, validatingComponents]
  );

  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('common.add')} ${countRecipe}ª ${t(
        'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.recipe'
      )}`}
      schema={ManipulatedRecipeSchema()}
      body={
        <div className={styles['container']}>
          <Divflex className={styles['content-step']}>
            <Steps
              type="step-by-step"
              current={stepCurrent}
              items={[t('common.components'), t('common.details')]}
              className={styles['steps']}
            />
          </Divflex>
          {steps[stepCurrent]}
        </div>
      }
      visible={visibleModal}
      okButtonName={t('common.next')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={700}
      className={styles['modal-content']}
      withForm
      htmlType="submit"
      initialValues={{
        pacienteExternalId: client?.externalId,
        itens: [{ id: uuidV4() }],
        calc: 0,
        pacienteExternalIdInitialValue: {
          id: client?.externalId,
          label: client?.name,
          avatar: { name: client?.name },
        },
      }}
      onOkClickForm={(_, formik) =>
        ComponentsFormErrors(
          componentsEditing,
          setStepCurrent,
          formik,
          setValidatingComponents
        )
      }
      disabledButton={validatingComponents && componentsEditing.length > 0}
      onReturn={() => setStepCurrent((x) => x - 1)}
      showReturnButton={stepCurrent > 0}
    />
  );
};

const ComponentsFormErrors = (
  componentsEditing: number[],
  setStepCurrent: (value: SetStateAction<number>) => void,
  form: FormikHelpers<FormikValues>,
  setValidatingComponents: Dispatch<SetStateAction<boolean>>
) => {
  if (componentsEditing.length > 0) {
    form.setSubmitting(false);
    setValidatingComponents(true);
  } else setStepCurrent((x) => x + 1);
};
