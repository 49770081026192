import { i18n } from '@/Components';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  EDIT_ADRESS_CLIENT,
  FETCH_ADDRESS_CLIENT_LIST,
  GET_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IuseEditAddressCallAPI } from './interfaces';
import { IGetClientAdressResponse } from '@/Data/Interfaces/response/Person/Client';

export const useEditAddressCallAPI = ({
  selectedAddressExternalId,
  changeVisibleModal,
}: IuseEditAddressCallAPI) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editAddress } = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_CLIENT,
    entityApi: ClientAPI.editAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeAddress'));

        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);

        queryClient.invalidateQueries([FETCH_ADDRESS_CLIENT_LIST]);

        queryClient.invalidateQueries([GET_CLIENT]);
        changeVisibleModal(false);
      },
    },
  });

  const updateDeliverySaleOrder = useControllerMutateApiHook({
    uniqId: UPDATE_DELIVERY_SALES_ORDER,
    entityApi: SalesOrderAPI.updateDeliverySaleOrder,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const { data: clientAddress } =
    useControllerQueryApiHook<IGetClientAdressResponse>({
      uniqId: GET_CLIENT,
      entityApi: ClientAPI.getClientAddress,
      externalIds: [selectedAddressExternalId],
    });

  return { editAddress, updateDeliverySaleOrder, clientAddress };
};
