import { Modal, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectedTableItem } from '..';

interface IDeleteTableItemModal {
  visibleModal: ISelectedTableItem | undefined;
  changeVisibleModal: (value: ISelectedTableItem | undefined) => void;
  onOkClick: () => void;
  description: string;
  okButtonName: string;
  okButtonLoading: boolean;
}

export const DeleteTableItemModal: FC<IDeleteTableItemModal> = ({
  changeVisibleModal,
  visibleModal,
  onOkClick,
  description,
  okButtonName,
  okButtonLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t(
        'saleOrder.editOrder.SaleOrder.deleteTableItemModal.title'
      )} "${description}"?`}
      body={
        <Divflex style={{ padding: '20px' }}>
          <Text type="ui-tiny-content">
            {t(
              'saleOrder.editOrder.SaleOrder.deleteTableItemModal.description'
            )}
          </Text>
        </Divflex>
      }
      visible={!!visibleModal}
      okButtonName={okButtonName}
      loading={okButtonLoading}
      okButtonStatus="danger"
      onCancelClick={() => changeVisibleModal(undefined)}
      onClose={() => changeVisibleModal(undefined)}
      maxWidth={400}
      htmlType="submit"
      onOkClick={onOkClick}
    />
  );
};
