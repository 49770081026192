import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IGroup } from './interfaces';
import { Dropdown, Tooltip } from '@/Components';
import { useFormikContext } from 'formik';
import { useGroupCallAPI } from './Hooks/useGroupCallAPI';
import { useGroupMemorizations } from './Hooks/useGroupMemorizations';

export const Group: FC<IGroup> = ({
  product,
  disabledForm,
  handlerGroupDropdown,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { listGroups, fetchNewGroupPage } = useGroupCallAPI();

  const { items } = useGroupMemorizations({ listGroups: listGroups?.data });

  return (
    <Col span={6}>
      <Tooltip
        title={`${t('products.toolTips.products.nameOfGroup')} ${
          product?.grupo
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.grupoExternalId !== form?.values?.grupoExternalId
        }
      >
        <Dropdown
          items={items}
          name="grupoExternalId"
          selectedItemFromForm="grupoLabelInitialValue"
          label={[
            {
              children: t('products.create.group'),
            },
          ]}
          onChange={(groupExternalId) => {
            handlerGroupDropdown(groupExternalId);
            form.setFieldValue('subGrupoExternalId', undefined);
          }}
          placeHolder={t('products.create.groupPlaceholder')}
          disabled={disabledForm}
          withStatusIcon={!!product}
          onScrollEnd={fetchNewGroupPage}
          required
        />
      </Tooltip>
    </Col>
  );
};
