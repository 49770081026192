import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { IAmountDefault } from './interfaces';
import { useAmountDefaultCallAPI } from './Hooks/useAmountDefaultCallAPI';
import { useAmountDefaultMemorizations } from './Hooks/useAmountDefaultMemorizations';

export const AmountDefault: FC<IAmountDefault> = ({
  classificationProductId,
  unidadeMedidaId,
}) => {
  const { t } = useTranslation();

  const { 
    listunitsMeasurements,
    refetchListUnitsMeasurements 
  } = useAmountDefaultCallAPI({
    classificationProductId,
  });

  const { dropdownRight } = useAmountDefaultMemorizations({
    unidadeMedidaId,
    listunitsMeasurements,
  });

  useEffect(() => {
    refetchListUnitsMeasurements ({
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: classificationProductId,
        },
      ],
    });
  }, [classificationProductId]);

  return (
    <>
      <WeightInput
        name="quantidadePadrao"
        label={t('production.standardFormula.create.amoundDefault')}
        placeHolder={t(
          'production.standardFormula.create.amoundDefaultPlaceholder'
        )}
        required
        withoutMarginBottom
        dropdownRight={dropdownRight}
      />
    </>
  );
};
