import { Icon, Label, Skeleton, Tag, Text } from '@/Components';
import { IGetSimplifiedClientResponse } from '@/Data/Interfaces/response/Person/Client';
import { Divflex } from '@/Layouts/DivWhithFlex';
import DateUtils from '@/Utils/DateUtils';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { Col, Row } from 'antd';
import { FC } from 'react';
interface IContent {
  client?: IGetSimplifiedClientResponse;
  clientLoading: boolean;
}
export const Content: FC<IContent> = ({ client, clientLoading }) => {
  return clientLoading ? (
    <>
      <Skeleton size="md" />
      <br />
      <Skeleton size="md" />
      <br />
      <Skeleton size="md" />
    </>
  ) : (
    <Row gutter={[12, 12]}>
      {(client?.cnpj || client?.cpf) && (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={client.cpf ? 'CPF' : 'CNPJ'}
            withBottomMargin
          />
          <Text type="ui-tiny-semibold" color="text-50">
            {client.cpf ? MaskForCPF(client?.cpf) : MaskForCNPJ(client.cnpj)}
          </Text>
        </Col>
      )}
      {client?.dataNascimento && (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={'Data de Nascimento'}
            withBottomMargin
          />
          <Divflex>
            <Text
              type="ui-tiny-content"
              color="text-300"
              style={{ marginRight: 6 }}
            >
              {DateUtils.convertDate(client?.dataNascimento)}
            </Text>

            <Tag
              type="secondary"
              children={`${DateUtils.getAge(client?.dataNascimento)} anos`}
            />
          </Divflex>
        </Col>
      )}
      {client?.contatoPrincipal && (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={'Contato Principal'}
            withBottomMargin
          />
          <Divflex>
            <Icon icon={client?.tipoContatoIcon} style={{ marginRight: 6 }} />
            <Text type="ui-tiny-content" color="text-50">
              {client?.contatoPrincipal}
            </Text>
          </Divflex>
        </Col>
      )}
    </Row>
  );
};
