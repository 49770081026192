import { i18n } from '@/Components';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  CHANGE_ITEM_AMOUNT,
  CHANGE_SELECTED_FINISHED_PRODUCT,
  DELETE_SALES_ORDER,
  FETCH_GET_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { IUseTableItemCallAPI } from './interface';

export const useTableItemCallAPI = ({
  contentItemHeight,
  changeVisibleScroll,
  setSelectedTableItem,
}: IUseTableItemCallAPI) => {
  const queryClient = useQueryClient();

  const changeAmount = useControllerMutateApiHook({
    uniqId: CHANGE_ITEM_AMOUNT,
    entityApi: SalesOrderAPI.changeItemAmountSaleOrder,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        messageSucessToast(
          i18n.t(
            'saleOrder.editOrder.SaleOrder.table.successMessage.changeItemAmount'
          )
        );
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const removeItem = useControllerMutateApiHook({
    uniqId: DELETE_SALES_ORDER,
    entityApi: SalesOrderAPI.deleteSaleOrder,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        contentItemHeight && changeVisibleScroll(contentItemHeight);
        setSelectedTableItem(undefined);
        messageSucessToast(
          i18n.t('saleOrder.editOrder.SaleOrder.deleteTableItemModal.success')
        );
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const changeSelectedItem = useControllerMutateApiHook({
    uniqId: CHANGE_SELECTED_FINISHED_PRODUCT,
    entityApi: SalesOrderAPI.changeSelectedFinishedProduct,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  return { changeAmount, removeItem, changeSelectedItem };
};
