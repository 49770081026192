import { ICreateProductLinkedMessage } from '@/Data/Interfaces/request/ProductMensage/IProductMensageRequest';
import { FETCH_LIST_PRODUCT_LINKED_MESSAGE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { FormikHelpers, FormikValues } from 'formik';

export const useLinkedProductMessageModalFunctions = ({
  typeMessageSelected,
  setTypeMessageSelected,
  newMessageLinkedProduct,
  createProductLinkedMessage,
  createProductMessageLinked,
  produtoExternalId,
  changeVisible,
  visible,
  setVisibleLinkedProductMessageModalSearch,
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const handlerMessageTypeSelected = (value: number) => {
    setTypeMessageSelected(value);
  };

  const onSubmit = async (
    values: ICreateProductLinkedMessage,
    helpers?: FormikHelpers<FormikValues>
  ) => {
    try {
      if (typeMessageSelected === newMessageLinkedProduct) {
        const body: any = { ...values };
        const res = await createProductLinkedMessage.mutateAsync({
          descricao: body.mensagem,
          exibeVenda: body.exibeVenda ?? false,
          exibeRotulagem: body.exibeRotulagem ?? false,
          exibeFichaPesagem: body.exibeFichaPesagem ?? false,
          exibeImpressaoRotulo: body.exibeImpressaoRotulo ?? false,
          produtoExternalId: produtoExternalId,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_MESSAGE]);
          changeVisible(!visible);
        }
      } else {
        const body: any = { ...values };
        const res = await createProductMessageLinked.mutateAsync({
          mensagemExternalId: body.mensagemExternalId,
          produtoExternalId: produtoExternalId,
        });
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_PRODUCT_LINKED_MESSAGE]);
          changeVisible(!visible);
        }
      }
    } catch {
      helpers?.setSubmitting(false);
    }
  };

  const changeVisibleModalSearch = (x: boolean) =>
    setVisibleLinkedProductMessageModalSearch(x);

  const onCancelClick = () => changeVisible(!visible);

  const onClose = () => changeVisible(!visible);

  return {
    handlerMessageTypeSelected,
    onSubmit,
    changeVisibleModalSearch,
    onCancelClick,
    onClose,
  };
};
