import { i18n } from '@/Components';
import {
  FETCH_GET_ORDER_PURCHASE,
  SAVE_POST_DRAFT_ORDER_PURCHASE,
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { IPurchaseOrderGetResponse } from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { IUseCallAPI } from './interfaces';

export const useEditOrderCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: purchaseOrder } =
    useControllerQueryApiHook<IPurchaseOrderGetResponse>({
      uniqId: FETCH_GET_ORDER_PURCHASE,
      entityApi: PurchaseAPI.getOrderPurchase,
      externalIds: [externalId],
      refetchOnWindowFocus: false,
    });

  const editOrderPurchase: any = useControllerMutateApiHook({
    uniqId: SAVE_POST_DRAFT_ORDER_PURCHASE,
    entityApi: PurchaseAPI.editOrderPurchase,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.purchaseOrder.successMessageToasts.edit')
        ),
    },
  });

  return {
    purchaseOrder,
    editOrderPurchase,
  };
};
