import { FC } from 'react';
import { Col } from 'antd';
import { InputWithSearch } from '@/Components';
import { useTranslation } from 'react-i18next';
import { IProvider } from './interfaces';
import { useProviderMemorizations } from './Hooks/useProviderMemorizations';
import { useProviderFunctions } from './Hooks/useProviderFunctions';
import { useProviderCallAPI } from './Hooks/useProviderCallAPI';
import { FormikValues, useFormikContext } from 'formik';
import { IListProviderData } from '@/Services/People/Supplier/ListSupplier.service';

export const Provider: FC<IProvider> = ({
  disabledForm,
  setProviderId,
  provider,
}) => {
  const { t } = useTranslation();

  const form = useFormikContext<FormikValues>();

  const {
    providersList,
    isProviderLoading,
    fetchNewProviderPage,
    refetchSearchProviderPage,
  } = useProviderCallAPI();

  const { items } = useProviderMemorizations({ providersList });

  const { onSearch } = useProviderFunctions({
    providersList,
    setProviderId,
    refetchSearchProviderPage,
  });

  return (
    <Col span={9}>
      <InputWithSearch
        items={items}
        onScrollEnd={fetchNewProviderPage}
        name="fornecedorExternalId"
        label={t('products.create.provider')}
        placeHolder={t('products.create.providerPlaceholder')}
        disabled={disabledForm}
        onChange={(x) => {
          const providerSelected = providersList?.data?.find(
            (value: IListProviderData) => value.externalId === x
          );
          setProviderId(x);
          form?.setFieldValue(
            'razaoSocial',
            providerSelected && providerSelected.nome
          );
          form?.setFieldValue('Cnpj', provider && provider.cnpj);
        }}
        onSearch={onSearch}
        isLoading={isProviderLoading}
        required
        onClear={() => {
          form?.setFieldValue('razaoSocial', undefined);
          form?.setFieldValue('Cnpj', undefined);
        }}
      />
    </Col>
  );
};
