import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Formik,
  FormikProps,
  FormikValues,
  Form as FormFormik,
  FormikHelpers,
} from 'formik';
import { ObjectSchema } from 'yup';
import { NotificationActions } from '../../Store/Notification/Notification.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { getIsCurrentFormDirty } from '../../Store/Notification/Notification.selector';
import { App } from 'antd';
import { LeaveWithoutSave } from '../ModalLeaveWithoutSave';

export interface IFChildren extends FormikProps<FormikValues> {}

export type FChildren = (props: IFChildren) => ReactNode;

export interface IForm {
  id?: string;
  name?: string;
  schema?: ObjectSchema<any>;
  initialValues?: FormikValues;
  children: ReactNode | FChildren;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  style?: CSSProperties;
  individualValidateOnChange?: boolean;
  className?: string;
  validateOnMount?: boolean;
  onSubmit?: (values: any, formikHelpers: FormikHelpers<FormikValues>) => void;

  noLeaveWithoutLeaving?: boolean;
}

export const Form: FC<IForm> = ({
  id,
  name,
  initialValues,
  schema,
  className,
  children,
  style,
  validateOnChange = false,
  individualValidateOnChange = false,
  validateOnBlur = false,
  validateOnMount = false,
  onSubmit,
  noLeaveWithoutLeaving,
}) => {
  const dispatch = useDispatch();
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] =
    useState(false);

  const onSubmitWrapper = useCallback(
    (values: any, formikHelpers: FormikHelpers<FormikValues>) => {
      dispatch(NotificationActions.setCurrentFormIsDirty(false));

      setTimeout(() => {
        onSubmit && onSubmit(values, formikHelpers);
      }, 1);
    },
    [dispatch, onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues || {}}
      enableReinitialize={true}
      validationSchema={schema}
      validateOnMount={validateOnMount}
      validateOnChange={validateOnChange || validateOnChangeAfterSubmit}
      validateOnBlur={validateOnBlur}
      onSubmit={onSubmitWrapper}
    >
      {(props) => (
        <FormValidate
          children={children}
          props={props}
          className={className}
          id={id}
          name={name}
          style={style}
          individualValidateOnChange={individualValidateOnChange}
          onChangeValidateOnChange={() => {
            if (props.submitCount > 0) {
              setValidateOnChangeAfterSubmit(true);
            }
          }}
          noLeaveWithoutLeaving={noLeaveWithoutLeaving}
        />
      )}
    </Formik>
  );
};

interface IFormValidate
  extends Omit<
    IForm,
    | 'schema'
    | 'initialValues'
    | 'validateOnChange'
    | 'validateOnBlur'
    | 'validateOnMount'
    | 'onSubmit'
  > {
  props: FormikProps<FormikValues>;
  onChangeValidateOnChange: () => void;
  individualValidateOnChange?: boolean;
}

const FormValidate: FC<IFormValidate> = ({
  id,
  name,
  className,
  style,
  children,
  props,
  onChangeValidateOnChange,
  noLeaveWithoutLeaving,
  individualValidateOnChange,
}) => {
  const dispatch = useDispatch();
  const app = App.useApp();
  const isCurrentFormDirty = useSelector(getIsCurrentFormDirty);
  const isFunction = typeof children === 'function';
  const FChildren = children as FChildren;

  const individualValidation = (target: HTMLInputElement) => {
    const { name } = target;

    if (name && props.values?.[name])
      setTimeout(() => props.validateField(name), 100);
  };

  useEffect(() => {
    const objectKeys = Object.keys(props.errors);
    if (props.errors && objectKeys.length > 0) {
      onChangeValidateOnChange && onChangeValidateOnChange();
    }
  }, [props.errors, onChangeValidateOnChange]);

  return (
    <FormFormik
      id={id}
      name={name}
      className={className}
      style={style}
      autoComplete="off"
      onSelect={(event) => {
        if(individualValidateOnChange)
          individualValidation(event.target as HTMLInputElement);

        dispatch(NotificationActions.setCurrentFormIsDirty(true));
      }}
      onChange={(event) => {
        if(individualValidateOnChange)
          individualValidation(event.target as HTMLInputElement);

        dispatch(NotificationActions.setCurrentFormIsDirty(true));
      }}
    >
      {!noLeaveWithoutLeaving && (
        <Prompt
          when={isCurrentFormDirty}
          message={(location, action) => {
            const modal = app.modal.info({});
            modal.update(LeaveWithoutSave({ location, action }, modal));

            return false;
          }}
        />
      )}

      {isFunction ? FChildren && FChildren(props) : children}
    </FormFormik>
  );
};
