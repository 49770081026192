import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { IFullDiscount } from './interfaces';
import { Tooltip } from '@/Components';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { useFullDiscountMemorizations } from './Hooks/useFullDiscountMemorizations';
import { useDiscountInput } from '@/Pages/Sectors/Order/Purchase/Create/Components/TotalPurchase/Components/OrderValues/hooks/useDiscountInput';

export const FullDiscount: FC<IFullDiscount> = ({
  orderPurchase,
  totalProductValue,
}) => {
  const { t } = useTranslation();
  const { calculateDiscount } = useDiscountInput();

  const { showMe, placeholder, dropdownLeft } = useFullDiscountMemorizations({
    orderPurchase,
  });

  return (
    <>
      <Tooltip
        title={`${t('common.originalValue')} ${currencyFormater(
          orderPurchase?.valorDesconto
        )}`}
        showMe={showMe}
        targetOffset={[60, -12]}
      >
        <DiscountInput
          name="valorDesconto"
          label={t('purchasing.purchaseOrder.create.totalCard.fullDiscount')}
          placeHolder={placeholder}
          withoutMarginBottom
          dropdownLeft={dropdownLeft}
          withStatusIcon={showMe}
          onChange={(value) => {
            calculateDiscount(totalProductValue, value);
          }}
          allowNegative={false}
        />
      </Tooltip>
    </>
  );
};
