import { FC } from 'react';
import { Text } from '@/Components';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';

interface IInsuficientFunds {
  productName?: string;
  balanceStock?: number;
}

export const InsuficientFunds: FC<IInsuficientFunds> = ({
  productName,
  balanceStock,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<any> = useFormikContext();

  return (
    <div style={{ padding: '20px' }}>
      <Text type="ui-tiny-content" color="text-50">
        <ReactMarkdown>
          {t(
            'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.description',
            {
              product: `**${productName}**`,
            }
          )}
        </ReactMarkdown>
      </Text>
      <Text type="ui-tiny-content" color="text-50">
        <ReactMarkdown>
          {t(
            'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.BalanceStock',
            {
              stock: `**${balanceStock} un**`,
            }
          )}
        </ReactMarkdown>
      </Text>
      <Text type="ui-tiny-content" color="text-50">
        <ReactMarkdown>
          {t(
            'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.SelectedQuantity',
            {
              quantity: `**${form.values?.quantidade} un**`,
            }
          )}
        </ReactMarkdown>
      </Text>
      <Text
        type="ui-tiny-content"
        children={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.InsufficientFunds.footerDescription'
        )}
        color="text-50"
        withoutMarginBottom
      />
    </div>
  );
};
