import { InputWithSearch } from '@/Components';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { Col } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from './Popover/Title';
import { Content } from './Popover/Content';

export const PrescriberInput: FC = () => {
  const [selectedPrescriber, setSelectedPrescriber] = useState<
    ISimpleListPrescribersData | undefined
  >(undefined);

  const { t } = useTranslation();

  const prescriberList = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'ASC' },
    },
  });

  return (
    <Col span={24}>
      <InputWithSearch
        name="prescritorExternalId"
        label={t('prescribers.listPage.prescriber')}
        placeHolder={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
        )}
        items={prescriberList?.data?.data?.map(
          (x: ISimpleListPrescribersData) => ({
            avatar: { name: x.nomeCompleto },
            label: x.nomeCompleto,
            id: x.externalId,
            subLabel: `${x.siglaRegistro}/${x.abreviacaoUfRegistro} ${x.codigoRegistro}`,
            allItem: x,
          })
        )}
        onChange={(_, value) => setSelectedPrescriber(value)}
        placeholderWithAvatar
        withoutSearchIcon
        withoutMarginBottom
        onScrollEnd={prescriberList.fetchNewPage}
        popover={{
          title: <Title selectedPrescriber={selectedPrescriber} />,
          content: <Content />,
          width: 300,
        }}
        selectedOptionRender="withoutSubLabel"
      />
    </Col>
  );
};
