export const useListLots = () => {
  const listLots = (products: any[]) => {
    let lots: any = [];
    products.map((e) => {
      if (!e.lotes.length) {
        return lots.push({
          notaFiscalEntradaItemId: e.notaFiscalEntradaItemId,
          unidadeMedidaId: e.unidadeMedidaId,
          quantidade: e.quantidadeComprada,
        });
      }
      lots = [...lots, ...e.lotes];
    });

    return lots;
  };

  return {
    listLots,
  };
};
